import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {TimeSlot} from '@shared/models/time-slot.model';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {ScapistStatus} from '@shared/constants/scapist-status.enum';
import {DayFilterEnum} from '@shared/constants/day-filter.enum';
import {ScapistStatusUtils} from '@shared/utils/scapist-status.utils';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {TimeBookingComponent} from '@modules/scapists/time-booking/time-booking.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {WorkListService} from '@shared/services/work-list.service';
import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {TimeSlotUtils} from '@shared/utils/time-slot.utils';

@Component({
  selector: 'app-scapist-booking',
  templateUrl: './scapist-booking.component.html',
  styleUrls: ['./scapist-booking.component.scss']
})
export class ScapistBookingComponent implements OnInit, OnChanges {

  @Input() scapist: Scapist;

  ID_FOR_SMS_BOX = 'smsbox';
  ID_FOR_EMAIL_BOX = 'emailbox';
  timeSlotStart: TimeSlot;
  timeSlotContinuedStart: TimeSlot;
  timeSlotCT: TimeSlot;
  timeSlotPrevention: TimeSlot;
  timeSlotPreventionFollowUp: TimeSlot;
  absentTimeSlotDays: TimeSlot[];
  isScapistInterested: boolean;
  isScapistBooked: boolean;
  isScapistReadyForPrevention: boolean;
  isScapistReadyForPreventionFollowUp: boolean;
  isScapistUnreachable: boolean;
  isBooker: boolean;
  isDone: boolean;
  isPreventionNurse: boolean;
  isAnyTimeSlotAbsent: boolean;
  showContinuedStart: boolean;
  canBookCore: boolean;

  constructor(private timeSlotService: TimeSlotService, private scapistService: ScapistService,
              private authenticationService: AuthenticationService,private workListService: WorkListService,  public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initiateFormScapist()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scapist) {
      this.initiateFormScapist();
    }
  }
  initiateFormScapist() {
    if (this.scapist.timeSlots) {
      this.timeSlotStart = null;
      this.timeSlotContinuedStart = null;
      this.timeSlotCT = null;
      this.timeSlotPrevention = null;
      this.timeSlotPreventionFollowUp = null;
      for (const timeSlot of this.scapist.timeSlots) {
        if (timeSlot.timeSlotType === TimeSlotTypeEnum.START && timeSlot.status !== BookingStatusEnum.ABSENT) {
          this.timeSlotStart = timeSlot;
        } else if (timeSlot.timeSlotType === TimeSlotTypeEnum.CONTINUED_START && timeSlot.status !== BookingStatusEnum.ABSENT) {
          this.timeSlotContinuedStart = timeSlot;
        } else if (timeSlot.timeSlotType === TimeSlotTypeEnum.CT && timeSlot.status !== BookingStatusEnum.ABSENT) {
          this.timeSlotCT = timeSlot;
        } else if (timeSlot.timeSlotType === TimeSlotTypeEnum.PREVENTION && timeSlot.status !== BookingStatusEnum.ABSENT) {
          this.timeSlotPrevention = timeSlot;
        } else if (timeSlot.timeSlotType === TimeSlotTypeEnum.PREVENTION_FOLLOW_UP && timeSlot.status !== BookingStatusEnum.ABSENT) {
          this.timeSlotPreventionFollowUp = timeSlot;
        }
      }
    }
    this.isScapistInterested = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.INTERESTED)
    this.isScapistReadyForPrevention = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.BOOK_PREVENTION)
    this.isScapistReadyForPreventionFollowUp = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.DONE_PREVENTION)
    this.isScapistBooked = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.BOOKED);
    this.isScapistUnreachable = this.scapist.status === ScapistStatus.UNREACHABLE;
    this.isBooker = this.authenticationService.userHasRole(UserTypeEnum.ROLE_BOKARE);
    this.isPreventionNurse = this.authenticationService.userHasRole(UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA);
    this.isAnyTimeSlotAbsent =  this.checkIfAnyTimeSlotAbsent();
    this.showContinuedStart = this.scapist.site.hasContinuedStartVisit;
    this.isDone = ScapistStatusUtils.isScapistRemoved(this.scapist.status) || this.scapist.status === ScapistStatus.FINISHED;
  }

  isNotCancellable(timeSlot: TimeSlot) {
    return timeSlot.status === BookingStatusEnum.ABSENT || timeSlot.status === BookingStatusEnum.ATTENDED;
  }

  isAttended(status: BookingStatusEnum) {
    return status === BookingStatusEnum.ATTENDED;
  }

  isTimeSlotBookable(timeslot: TimeSlot) {
    return !timeslot || timeslot.status === BookingStatusEnum.ABSENT;
  }

  dayType(day: string) {
    return DayFilterEnum[day].display
  }

  checkIfAnyTimeSlotAbsent() {
    this.absentTimeSlotDays = this.scapist.timeSlots.filter(slot => slot.status === BookingStatusEnum.ABSENT);
    return this.absentTimeSlotDays.length > 0;
  }

  openBooking() {
    const conf = new MatDialogConfig();
    conf.data = this.scapist;
    conf.width = '800px';
    this.dialog.open(TimeBookingComponent, conf);
  }

  getTimeSlotTitle(timeSlotType: TimeSlotTypeEnum) {
    return TimeSlotUtils.getTitle(timeSlotType);
  }

  protected readonly TimeSlotTypeEnum = TimeSlotTypeEnum;
}
