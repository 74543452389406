import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { StatisticsDataModel } from '@shared/models/data-models/statistics.model';
import { ArcElement, Chart, ChartOptions, PieController } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit, OnChanges {

  @Input() data: StatisticsDataModel[];
  @Input() labels: string[];

  pieChartOptions: ChartOptions<'pie'>;
  pieChartLabels: string[];
  public pieChartDatasets;
  public pieChartPlugins = [ChartDataLabels];

  constructor() {
     this.pieChartOptions = {
      plugins: {
        datalabels: {
          color: '#FFF',
          formatter: (value: number, ctx) => {
            const data = this.pieChartDatasets[0].data;
            const total = data.reduce((pv: number, cv: number) => pv + cv, 0);
            return [value, `${Math.floor(100 * (value / total))}%`];
          }
        }
      }
    };
    // Register the plugin
    Chart.register(ChartDataLabels);
    Chart.register(PieController);
    Chart.register(ArcElement);
  }

  ngOnInit() {
    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.data || changes.labels) {
      this.drawChart();
    }
  }

  drawChart(): void {
    if(this.data && this.labels) {
      this.pieChartLabels = this.labels;
      this.pieChartDatasets = [{
        data: this.data[0].values,
        backgroundColor: ['#4472c4', '#ed7d31', '#a5a5a5', '#ffc000'],
      }];
    }
  }

}
