import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InterestedCallListComponent} from './interested-call-list/interested-call-list.component';
import {UnreachableCallListComponent} from './unreachable-call-list/unreachable-call-list.component';
import {NoShowCallListComponent} from './no-show-call-list/no-show-call-list.component';
import {CallListPageComponent} from './call-list-page/call-list-page.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {UtilsModule} from '@shared/utils/utils.module';
import {MatSortModule} from '@angular/material/sort';
import {MatButtonModule} from '@angular/material/button';
import {VisitTypeFormatterPipe} from '@shared/utils/visit-type-formatter.pipe';
import {
  PreventionCallListComponent
} from '@modules/scapists/call-list/prevention-call-list/prevention-call-list.component';
import {
  NoShowPreventionCallListComponent
} from '@modules/scapists/call-list/no-show-prevention-call-list/no-show-prevention-call-list.component';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [
    InterestedCallListComponent,
    UnreachableCallListComponent,
    NoShowCallListComponent,
    PreventionCallListComponent,
    CallListPageComponent,
    NoShowPreventionCallListComponent
  ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatButtonModule,
        MatSortModule,
        MatTableModule,
        UtilsModule,
        MatIconModule
    ],
  providers: [VisitTypeFormatterPipe]
})
export class CallListModule { }
