import { Component, OnInit } from '@angular/core';
import {SubStudyService} from '@shared/services/entity-services/sub-study.service';
import {ActivatedRoute} from '@angular/router';
import {SubStudy} from '@shared/models/sub-study.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-sub-study-page',
  templateUrl: './sub-study-page.component.html',
  styleUrls: ['./sub-study-page.component.scss']
})
export class SubStudyPageComponent implements OnInit {
  study;
  id: number;

  form: FormGroup;
  cutoff: number;

  constructor(private studyService: SubStudyService, private route: ActivatedRoute, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.study = this.studyService.getSubStudyById(this.id);
    } else {
      this.study = new SubStudy();
    }

    const config = {
      cutoff: new FormControl(null)
    };

    this.form = this.formBuilder.group(config);

    this.form.valueChanges.subscribe(() => {
      this.onChange();
    });
  }

  private onChange() {
    const data = this.form.get('cutoff');
    this.cutoff = data.getRawValue();
  }
}
