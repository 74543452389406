<div class="container">
    <mat-expansion-panel expanded [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
            <h3 class="work-list-title">{{this.redcapFlow | displayName: "RedcapFlowEnum" }} <small>({{scapistList.length}} scapister)</small></h3>
        </mat-expansion-panel-header>
        <mat-panel-description>
            <ng-container *ngIf="scapistList.length === 0; then noPersonsInWorkList; else showTable"></ng-container>
            <ng-template #noPersonsInWorkList>
                <p>Inga scapister i denna arbetslista</p>
            </ng-template>

            <ng-template #showTable>
                <mat-table [dataSource]="dataSource" id="{{redcapFlow}}" matSort matSortActive="lastReview" matSortDirection="desc" (matSortChange)="sortData($event)" [matSortActive]="sessionSort.active" [matSortDirection]="sessionSort.direction">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" sortActionDescription="Sortera efter name" id="name">Namn</th>
                        <td mat-cell *matCellDef="let column" class="clickable-cell" (click)="this.redirectToBookingPage(column.id)"
                        >
                            {{column.firstName}}  {{column.lastName}} {{column.status == 'REMOVED_AFTER_INCLUSION' ? '(AVBRUTEN)' : ''}}
                            <mat-icon [inline]="true" class="inline-icon">open_in_new</mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="personNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="personNumber" sortActionDescription="Sortera efter personNumber" id="personNumber">Personnummer</th>
                        <td mat-cell *matCellDef="let column" class="td-container clickable-cell" (click)="this.copyToClipboard(column.personNumber)">{{column.personNumber | personNumber}}
                            <mat-icon [inline]="true" class="inline-icon">content_copy</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="date" sortActionDescription="Sortera efter date" id="date">Datum</th>
                        <td mat-cell *matCellDef="let column">
                            {{column.transitionsToRelevantTimestamp | date : 'YYYY-MM-dd HH:mm'}}
                        </td>

                    </ng-container>
                    <ng-container matColumnDef="days">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="days" sortActionDescription="Sortera efter days" id="days">Dagar</th>
                        <td mat-cell *matCellDef="let column">{{dateMap.get(column.id)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="status" sortActionDescription="Sortera efter status" id="status">🚦Status</th>
                        <td mat-cell *matCellDef="let column">
                            {{statusIcon(column.flowStatus)}} {{column.flowStatus | displayName: 'RedcapFlowStatusEnum'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="latestReviewBy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="latestReviewBy" sortActionDescription="Sortera efter latestReviewBy" id="latestReviewBy">Senast öppnat av</th>
                        <td mat-cell *matCellDef="let column">
                            <div *ngIf="column.lastOpenedTimestamp">
                                {{column.lastOpenedBy}}
                            </div>
                            <div *ngIf="!column.lastOpenedTimestamp">
                                Inte öppnad
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="latestReview">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="latestReview" sortActionDescription="Sortera efter latestReview" id="latestReview">Senast öppnat</th>
                        <td mat-cell *matCellDef="let column">
                            <div *ngIf="column.lastOpenedTimestamp">
                                {{column.lastOpenedTimestamp | date:'yyyy-MM-dd HH:mm' }}
                            </div>
                            <div *ngIf="!column.lastOpenedTimestamp">
                                Inte öppnad
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ecrf">
                        <th mat-header-cell *matHeaderCellDef id="ecrf">Öppna i eCRF</th>
                        <td mat-cell *matCellDef="let column" class="button-cell">
                            <button mat-flat-button color="accent" type="submit" (click)="this.redirectToRedcap(column)">
                                <mat-icon class="centered-icon">assignment</mat-icon>
                                eCRF
                                <mat-icon class="centered-icon">open_in_new</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        id="{{row.id}}"
                    >
                    </tr>
                </mat-table>
            </ng-template>
            <mat-paginator
                [hidden]="scapistList.length === 0"
                #paginator
                [pageSizeOptions]="[10, 25, 50, 100, scapistList.length]"
                showFirstLastButtons
            ></mat-paginator>
        </mat-panel-description>
    </mat-expansion-panel>
</div>
