import { Component, OnInit } from '@angular/core';
import {share} from 'rxjs/operators';
import {StatisticsService} from '@shared/services/statistics.service';
import { Observable } from 'rxjs';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';

@Component({
  selector: 'app-ct-exams',
  templateUrl: './ct-exams.component.html',
  styleUrls: ['./ct-exams.component.scss']
})
export class CtExamsComponent implements OnInit {

  ctExams: Observable<StatisticsModel>;
  ctExamsPerWeek: Observable<StatisticsModel>;
  ctExamsTime: Observable<StatisticsModel>;


  constructor(private statisticsService: StatisticsService) { }

  ngOnInit(): void {
    this.ctExams = this.statisticsService.ctExams.pipe(share());
    this.ctExamsPerWeek = this.statisticsService.ctExamsPerWeek.pipe(share());
    this.ctExamsTime = this.statisticsService.ctExamsTime.pipe(share());
  }

}
