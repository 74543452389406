import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

import {environment} from '@environments/environment';
import {NotificationService} from '@shared/services/notification.service';
import {TimeSlot} from '@shared/models/time-slot.model';
import {Scapist} from '@shared/models/scapist.model';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class TimeSlotService {
  private baseUrl: string;

  private timeSlotsURL = '/time-slot/';
  private bookableTimeSlotsURL = '/time-slot/bookable';
  private dataStore: { timeSlots: TimeSlot[], loading: boolean, bookableTimeSlots: TimeSlot[] } =
    {timeSlots: [], loading: false, bookableTimeSlots: [] };
  private _timeSlots = new BehaviorSubject<TimeSlot[]>([]);
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading = this._loading.asObservable();
  public readonly timeSlots = this._timeSlots.asObservable();
  private _bookableTimeSlots = new BehaviorSubject<TimeSlot[]>([]);
  public readonly bookableTimeSlots = this._bookableTimeSlots.asObservable();

  constructor(private http: HttpClient, private datePipe: DatePipe, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  public loadBookableTimeslots() {
    this._loading.next(true);
    this.http.get<TimeSlot[]>(this.baseUrl + this.bookableTimeSlotsURL).subscribe(data => {
      const timeSlots = [];
      data.forEach(item => {
        timeSlots.push(Object.assign(new TimeSlot(), item));
      });
      this.dataStore.bookableTimeSlots = timeSlots;
      this.dispatchBookableTimeSlots();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in tider');
    });
  }


  public loadData() {
    this._loading.next(true);
    this.http.get<TimeSlot[]>(this.baseUrl + this.timeSlotsURL).subscribe(data => {
      const timeSlots = [];
      data.forEach(item => {
        timeSlots.push(Object.assign(new TimeSlot(), item));
      });
      this.dataStore.timeSlots = timeSlots;
      this.dispatchTimeSlots();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in tider');
    });
  }

  public getTimeSlotsWithInterval(from: Date, to: Date) {
    this._loading.next(true);
    this.http.get<TimeSlot[]>(this.baseUrl + this.timeSlotsURL + 'dates' + this.getParams(to, from)).subscribe(data => {
      const timeSlots = [];
      data.forEach(item => {
        timeSlots.push(Object.assign(new TimeSlot(), item));
      });
      this.dataStore.timeSlots = timeSlots;
      this.dispatchTimeSlots();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in tider');
    });
  }

  private dispatchTimeSlots() {
    this._timeSlots.next(Object.assign({}, this.dataStore).timeSlots);
  }

  create(data) {
    this._loading.next(true);
    this.http.post(this.baseUrl + this.timeSlotsURL, data).subscribe(
      () => {
        this.loadData();
      }, () => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte skapa inbjudan');
      }
    )

  }

  createSchedule(data) {
    this._loading.next(true);
    this.http.post(this.baseUrl + this.timeSlotsURL + 'schedule', data).subscribe(
      (response: any[]) => {
        this.loadData();
        this.notificationService.sendSuccessNotification(`Skapade ${response.length}st besökstider`)
      }, () => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte skapa besökstider');
      }
    )
  }


  cancel(timeslot: TimeSlot, scapist: Scapist, callback: () => void) {
    this.http.post(this.timeslotUrl(timeslot.id) + '/cancel', {scapistId: scapist.id}).subscribe(() => {
      callback();
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  }

  timeslotUrl(id: number) {
    return `${this.baseUrl}${this.timeSlotsURL}${id || ''}`;
  }

  book(slotId: number, scapist: Scapist, callback: () => void) {
    this.http.post(this.timeslotUrl(slotId) + '/book', {scapistId: scapist.id}).subscribe(() => {
      callback();
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  }

  delete(slot: TimeSlot) {
    this.http.delete(this.timeslotUrl(slot.id)).subscribe(() => {
      this.loadData();
      this.notificationService.sendSuccessNotification('Tiden har blivit borttagen!');
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  };

  deleteMultiple(slotIds: number[]) {
    this.http.delete(this.baseUrl + this.timeSlotsURL, {body: slotIds}).subscribe((deletedItems: number) => {
      this.loadData();
      this.notificationService.sendSuccessNotification(`${deletedItems} tider har tagits bort`);
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  };

  private getParams(from, to): string {
    const params = [
      `from=${this.datePipe.transform(from, 'YYYY-MM-dd')}`,
      `to=${this.datePipe.transform(to, 'YYYY-MM-dd')}`
    ];

    return `?${params.join('&')}`;
  }

  deleteByDate(day: Date) {
    const slots = [];
    for(const slot of this.dataStore.timeSlots) {
      if(this.isOnSameDay(slot.time, day) && !slot.scapist) {
        slots.push(slot.id);
      }
    }
    if(slots.length> 0) {
      this.deleteMultiple(slots);
    }
  }

  private isOnSameDay(day1: Date, day2: Date) {
    return day1.getFullYear() === day2.getFullYear() &&
      day1.getMonth() === day2.getMonth() &&
      day1.getDate() === day2.getDate();
  }

  private dispatchBookableTimeSlots() {
    this._bookableTimeSlots.next(Object.assign({}, this.dataStore).bookableTimeSlots);
  }
}
