import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@shared/services/notification.service';
import {environment} from '@environments/environment';
import {Scapist} from '@shared/models/scapist.model';
import {BehaviorSubject} from 'rxjs';
import {FreeBookRequest} from '@shared/models/free-book-request.model';


@Injectable({
  providedIn: 'root'
})
export class ScapistService {

  private readonly baseUrl: string;

  private siteScapistURL = '/site-scapist';
  private freeBookURLBase = '/free-book';
  private dataStore: {
    scapists: Scapist[],
    loading: boolean,
    currentScapist,
  } = {
    scapists: [],
    loading: false,
    currentScapist: null,
  };


  private _loading = new BehaviorSubject<boolean>(false);
  private _scapists = new BehaviorSubject<Scapist[]>([]);
  private _scapist = new BehaviorSubject<Scapist>(null);

  private _page = new BehaviorSubject<number>(0);
  public readonly loading = this._loading.asObservable();
  public readonly scapists = this._scapists.asObservable();
  public readonly scapist = this._scapist.asObservable();
  public readonly page = this._page.asObservable();

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  public update(scapist: Scapist) {
    this.http.post(this.scapistUrl(null) + 'update', scapist).subscribe((data: Scapist) => {
      this.dispatchScapist(data);
      this.notificationService.sendSuccessNotification('Scapist uppdaterad');
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte uppdatera Scapist');
    });
  }

  public setInterest(scapist: Scapist) {
    this.http.post(this.scapistUrl(null) + 'set-interest', scapist).subscribe((data: Scapist) => {
      this.dispatchScapist(data);
      this.notificationService.sendSuccessNotification('Sparat');
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte uppdatera Scapist');
    });
  }
  private dispatchScapist(scapist: Scapist) {
    this.dataStore.currentScapist = scapist;
    this._scapist.next(Object.assign({}, this.dataStore).currentScapist);
  }

  clearScapist() {
    this.dispatchScapist(null);
  }

  private scapistUrl(id: number): string {
    return `${this.baseUrl}${this.siteScapistURL}/${id || ''}`;
  }
  private freeBookUrl(id: number): string {
    return `${this.baseUrl}${this.freeBookURLBase}/${id || ''}`;
  }

  getById(id: number) {
    this.http.get<Scapist>((this.scapistUrl(id))).subscribe((data) => {
      this.dispatchScapist(data);
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte hitta Scapist');
    })
  }

  setRemoveStatus(scapist: Scapist) {
    this.http.post(this.scapistUrl(null) + 'set-remove-status', scapist).subscribe((data: Scapist) => {
      this.dispatchScapist(data);
      this.notificationService.sendSuccessNotification('Sparat');
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte uppdatera Scapist');
    });
  }

  undoRemoveStatus(scapist: Scapist) {
    this.http.post(this.scapistUrl(null) + 'undo-remove-status', scapist).subscribe((data: Scapist) => {
      this.dispatchScapist(data);
      this.notificationService.sendSuccessNotification('Sparat');
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte uppdatera Scapist');
    });
  }


    createFreeBook(data: FreeBookRequest) {
        this.http.post(this.freeBookUrl(null) + '', data).subscribe((response: Scapist) => {
            this.dispatchScapist(response);
            this.notificationService.sendSuccessNotification('Skapat');
        }, error => {
            this.notificationService.sendErrorNotification('Kunde inte skapa');
        });
    }

    deleteFreeBook(id: number) {
        this.http.delete(this.freeBookUrl(id) + '').subscribe((response: Scapist) => {
            this.dispatchScapist(response);
            this.notificationService.sendSuccessNotification('Borttagen');
        }, error => {
            this.notificationService.sendErrorNotification('Kunde inte ta bort');
        });
    }

    manageFreeBook(id) {
        this.http.post(this.freeBookUrl(id) + '/manage', {}).subscribe((response: Scapist) => {
            this.dispatchScapist(response);
            this.notificationService.sendSuccessNotification('Markerad som hanterad');
        }, error => {
            this.notificationService.sendErrorNotification('Kunde inte markera som hanterad');
        });
    }

  abortPrevention(scapist: Scapist) {
    this.http.post(this.scapistUrl(scapist.id) + '/abort-prevention', scapist).subscribe((data: Scapist) => {
      this.dispatchScapist(data);
      this.notificationService.sendSuccessNotification('Sparat');
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte uppdatera Scapist');
    });
  }
}
