import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidatePhoneNumber} from '@shared/validators/phone-number.validator';
import {PhoneNumberFormatter} from '@shared/utils/phone-number-formatter';
import {FormUtils} from '@shared/utils/form.utils';
import {ScapistStatus} from '@shared/constants/scapist-status.enum';
import {ScapistStatusUtils} from '@shared/utils/scapist-status.utils';
import {DisplayNamePipe} from '@shared/utils/display-name.pipe';
import {DatePipe} from '@angular/common';
import {EventType} from '@shared/constants/event-type.enum';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {PersonNumberPipe} from '@shared/utils/person-number.pipe';


@Component({
  selector: 'app-scapist-form',
  templateUrl: './scapist-form.component.html',
  styleUrls: ['./scapist-form.component.scss']
})
export class ScapistFormComponent implements OnInit, OnChanges {

  static readonly MARK_AS_INTERESTED_ALLOWED_TYPES: ScapistStatus[] = [
    ScapistStatus.INVITED,
    ScapistStatus.REMINDED,
    ScapistStatus.PASSIVELY_UNINTERESTED
  ];

  @Input() scapist: Scapist;
  form: FormGroup;
  isScapistInterested: boolean;
  isScapistRemoved: boolean;
  canMarkScapistAsInterested: boolean;
  isBooker: boolean;

  constructor(private scapistService: ScapistService, private formBuilder: FormBuilder,
              private displayNamePipe: DisplayNamePipe, private datePipe: DatePipe, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.getFormState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scapist) {
      this.getFormState();
    }
  }

  getFormState() {
    const dateWhenMarkedAsInterested = this.getDateWhenMarkedAsInterested();
    this.form = this.formBuilder.group({
      personNumber: {value: this.scapist?.personNumber && PersonNumberPipe.doTransform(this.scapist.personNumber) || 'Har inget personnummer', disabled: true},
      status: {
        value: this.displayNamePipe.transform(this.scapist?.status, 'ScapistStatus') || 'Har ingen status',
        disabled: true
      },
      markedAsInterested: {value: dateWhenMarkedAsInterested, disabled: true},
      email: new FormControl(this.scapist.email, [Validators.email, Validators.required]),
      phone: new FormControl(this.scapist.phone, [Validators.required, ValidatePhoneNumber]),
      remindedViaEmail: new FormControl(this.scapist.remindedViaEmail),
      remindedViaSms: new FormControl(this.scapist.remindedViaSms)
    });
    this.canMarkScapistAsInterested = this.isMarkScapistAsInterestedAllowed();
    this.isScapistInterested = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.INTERESTED);
    this.isBooker = this.authenticationService.userHasRole(UserTypeEnum.ROLE_BOKARE);
    this.isScapistRemoved = this.scapist.status === ScapistStatus.REMOVED_BEFORE_INCLUSION || this.scapist.status === ScapistStatus.REMOVED_AFTER_INCLUSION;
  }

  get email() {
    return this.form.get('email');
  }

  get phone() {
    return this.form.get('phone');
  }

  markAsInterested() {
    this.scapistService.setInterest(this.scapist);
  }

  getDateWhenMarkedAsInterested() {
    const acceptEvent = this.scapist.events?.find(event => event.eventType === EventType.ACCEPT);
    if (acceptEvent) {
      return this.datePipe.transform(acceptEvent.createdAt, 'yyyy-MM-dd HH:mm');
    }
    if(this.scapist.status === ScapistStatus.NEW) {
      return 'Ännu ej inbjuden';
    }
    return 'Har inte accepterat';
  }

  isMarkScapistAsInterestedAllowed(): boolean {
    return this.scapist.email && this.scapist.phone && ScapistFormComponent.MARK_AS_INTERESTED_ALLOWED_TYPES.includes(this.scapist.status);
  }

  isDisplayError(formProperty: string) {
    return FormUtils.isDisplayError(this.form, formProperty);
  }

  onSubmit() {
    const data = this.form.getRawValue();
    this.scapist.email = data.email;
    this.scapist.phone = PhoneNumberFormatter(data.phone);
    this.scapist.remindedViaEmail = data.remindedViaEmail;
    this.scapist.remindedViaSms = data.remindedViaSms;
    this.scapistService.update(this.scapist);
    this.canMarkScapistAsInterested = this.isMarkScapistAsInterestedAllowed();
  }



}
