import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {S2ApiVersion} from '@environments/api-version';
import {VersionService} from '@shared/utils/version.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

  constructor(private versionService: VersionService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const backendVersion = event.headers.get('S2-Api-Version');
          this.versionService.isApiVersionMatchingBackend.next(backendVersion === S2ApiVersion)
        }
      })
    );
  }
}
