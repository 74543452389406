import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {share} from 'rxjs/operators';
import {Scapist} from '@shared/models/scapist.model';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {Router} from '@angular/router';
import {TimeSlot} from '@shared/models/time-slot.model';
import {AuthenticationService} from '@shared/services/authentication.service';
import {Site} from '@shared/models/site.model';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {ScapistStatus} from '@shared/constants/scapist-status.enum';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {LoaderService} from '@shared/utils/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-time-booking',
  templateUrl: './time-booking.component.html',
  styleUrls: ['./time-booking.component.scss']
})
export class TimeBookingComponent implements OnInit {
  form: FormGroup;
  site: Site;

  timeSlots: Observable<TimeSlot[]>;
  availableTimeSlots: TimeSlot[] = [];
  timeSlotTypeEnum = TimeSlotTypeEnum;

  displayPrevention: boolean;
  displayFollowup: boolean;
  displayCoreVisits: boolean;

  isLoading: Observable<boolean>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public scapist: Scapist,
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private timeSlotService: TimeSlotService,
    private scapistService: ScapistService,
    private router: Router,
    private authenticationService: AuthenticationService,
    public loaderService: LoaderService) {

}

  ngOnInit(): void {
    this.isLoading = this.loaderService.isLoading.pipe(share());
    this.timeSlots = this.timeSlotService.bookableTimeSlots.pipe(share());
    this.form = this.formBuilder.group({});
    this.timeSlots.subscribe(data => {
      this.availableTimeSlots = data.filter(slot => slot.status === BookingStatusEnum.EMPTY);
    });
    this.site = this.authenticationService.getUser().site || new Site();

    this.displayFollowup = this.canBookFollowupPrevention(this.scapist) &&
      this.authenticationService.userHasAnyRole([UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA]);
    this.displayPrevention = this.canBookPrevention(this.scapist) &&
      this.authenticationService.userHasAnyRole([UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA]);
    this.timeSlotService.loadBookableTimeslots();
  }

  async onSubmit() {
    const values = Object.entries(this.form.value);

    const selected = values
    .filter(([key, value]) => key.includes('select') && value)
    .map(([key]) => key.split('_select')[0] as TimeSlotTypeEnum);

    if (selected.length > 0) {
        for (const type of selected) {
          const slotId = this.form.value[`${type}_id`];

          if (slotId) {
            await this.bookAsync(slotId, this.scapist);
          }
      }
      this.scapistService.clearScapist();
      this.scapistService.getById(this.scapist.id);
      this.router.navigateByUrl('/scapist/' + this.scapist.id);
      this.dialogRef.close();
    }
  }

  async bookAsync(slotId: number, scapist: Scapist): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        this.timeSlotService.book(slotId, scapist, resolve);
    });
  }

  canBookPrevention(scapist: Scapist): boolean {
    return [
      ScapistStatus.BOOK_PREVENTION,
      ScapistStatus.NO_SHOW_PREVENTION,
      ScapistStatus.DONE_PREVENTION,
      ScapistStatus.NO_SHOW_PREVENTION_FOLLOW_UP,
      ScapistStatus.NO_PREVENTION_FOLLOW_UP_BOOKED,
    ].includes(scapist.status);
  }

  canBookFollowupPrevention(scapist: Scapist): boolean {
    return [
      ScapistStatus.DONE_PREVENTION,
      ScapistStatus.NO_SHOW_PREVENTION_FOLLOW_UP,
      ScapistStatus.NO_PREVENTION_FOLLOW_UP_BOOKED,
    ].includes(scapist.status);
  }
}
