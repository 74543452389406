<div [ngClass]="{content: true, 'logged-in': isLoggedIn()}">
  <app-environment-indicator></app-environment-indicator>
  <app-header></app-header>
  <div class="page">
    <div>
      <div class="container">
        <app-notification></app-notification>
      </div>
      <div id="pageContent" class="page-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
