import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {

  public reloadApp() {
    window.location.reload();
  }
}
