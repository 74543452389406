<nav>
    <button *ngFor="let chart of charts; let i = index" mat-stroked-button (click)="selectChart(i)" class="{{className(i)}}">{{chart}}</button>
</nav>
<div class="container">
  <div class="row">
    <div class="col-md-6"><h1>{{getTitle()}}</h1></div>
    <div class="col-md-3 offset-3">
      <div *ngIf="isNationalStats">
        <mat-form-field>
          <mat-label>Titta på statistik för en site</mat-label>
          <mat-select (selectionChange)="setSite($event.value)">
            <mat-option *ngFor="let site of sites" [value]="site.value">{{site.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="openChart">
    <app-invite-stats-page *ngSwitchCase="charts.indexOf('Inbjudningar')"></app-invite-stats-page>
    <app-inclusion-stats-page *ngSwitchCase="charts.indexOf('Inklusion')"></app-inclusion-stats-page>
    <app-ct-exams *ngSwitchCase="charts.indexOf('CT-Undersökningar')"></app-ct-exams>
    <app-ct-reviews *ngSwitchCase="charts.indexOf('CT-Granskningar')"></app-ct-reviews>
    <app-prevention *ngSwitchCase="charts.indexOf('Prevention')"></app-prevention>
    <app-study-pace *ngSwitchCase="charts.indexOf('Studietakt')"></app-study-pace>
  </ng-container>


  <h3>Information</h3>
  <p>
    Rapporter som ligger till grund för linje- och stapeldiagram körs natten mellan torsdag och fredag.
    Cirkeldiagram är ögonblicksbilder av hur status ser ut i S2 just nu.
  </p>
  <ng-container [ngSwitch]="openChart">
    <div *ngSwitchCase="charts.indexOf('CT-Granskningar')">
      <p>
        Cirkeldiagramen visar hur lång tid scapister har legat på respektive arbetslista för granskning.
        Dvs eCRF sidan CT-undersökning är "complete" markerad tills dess att respektive eCRF granskningsida är "complete" markerad.
      </p>
      <p>
        Kompletta granskningar räknas från det att eCRF CT-undersökningssidan är “complete” tills att samtliga relevanta eCRF granskningssidor är “complete” markerade.
      </p>
      <p>
        Den genomsnittliga tiden under varje graf är beräknad baserat på genomförda granskningar. De som fortfarande
        väntar på granskning är alltså inte inräknade.
      </p>
    </div>
    <div *ngSwitchCase="charts.indexOf('Inklusion')">
      <p>
        Cirkeldiagramen visar alla schemalagda tider för respektive besökstyp.
      </p>
      <ul>
        <li>Obokade: Antal tider i schemat som inte är kopplade till en scapist, framtida såväl som dåtida.</li>
        <li>Bokade: Antal tider i schemat som är kopplade till en scapist men som ligger i framtiden. </li>
        <li>Genomförda: Antal tider där den inbokade scapisten är markerad som ankommen.</li>
        <li>Missade: Antal tider där den inbokade scapisten är markerad som frånvarande.</li>
      </ul>
    </div>

  </ng-container>

</div>
