export class Notification {

  constructor(type: string, message: string, actionLabel?: string, action?: any) {
    this.type = type;
    this.message = message;
    this.actionLabel = actionLabel;
    this.action = action;
  }

  type: string;
  message: string;

  actionLabel: string;
  action() {}
}
