import {Component} from '@angular/core';
import {share} from 'rxjs/operators';
import {NotificationService} from '@shared/services/notification.service';
import {Notification} from '@shared/models/notification';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {

  notification: Observable<Notification>

  constructor(private notificationService: NotificationService, private _snackBar: MatSnackBar) {
    this.notification = this.notificationService.notification.pipe(share());
  }

  ngOnInit() {
    this.notification.subscribe(notification => {
      if (notification?.message) {
        this.openSnackBar(notification);
      }
    })
  }

  openSnackBar(notification: Notification) {
    let actionMsg = 'Stäng';
    if (notification.actionLabel) {
      actionMsg = notification.actionLabel;
    }
    const ref = this._snackBar.open(notification.message, actionMsg, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: [notification.type]
    });
    if(ref) {
      ref.afterDismissed().subscribe((info) => {
        if(info.dismissedByAction && notification.action && typeof notification.action === 'function') {
          notification.action();
        }
      });
    }

  }
}
