<nav class="{{mode}} desktop-navigation">
  <ng-container *ngFor="let item of navItems">
    <button
      *ngIf="item.authorized"
      mat-button
      routerLinkActive="active"
      [routerLink]="item.route"
      [id]="item.route"
    >
      <mat-icon aria-hidden="true" fontSet="material-icons-outlined">
          {{ item.icon }}
      </mat-icon>
      {{ item.label }}
    </button>
  </ng-container>
</nav>

<button
  mat-icon-button
  class="nav-button"
  [matMenuTriggerFor]="menu"
>
  <mat-icon>menu</mat-icon>
</button>
<mat-menu #menu="matMenu" class="mobile-navigation">
  <ng-container *ngFor="let item of navItems">
    <button
      *ngIf="item.authorized"
      mat-menu-item
      routerLinkActive="active"
      [routerLink]="item.route"
      [id]="item.route"
    >
      <mat-icon aria-hidden="true" fontSet="material-icons-outlined">
          {{ item.icon }}
      </mat-icon>
      {{ item.label }}
    </button>
  </ng-container>
  <app-user-nav></app-user-nav>
</mat-menu>
