
export enum RedcapFlowStatusEnum {
  DONE = ('DONE'),
  NOT_APPLICABLE = ('NOT_APPLICABLE'),
  NOT_VERIFIED = ('NOT_VERIFIED'),
  RELEVANT = ('RELEVANT'),
  INITIATED = ('INITIATED'),
  STARTED = ('STARTED')
}


export const RedcapFlowStatusDisplay = {
  [RedcapFlowStatusEnum.DONE]: 'Klar',
  [RedcapFlowStatusEnum.NOT_APPLICABLE]: 'Icke applicerbar',
  [RedcapFlowStatusEnum.NOT_VERIFIED]: 'Ej verifierad',
  [RedcapFlowStatusEnum.RELEVANT]: 'Ej påbörjad',
  [RedcapFlowStatusEnum.INITIATED]: 'Initierad i redcap',
  [RedcapFlowStatusEnum.STARTED]: 'Påbörjad',
}
