import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@shared/services/authentication.service';

/**
 * Used with the routing module. Some urls should not be accessible when logged/not logged in.
 * This takes care of that
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isLoggedIn = this.authenticationService.isUserLoggedIn();

    const segment: UrlSegment[] = route.url;
    const path = segment[0].path;

    if (path === 'version') {
      this.authenticationService.setRedirectTo(this.cleanPath(window.location.pathname));
      return true;
    }

    if( path === 'login' ||
      path === 'createUser' ||
      path === 'auth-code' ||
      path === 'enableUser' ||
      path === 'requestNewPassword' ||
      path === 'verifyPasswordToken' ||
      path === 'resetPassword') {
      return this.validateOrRedirect(!isLoggedIn, '/start');
    }
    return this.validateOrRedirect(isLoggedIn, '/login');
  }

  /**
   * Return true if the condition passed in is true.
   * If not, redirect to start page and return false.
   * @param condition true if valid
   * @param path string stating where to redirect
   */
  private validateOrRedirect(condition: boolean, path: string): boolean {
    if (condition) {
      return true;
    }
    this.authenticationService.setRedirectTo(this.cleanPath(window.location.pathname));
    this.router.navigateByUrl(path).then();
    return false;
  }

  private cleanPath(path: string) {
    return path.replace('/portal', '');
  }
}
