import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitePageComponent } from './site-page/site-page.component';
import { SiteFormComponent } from './site-form/site-form.component';
import {MatButtonModule} from "@angular/material/button";
import {RouterLinkWithHref} from "@angular/router";
import {SubStudiesModule} from "@modules/sub-studies/sub-studies.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    SitePageComponent,
    SiteFormComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterLinkWithHref,
    SubStudiesModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class SiteModule { }
