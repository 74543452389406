import {Component, OnInit} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {share} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {ListFunctionsService} from '@shared/services/list-functions.service';
import {UnreachableScapistsService} from '@shared/services/scapist-services/unreachable-scapists.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-unreachable-call-list',
  templateUrl: './unreachable-call-list.component.html',
  styleUrls: ['./unreachable-call-list.component.scss']
})
export class UnreachableCallListComponent implements OnInit {

  scapists: Observable<Scapist[]>;

  displayedColumns: string[] = [];
  unreachableScapistList: Scapist[];
  pageSizeOptions = [10, 25, 50];
  toggleUnreachable = false;

  constructor(private unreachableScapistsService: UnreachableScapistsService, public listFunctionService: ListFunctionsService) {
  }

  ngOnInit(): void {
    this.scapists = this.unreachableScapistsService.scapists.pipe(share());
    this.displayedColumns = ['name', 'personNumber', 'phone', 'accepted', 'attempts', 'latestAttempt', 'callAttempt'];
    this.unreachableScapistsService.getScapists();
    this.scapists.subscribe(list => {
      this.unreachableScapistList = list;
      this.pageSizeOptions = [...this.pageSizeOptions, this.pageInfo.total];
    })
    this.sortData({active: 'accepted', direction: 'asc'})
  }

  get pageInfo() {
    return this.unreachableScapistsService.getPaginationInfo();
  }

  sortData(sort: Sort) {
    this.unreachableScapistsService.setSorting(sort.active, sort.direction);
    this.unreachableScapistsService.getScapists();
  }

  sendCallAttemptEvent(scapist: Scapist, direction: number) {
    // You cannot regret a call attempt scapist when no attempts been made.
    if (direction === -1 && scapist.callAttempts === 0) {
      return;
    }
    scapist.callAttempts += direction;
    if(direction !== -1) {
      scapist.latestCallAttempt = new Date();
    }
    this.unreachableScapistsService.sendCallAttempt(scapist);
  }

  toogleShowUnreachable() {
    this.toggleUnreachable = !this.toggleUnreachable;
  }

  handlePageEvent(event: PageEvent) {
    this.unreachableScapistsService.loadPage(event.pageIndex, event.pageSize)
  }


}
