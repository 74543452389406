import {Pipe, PipeTransform} from '@angular/core';
import {TimeSlot} from '@shared/models/time-slot.model';
import {TimeSlotUtils} from '@shared/utils/time-slot.utils';

@Pipe({
  name: 'groupTimeSlots'
})
export class GroupTimeSlotsPipe implements PipeTransform {

  transform(timeSlots: TimeSlot[]): Map<string, TimeSlot[]> {

    return TimeSlotUtils.groupByDate(timeSlots);
  }

}
