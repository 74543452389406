import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {VisitListDay} from '@shared/models/data-models/visit-list-day.model';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {DateUtils} from '@shared/utils/date.utils';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {DayFilterEnum} from '@shared/constants/day-filter.enum';
import {Booking} from '@shared/models/data-models/booking.model';

@Component({
  selector: 'app-day-visit-list',
  templateUrl: './day-visit-list.component.html',
  styleUrls: ['./day-visit-list.component.scss']
})
export class DayVisitListComponent implements OnInit, OnChanges {

  @Input() day: VisitListDay;

  @Output() attend: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output() absent: EventEmitter<Booking> = new EventEmitter<Booking>();
  @Output() reset: EventEmitter<Booking> = new EventEmitter<Booking>();

  sortedTimes: Booking[] = [];

  displayedColumns: string[] = [];

  isToday: boolean;

  isRequestSent = false;

  constructor(private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isRequestSent = false;
  }

  ngOnInit(): void {

    this.displayedColumns = ['time', 'name', 'personNumber', 'type', 'actions'];

    this.sortData({active: 'time', direction: 'desc'});
    this.checkToday();
  }

  redirectToScapistPage(event: MouseEvent, slot: Booking) {
    event.preventDefault();
    this.router.navigateByUrl('/scapist/' + slot.scapist.id).then();
  }

  sortData(sort: Sort) {
    const data = (this.day.timeSlots || []).slice();
    if (!sort.active || sort.direction === '') {
      this.sortedTimes = data;
      return;
    }

    this.sortedTimes = data.sort((a: Booking, b: Booking) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.scapist.firstName, b.scapist.firstName, isDesc);
        case 'personNumber':
          return this.compare(a.scapist.personNumber, b.scapist.personNumber, isDesc);
        case 'time' :
          return this.compare(a.time, b.time, isDesc);
        case 'type':
          return this.compare(a.timeSlotType, b.timeSlotType, isDesc);
        default:
          return 0;
      }
    });
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private checkToday() {
    const today = new Date();
    const candidate = new Date(this.day.date);
    this.isToday = candidate.getFullYear() === today.getFullYear() &&
      candidate.getMonth() === today.getMonth() &&
      candidate.getDate() === today.getDate();
  }

  get dayLabel() {
    let label = '';
    if (this.isToday) {
      label = label + 'Idag, ';
    }
    const date = new Date(this.day.date);
    return label + `${DateUtils.getDayString(date)} ${date.getDate()} ${DateUtils.getMonthString(date)}`;
  }

  doAttend(event: MouseEvent, slot: Booking) {
    event.preventDefault();
    if(this.isRequestSent) {
      return;
    }
    this.isRequestSent = true;
    if (this.isChecked(slot, BookingStatusEnum.ATTENDED)) {
      this.reset.emit(slot);
    } else {
      this.attend.emit(slot);
    }

  }

  doAbsent(event: MouseEvent, slot: Booking) {
    event.preventDefault();
    if(this.isRequestSent) {
      return;
    }
    this.isRequestSent = true;
    if (this.isChecked(slot, BookingStatusEnum.ABSENT)) {
      this.reset.emit(slot);
    } else {
      this.absent.emit(slot);
    }
  }

  getAttendClass(slot: Booking, base: string) {
    if (slot.status === BookingStatusEnum.MANAGED) {
      return base + ' managed';
    }
    if (slot.status === BookingStatusEnum.ATTENDED) {
      return base + ' attended';
    }
    if (slot.status === BookingStatusEnum.ABSENT) {
      return base + ' absent';
    }
    return base;
  }

  dayType(day: string) {
    return DayFilterEnum[day].display
  }

  isChecked(slot: Booking, status: BookingStatusEnum) {
    return slot.status === status;
  }

  getTooltip(slot: Booking, status: BookingStatusEnum) {
      if (status === BookingStatusEnum.ATTENDED) {
        if (this.isChecked(slot, BookingStatusEnum.ABSENT)) {
          return 'Redan markerad som utebliven. Bocka ur "Uteblivit" för att markera som ankommit';
        }
        else {
          return 'Markera som ankommit';
        }
      }
      if (status === BookingStatusEnum.ABSENT) {
        if (this.isChecked(slot, BookingStatusEnum.ATTENDED)) {
          return 'Redan markerad som ankommit. Bocka ur "Ankommit" för att markera som utebliven';
        }
        else {
          return 'Markera som utebliven';
        }
      }
  }

}
