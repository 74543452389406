import {Component, Input, OnInit} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FreeBookFormComponent} from '@modules/scapists/free-book-form/free-book-form.component';
import {ScapistStatusUtils} from '@shared/utils/scapist-status.utils';
import {UserTypeEnum} from "@shared/constants/user-type.enum";
import {AuthenticationService} from "@shared/services/authentication.service";

@Component({
  selector: 'app-free-books',
  templateUrl: './free-books.component.html',
  styleUrls: ['./free-books.component.scss']
})
export class FreeBooksComponent implements OnInit {
    @Input() scapist: Scapist;


    columnsToDisplay = ['status', 'time', 'title', 'actions'];
    canTakeActions: boolean;

    constructor(public dialog: MatDialog, private scapistService: ScapistService,
                private authenticationService: AuthenticationService) {}

    ngOnInit(): void {
      this.canTakeActions = this.authenticationService.userHasAnyRole([
        UserTypeEnum.ROLE_BOKARE,
        UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL
      ]);
    }

    openNewFreeBookModal() {
        const conf = new MatDialogConfig();
        conf.data = this.scapist;
        conf.width = '500px';
        this.dialog.open(FreeBookFormComponent, conf);
    }

    deleteFreeBook(id: number) {
        this.scapistService.deleteFreeBook(id);
    }

    manageFreeBook(id) {
        this.scapistService.manageFreeBook(id);
    }

  isNotBookable() {
    return ScapistStatusUtils.isScapistNotBookable(this.scapist.status);
  }
}
