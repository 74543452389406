import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  @Input() currentValue: number
  @Input() goalValue: number

  ratio: number;

  constructor() { }

  ngOnInit(): void {
    this.computeRatio();
  }

  ngOnChanges(): void {
    this.computeRatio();
  }

  private computeRatio() {
    this.ratio = (this.currentValue || 0)*100.0/(this.goalValue || 1);
  }
}
