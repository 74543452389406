import {Component, Input, OnInit} from '@angular/core';
import {Site} from '@shared/models/site.model';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SiteService} from "@shared/services/site.service";

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss']
})
export class SiteFormComponent implements OnInit {

  @Input() site: Site;
  form: FormGroup;
  constructor(private service: SiteService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const conf = {
      phone: new FormControl(this.site.telephoneNumber, Validators.required),
      recruitmentTarget: new FormControl(this.site.recruitmentTarget, Validators.required),
      address: new FormControl(this.site.address, Validators.required),
      performer: new FormControl(this.site.performer, Validators.required),
      email: new FormControl(this.site.email, [Validators.required, Validators.email]),
      useSecondStartVisit: new FormControl(this.site.hasContinuedStartVisit)
    }
    this.form = this.formBuilder.group(conf);
  }

  onSubmit(value: any) {
    this.service.update(value);
  }
}
