import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FreeBooksComponent} from '@modules/scapists/free-books/free-books.component';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {Scapist} from '@shared/models/scapist.model';

@Component({
  selector: 'app-free-book-form',
  templateUrl: './free-book-form.component.html',
  styleUrls: ['./free-book-form.component.scss']
})
export class FreeBookFormComponent implements OnInit {

    form: FormGroup;

    titles = [
        'RETAKE',
        'LS'
    ]

    constructor(public dialogRef: MatDialogRef<FreeBooksComponent>,
                @Inject(MAT_DIALOG_DATA) public scapist: Scapist,
                private service: ScapistService, private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
      const conf = {
          title: new FormControl(null, Validators.required),
          description: new FormControl(null, Validators.required),
          hour: new FormControl(null, Validators.required),
          minute: new FormControl(null, Validators.required),
          day: new FormControl(null, Validators.required)
      }
      this.form = this.formBuilder.group(conf);
    }

    onSubmit(rawValue: any) {
        if (!this.form.valid) {
            return;
        }
        const time = new Date(rawValue.day);
        time.setHours(rawValue.hour);
        time.setMinutes(rawValue.minute);
        this.service.createFreeBook({
            time, title: rawValue.title, description: rawValue.description, scapistId: this.scapist.id
        });
        this.dialogRef.close();
    }


}
