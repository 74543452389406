import {Component, Input, OnDestroy} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnDestroy{
  @Input() qrCodeURI: string;

  constructor(private authenticationService: AuthenticationService) {
  }
  clean() {
    this.authenticationService.resetQrToken();
  }

  ngOnDestroy(): void {
    this.clean();
  }
}
