<div class="container">
  <div class="content">
    <img src="{{qrCodeURI}}" alt="QR Code to scan">
  </div>
  <div class="content-title">
    <p>Instruktion:</p>
    <ol class="list">
      <li>Öppna Microsoft Authenticator på din mobila enhet</li>
      <li>Tryck på “Verifierade IDn” längst ned i appen</li>
      <li>Skanna QR-koden en gång</li>
      <li>Tryck på "Authenticator" längst ned i appen</li>
      <li>Leta upp "SCAPIS S2" i listan</li>
    </ol>
  </div>

</div>
