import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssueListComponent } from './issue-list/issue-list.component';
import { IssueListContainerComponent } from './issue-list-container/issue-list-container.component';
import {MatIconModule} from "@angular/material/icon";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {UtilsModule} from "@shared/utils/utils.module";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    IssueListComponent,
    IssueListContainerComponent
  ],
  exports: [
    IssueListContainerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    UtilsModule,
    MatButtonModule
  ]
})
export class IssuesModule { }
