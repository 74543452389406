import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {UserListComponent} from '@modules/users/user-list/user-list.component';
import {AuthenticationService} from "@shared/services/authentication.service";
import {FormUtils} from "@shared/utils/form.utils";

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss']
})
export class CreateUserModalComponent implements OnInit {

  form: FormGroup;
  constructor(private formBuilder: FormBuilder, private service: AuthenticationService, public dialogRef: MatDialogRef<UserListComponent>) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.email, Validators.required])
    });
  }

  onSubmit() {
    if(!this.form.valid) {
      return;
    }
    this.service.createUser(this.form.get('email').value);
    this.dialogRef.close();
  }

  get email() {
    return this.form.get('email');
  }

  isDisplayError(formProperty: string) {
    return FormUtils.isDisplayError(this.form, formProperty);
  }

}
