import { Component, OnInit } from '@angular/core';
import {VersionUtils} from '@shared/utils/version.utils';

@Component({
  selector: 'app-version-page',
  templateUrl: './version-page.component.html',
  styleUrls: ['./version-page.component.scss']
})
export class VersionPageComponent implements OnInit {

  releases = VersionUtils.getReleaseNotes();
  redcapReleases = VersionUtils.getRedcapReleaseNotes();

  constructor() { }

  ngOnInit(): void {
  }

}
