import {Component, OnInit} from '@angular/core';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {TimeSlot} from '@shared/models/time-slot.model';

@Component({
  selector: 'app-time-slots-page',
  templateUrl: './time-slots-page.component.html',
  styleUrls: ['./time-slots-page.component.scss']
})
export class TimeSlotsPageComponent implements OnInit {

  constructor(private timeSlotService: TimeSlotService) {
  }

  ngOnInit(): void {
  }

  deleteTimeSlot(timeSlot: TimeSlot) {
    this.timeSlotService.delete(timeSlot);
  }
}
