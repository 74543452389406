<h2>
  Inbjudningar
</h2>

<div>
  <pie-chart
    *ngIf="invations | async as loadedInvitations"
    [labels]="loadedInvitations.serieNames"
    [data]="loadedInvitations.data"
  >
  </pie-chart>

  <p class="summary">Antal inbjudna: <strong>{{numberOfInvited}}</strong></p>
</div>
