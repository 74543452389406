import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {UserService} from '@shared/services/user.service';
import {User} from '@shared/models/user.model';
import {UserTypeDisplay, UserTypeDisplaySite7} from '@shared/constants/user-type.enum';
import {AuthenticationService} from '@shared/services/authentication.service';
import {ViewFormatter} from '@shared/utils/view-formatter';
import {UserRole} from '@shared/models/user-role.model';

@Component({
  selector: 'app-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss']
})
export class UserRoleFormComponent implements OnInit {

  @Input() user: User;
  userRoleForm: FormGroup;
  roles: [string, string][] = [];
  isNotTheSameUser = false;
  currentUser: User;
  isDisabled: boolean;
  disabledRoleButtonInfo = 'Inaktiverad knapp då rollen inte är förändrad eller att personen inte har en site tilldelad.';
  enabledRoleButtonInfo = 'Uppdaterar användarens roll';

  constructor(private userService: UserService, private formBuilder: FormBuilder, private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.getUser();
  }

  ngOnInit() {
    this.isNotTheSameUser = this.currentUser.email !== this.user.email;
    const authorities = (this.user.authorities || []).map(a => a.authority);
    this.userRoleForm = this.formBuilder.group({
      userRole: new FormControl(authorities)
    });
    this.updateDisabledState();
    this.userRoleForm.valueChanges.subscribe(() => {
      this.updateDisabledState();
    });
    if(this.currentUser.site.number === 7) {
      this.roles = Object.entries(UserTypeDisplaySite7);
    } else {
      this.roles = Object.entries(UserTypeDisplay);
    }
  }

  updateDisabledState(): void {
    const userRoleControl = this.userRoleForm.controls.userRole;
    const userSite = this.user.site;
    this.isDisabled = userRoleControl.pristine || !userSite;
  }

  onSubmit(value: any, userId: number) {
    let roleString = 'ROLE_ANVANDARE';
    if (value.userRole.length > 0) {
      roleString = value.userRole.join(',');
    }
    this.userService.updateUserRole(userId, roleString);
  }

  resendCreationEmail(id: number) {
    this.userService.resendCreationEmail(id);
  }

  disableUser = () => {
    this.userService.disableUser(this.user.id);
  }

  enableUser = () => {
    this.userService.enableUser(this.user.id);
  }
}
