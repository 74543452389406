import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

export interface TableButton {
  btnText: string;
  text: string;
  action: (row: any) => void;
  disabled?: (row: any) => void;
}
export interface TableColumn {
  id: string;
  display: string;
  formatter?: TableButton | ((row: any, key?: string) => string);
}

@Component({
  selector: 'app-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.scss'],
  animations: [
    trigger('expandDetails', [
      state('collapsed', style({ height: 0, minHeight: 0 })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ExpandableTableComponent implements OnInit {

  expandedElement: any

  @Input() columns: TableColumn[];
  @Input() data: any[];
  @Input() detailRow!: TemplateRef<any>;
  constructor() { }

  ngOnInit(): void {}

  columnIds() {
    return this.columns.map(({ id }) => id);
  }

  isButton(column: TableColumn) {
    return column.formatter?.hasOwnProperty('text');
  }

  isDisabled(button: TableButton, element: any) {
    return button.disabled(element);
  }

}
