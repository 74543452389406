<div class="day-visit-list">
  <h3>{{dayLabel}}</h3>
  <ng-container *ngIf="sortedTimes.length === 0; then noPersonsInVisitList; else showTable"></ng-container>
  <ng-template #noPersonsInVisitList>
    <p>Finns inga personer i besökslistan för den här dagen som matchar din sökning</p>
  </ng-template>

  <ng-template #showTable>
    <mat-table [dataSource]="sortedTimes" matSort (matSortChange)="sortData($event)">

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="time" sortActionDescription="Sortera efter tid"
            id="tid">Tid
        </th>
        <td mat-cell *matCellDef="let column"> {{column.time | date: 'HH:mm'}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name" sortActionDescription="Sortera efter namn"
            id="name">Namn
        </th>
        <td mat-cell *matCellDef="let column">
          <a href="#" (click)="redirectToScapistPage($event, column)">
            {{column.scapist.firstName}}  {{column.scapist.lastName}}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="personNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="personNumber"
            sortActionDescription="Sortera efter personnummer" id="personNumber">Personnummer
        </th>
        <td mat-cell *matCellDef="let column">{{column.scapist.personNumber | personNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="type"
            sortActionDescription="Sortera efter besökstyp" id="type">Besökstyp
        </th>
        <td mat-cell *matCellDef="let column">
            <span *ngIf="column.timeSlotType != 'FREE_BOOK'">{{dayType(column.timeSlotType)}}</span>
            <span *ngIf="column.timeSlotType == 'FREE_BOOK'">{{column.title | visitType}} - {{column.description}}</span>
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef id="arrive"> Markera ankomst</th>
        <td mat-cell *matCellDef="let row" class="action-column">
          <mat-checkbox
            id="attend-checkbox-{{row.scapist.personNumber}}-row-{{row.timeSlotType}}"
            [matTooltip]="getTooltip(row, 'ATTENDED')"
            [checked]="isChecked(row, 'ATTENDED')"
            (click)="doAttend($event, row)"
            [disabled]="isChecked(row, 'ABSENT')"
            color="primary">Ankommit
          </mat-checkbox>
          <mat-checkbox
            id="absent-checkbox-{{row.scapist.personNumber}}-row-{{row.timeSlotType}}"
            [matTooltip]="getTooltip(row, 'ABSENT')"
            [checked]="isChecked(row, 'ABSENT')"
            (click)="doAbsent($event, row)"
            [disabled]="isChecked(row, 'ATTENDED')"
            color="warn">Uteblivit
          </mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          id="{{row.id}}"
      ></tr>
    </mat-table>

  </ng-template>
</div>
