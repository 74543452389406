import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { User } from '@shared/models/user.model';
import { AuthenticationService } from '@shared/services/authentication.service';
import {MatDividerModule} from '@angular/material/divider';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatIconModule, RouterModule]
})
export class UserNavComponent implements OnInit {
  initials = false;
  constructor(private authenticationService: AuthenticationService, private router: Router, breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium]).subscribe(result => this.initials = result.matches)
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isUserLoggedIn();
  }

  getUser(): User {
    return this.authenticationService.getUser();
  }

  getName(): string {
    const user = this.getUser();
    const firstName = user.firstName;
    const lastName = user.lastName;
    return this.initials ? `${firstName[0]} ${lastName[0]}` : `${firstName} ${lastName}`;
  }

  logOut() {
    this.authenticationService.logOut();
  }

  ngOnInit(): void {
  }

}
