import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { InvitationFormComponent } from './invitation-form/invitation-form.component';
import {InviteScapistsComponent} from '@modules/invitations/invite-scapists/invite-scapists.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { InvitationBasisInfoComponent } from './invitation-basis-info/invitation-basis-info.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortalCommonModule } from '@modules/common/portal-common.module';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';

const declarations = [
  InvitationFormComponent,
  InvitationListComponent,
  InviteScapistsComponent,
  InvitationBasisInfoComponent
  ];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    PortalCommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  exports: declarations,

})
export class InvitationsModule { }
