import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '@modules/auth/login/login.component';
import {AccountComponent} from '@modules/auth/account/account.component';
import {PageNotFoundComponent} from '@modules/common/page-not-found/page-not-found.component';
import {AuthGuardService} from '@shared/services/auth-guard.service';
import {RequestNewPasswordComponent} from '@modules/auth/request-new-password/request-new-password.component';
import {ResetPasswordComponent} from '@modules/auth/reset-password/reset-password.component';
import {VerifyPasswordTokenComponent} from '@modules/auth/verify-password-token/verify-password-token.component';
import {UpdatePasswordComponent} from '@modules/auth/update-password/update-password.component';
import {AdminResourcesComponent} from '@modules/navigation/admin-resources/admin-resources.component';
import {FindScapistComponent} from '@modules/scapists/find-scapist/find-scapist.component';
import {ManageScapistComponent} from '@modules/scapists/manage-scapist/manage-scapist.component';
import {InviteScapistsComponent} from '@modules/invitations/invite-scapists/invite-scapists.component';
import {TimeSlotsPageComponent} from '@modules/time-slots/time-slots-page/time-slots-page.component';
import {SubStudiesPageComponent} from '@modules/sub-studies/sub-studies-page/sub-studies-page.component';
import {SubStudyPageComponent} from '@modules/sub-studies/sub-study-page/sub-study-page.component';
import {CodePageComponent} from '@modules/auth/code-page/code-page.component';
import {UserListComponent} from '@modules/users/user-list/user-list.component';
import {ViewUserComponent} from '@modules/users/view-user/view-user.component';
import {TestScapistComponent} from '@modules/scapists/test-scapists/test-scapist.component';
import {VisitListComponent} from '@modules/scapists/visit-list/visit-list.component';
import {CallListPageComponent} from '@modules/scapists/call-list/call-list-page/call-list-page.component';
import {SitePageComponent} from '@modules/site/site-page/site-page.component';
import {WorkListComponent} from '@modules/users/work-list/work-list.component';
import {EnablePageComponent} from '@modules/auth/enable-page/enable-page.component';
import {StartPageComponent} from '@modules/auth/start-page/start-page.component';
import {VersionPageComponent} from "@modules/common/version-page/version-page.component";
import { StatisticsComponent } from './statistics/statistics.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [AuthGuardService]},
  {path: 'auth-code', component: CodePageComponent, canActivate: [AuthGuardService]},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuardService]},
  {path: 'start', component: StartPageComponent, canActivate: [AuthGuardService]},
  {path: 'requestNewPassword', component: RequestNewPasswordComponent, canActivate: [AuthGuardService]},
  {path: 'verifyPasswordToken', component: VerifyPasswordTokenComponent, canActivate: [AuthGuardService]},
  {path: 'admin-resources', component: AdminResourcesComponent, canActivate: [AuthGuardService]},
  {path: 'call-list', component: CallListPageComponent, canActivate: [AuthGuardService]},
  {path: 'visit-list', component: VisitListComponent, canActivate: [AuthGuardService]},
  {path: 'time-slots', component: TimeSlotsPageComponent, canActivate: [AuthGuardService]},
  {path: 'sub-studies', component: SubStudiesPageComponent, canActivate: [AuthGuardService]},
  {path: 'sub-studies/new', component: SubStudyPageComponent, canActivate: [AuthGuardService]},
  {path: 'sub-studies/:id', component: SubStudyPageComponent, canActivate: [AuthGuardService]},
  {path: 'users', component: UserListComponent, canActivate: [AuthGuardService]},
  {path: 'site', component: SitePageComponent, canActivate: [AuthGuardService]},
  {path: 'users/:id', component: ViewUserComponent, canActivate: [AuthGuardService]},
  {path: 'enableUser', component: EnablePageComponent, canActivate: [AuthGuardService]},

  {path: 'work-list', component: WorkListComponent, canActivate: [AuthGuardService]},

  {path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuardService]},

  {path: 'find-scapist', component: FindScapistComponent, canActivate: [AuthGuardService]},
  {path: 'generate-test-scapists', component: TestScapistComponent, canActivate: [AuthGuardService]},
  {path: 'invite-scapists', component: InviteScapistsComponent, canActivate: [AuthGuardService]},
  {path: 'scapist/:id', component: ManageScapistComponent, canActivate: [AuthGuardService]},
  // For users who have forgotten their password.
  {path: 'resetPassword',  component: ResetPasswordComponent, canActivate: [AuthGuardService]},
  // For logged-in users who want to change their password.
  {path: 'updatePassword',  component: UpdatePasswordComponent, canActivate: [AuthGuardService]},
  {path: 'version', component: VersionPageComponent, canActivate: [AuthGuardService]},
  {path: 'notFound', component: PageNotFoundComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

