<form (ngSubmit)="saveForm()" [formGroup]="form">
  <div mat-dialog-title class="dialog-title">
    <h3>Planera tid</h3>
    <button mat-icon-button aria-label="Stäng planera tid" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <mat-accordion formArrayName="rows">
      <mat-expansion-panel
          *ngFor="let row of formRows.controls; let rowIndex = index; first as isFirst"
          [class]="getAccordionClass(row)"
          [expanded]="rowIndex + 1 == formRows.controls.length"
          [formGroupName]="rowIndex">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ getTitle(rowIndex) }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>
          <mat-label>Besökstyp</mat-label>
          <mat-select formControlName="visit_type">
            <mat-option value="START">{{ "START" | displayName: 'TimeSlotTypeEnum' }}</mat-option>
            <mat-option *ngIf="site.hasContinuedStartVisit" value="CONTINUED_START">{{ "CONTINUED_START" | displayName: 'TimeSlotTypeEnum' }}</mat-option>
            <mat-option value="CT">{{ "CT" | displayName: 'TimeSlotTypeEnum' }}</mat-option>
            <mat-option value="PREVENTION">{{ "PREVENTION" | displayName: 'TimeSlotTypeEnum' }}</mat-option>
            <mat-option value="PREVENTION_FOLLOW_UP">{{ "PREVENTION_FOLLOW_UP" | displayName: 'TimeSlotTypeEnum' }}</mat-option>
          </mat-select>
          <mat-hint>Välj typ av besök</mat-hint>
        </mat-form-field>
          <mat-form-field>
            <mat-label>Varje</mat-label>
            <mat-select formControlName="weekday" multiple>
              <mat-select-trigger>
                {{formatSelection(rowIndex)}}
              </mat-select-trigger>
              <mat-option #allWeekdays (click)="toggleAllSelection(rowIndex)" [value]="-1">Alla veckodagar</mat-option>
              <mat-option
                *ngFor="let weekDay of weekDays; index as n"
                [value]="n"
                (click)="toggleAllSelection(rowIndex, true)"
              >
                {{ weekDay }}
              </mat-option>
            </mat-select>
            <mat-hint>Välj veckodag(ar)</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Från</mat-label>
            <input matInput [matDatepicker]="from" formControlName="from" />
            <mat-datepicker-toggle
              matSuffix
              [for]="from"
            ></mat-datepicker-toggle>
            <mat-datepicker #from></mat-datepicker>
            <mat-hint>Ange startdatum</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Till</mat-label>
            <input matInput [matDatepicker]="to" [min]="minToDates[rowIndex]" formControlName="to" />
            <mat-datepicker-toggle
              matSuffix
              [for]="to"
            ></mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
            <mat-hint>Ange slutdatum</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Antal tider</mat-label>
            <input matInput type="number" formControlName="number" />
            <mat-hint>Tillgängliga tider vid tillfället</mat-hint>
          </mat-form-field>
          <div formArrayName="time">
            <h5>Tider</h5>
            <mat-card appearance="outlined" *ngFor="let time of row.controls.time.controls; let timeIndex = index; last as lastTime, first as firstTime" [formGroupName]="timeIndex">
              <mat-form-field>
                <mat-label>Timme</mat-label>
                <mat-select formControlName="hour">
                  <mat-option value="6">6</mat-option>
                  <mat-option value="7">7</mat-option>
                  <mat-option value="8">8</mat-option>
                  <mat-option value="9">9</mat-option>
                  <mat-option value="10">10</mat-option>
                  <mat-option value="11">11</mat-option>
                  <mat-option value="12">12</mat-option>
                  <mat-option value="13">13</mat-option>
                  <mat-option value="14">14</mat-option>
                  <mat-option value="15">15</mat-option>
                  <mat-option value="16">16</mat-option>
                </mat-select>
                <mat-hint>Välj klockslag</mat-hint>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Minut</mat-label>
                <mat-select formControlName="minute">
                  <mat-option value="00">00</mat-option>
                  <mat-option value="15">15</mat-option>
                  <mat-option value="30">30</mat-option>
                  <mat-option value="45">45</mat-option>
                </mat-select>
                <mat-hint>Välj klockslag</mat-hint>
              </mat-form-field>
              <button matTooltip="Lägg till tid" *ngIf="lastTime" class="time-action time-action--add" mat-mini-fab (click)="$event.preventDefault();addTime(rowIndex)"><mat-icon>add</mat-icon></button>
            <button matTooltip="Ta bort tid" *ngIf="!firstTime" class="time-action time-action--delete" color="warn" mat-mini-fab (click)="$event.preventDefault();removeTime(rowIndex, timeIndex)"><mat-icon>delete</mat-icon></button>
            </mat-card>
          </div>
          <br />
          <button color="warn" *ngIf="!isFirst" mat-raised-button (click)="removeRow(rowIndex)">
            <mat-icon>delete</mat-icon> Ta bort schema
          </button>
          <button mat-raised-button (click)="$event.preventDefault();addRow(rowIndex)">
            <mat-icon>content_copy</mat-icon> Kopiera schema
          </button>
      </mat-expansion-panel>
    </mat-accordion>
    <button *ngIf="form.valid" type="button" color="accent" mat-stroked-button (click)="addRow()">
      <mat-icon>add</mat-icon> Lägg till schema
    </button>
  </mat-dialog-content>
  <mat-dialog-actions class="action-bar">
    <button mat-flat-button color="primary" class="save-button">Spara</button>
    <button mat-stroked-button [mat-dialog-close]="true">Avbryt</button>
  </mat-dialog-actions>
</form>
