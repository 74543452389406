import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

import {environment} from '@environments/environment';
import {NotificationService} from '@shared/services/notification.service';
import {Invitation} from '@shared/models/invitation.model';
import {InvitationBasis} from '@shared/models/invitation-basis.model.ts';
import {InvitationStatus} from '@shared/invitation-status.enum';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class InvitationService {
  private readonly baseUrl: string;

  private invitationsURL = '/invitation/';
  private sendRemindersURL = this.invitationsURL + 'send-reminders';
  private downloadAddressListUrl = this.invitationsURL + 'download-address-list';
  private dataStore: {
    invitations: Invitation[],
    loading: boolean,
    invitationBasis: InvitationBasis
  } = {invitations: [], loading: false, invitationBasis: new InvitationBasis()};
  private _invitations = new BehaviorSubject<Invitation[]>([]);
  private _invitationBasis = new BehaviorSubject<InvitationBasis>(new InvitationBasis());
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading = this._loading.asObservable();
  public readonly invitations = this._invitations.asObservable();
  public readonly invitationBasis = this._invitationBasis.asObservable();


  constructor(private http: HttpClient, private notificationService: NotificationService, private datePipe: DatePipe) {
    this.baseUrl = environment.baseUrl;
    this.loadData();
  }


  public loadData() {
    this._loading.next(true);
    this.http.get<Invitation[]>(this.baseUrl + this.invitationsURL).subscribe(data => {
      const invitations = [];
      data.forEach(item => {
        invitations.push(Object.assign(new Invitation(), item));
      });
      this.dataStore.invitations = invitations;
      this.dispatchInvitations();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in inbjudningar');
    });

    this.http.get<InvitationBasis>(this.baseUrl + this.invitationsURL + 'invitation-basis').subscribe(data => {
      this.dataStore.invitationBasis = data;
      this.dispatchInvitationBasis();
    }, () => {
      this.notificationService.updateNotification('error', 'Lyckades inte ladda inbjudningsunderlag');
    });
  }

  private dispatchInvitations() {
    this._invitations.next(Object.assign({}, this.dataStore).invitations);
  }

  private dispatchInvitationBasis() {
    this._invitationBasis.next(Object.assign({}, this.dataStore).invitationBasis);
  }

  private viewFile(url: string | URL) {
    const token = sessionStorage.getItem('token');
    if (token) {
      url = url + '?token=' + token;
    }
    window.open(url, '_blank');
  }

  create(data: any, callback?: () => void) {
    this.http.post(this.baseUrl + this.invitationsURL, data).subscribe(
      () => {
        this._loading.next(false);
        if (callback) {
          callback();
        }
      }, () => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte skapa');
        if (callback) {
          callback();
        }
      }
    );
  }

  downloadAddressList(invitation: Invitation, isReminder: boolean) {
    const fileName = this.buildFileName(invitation, isReminder);
    this.viewFile(`${this.baseUrl + this.downloadAddressListUrl}/${invitation.id}/${fileName}`);
    this.fakeUpdateStatus(invitation.id);
  }

  sendReminders(invitationId: number) {
    this.http.post(`${this.baseUrl}${this.sendRemindersURL}/${invitationId}`, null).subscribe(
      () => {
        this.loadData();
      }, () => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte läsa in påminnelseadresser');
      }
    );
  }

  private fakeUpdateStatus(invitationId: number) {
    for (const item of this.dataStore.invitations) {
      if(item.id === invitationId) {
        item.status = this.getStatusAfterDownload(item.status);
      }
    }
    this.dispatchInvitations();
  }

  private getStatusAfterDownload(status: InvitationStatus): InvitationStatus {
    if(status === InvitationStatus.INVITE) {
      return InvitationStatus.ADDRESSES_DOWNLOADED;
    }
    if(status === InvitationStatus.REMINDED) {
      return InvitationStatus.REMINDER_ADDRESSES_DOWNLOADED;
    }
    return status;
  }

  private buildFileName(invitation: Invitation, isReminder: boolean): string {
    let filenameExtension = '';
    if (isReminder) {
      filenameExtension = '_påminnelse';
    }
    return `${this.datePipe.transform(invitation.createdAt, 'YYYY-MM-dd')}_core_adresslista${filenameExtension}_${invitation.total}.csv`;
  }
}
