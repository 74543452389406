<div class="container">
  <div class="content-title">
    <h1>Versionsinformation</h1>
  </div>

  <div class="content">
    <div class="row">
      <div class="col-md-6">
        <h2>S2</h2>
        <div class="release" *ngFor="let release of releases">
          <h3>{{release.version}} - {{release.date}}</h3>
          <ul>
            <li *ngFor="let feature of release.features">
              {{feature.title}}
              <ul *ngIf="feature.bullets">
                <li *ngFor="let bullet of feature.bullets">{{bullet}}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <h2>eCRF</h2>
        <div class="release" *ngFor="let release of redcapReleases">
          <h3>{{release.version}} - {{release.date}}</h3>
          <ul>
            <li *ngFor="let feature of release.features">
              {{feature.title}}
              <ul *ngIf="feature.bullets">
                <li *ngFor="let bullet of feature.bullets">{{bullet}}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</div>
