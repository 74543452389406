import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {Booking} from '@shared/models/data-models/booking.model';
import {FreeBookStatusEnum} from '@shared/constants/free-book-status.enum';

export class FreeBook extends Booking {
  title: string;
  description: string;
  declare status: FreeBookStatusEnum;

  constructor() {
    super();
    this.timeSlotType = TimeSlotTypeEnum.FREE_BOOK;
  }
}
