import { AfterContentInit, Component, ContentChild, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogDirective } from './dialog.directive';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() text: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { text: string, action: () => void}, public dialogRef: MatDialogRef<ConfirmModalComponent>) { }
  
  ngOnInit(): void {
      
  }

  confirm() {
    this.data.action();
    this.dialogRef.close();
  }

}

@Component({
  selector: 'confirm-modal-button',
  template: `
      <ng-content></ng-content>
  `
})
export class ConfirmModalButtonComponent implements AfterContentInit {
  @ContentChild(DialogDirective, {static: false}) contentChild;
  @Input() action: () => void;
  @Input() text: string;

  constructor(public dialog: MatDialog) {}

  ngAfterContentInit() {
    this.contentChild.onClick = this.openDialog.bind(this);
  }

  ngOnInit() {}

  openDialog() {
    const conf = new MatDialogConfig();
    conf.data = { action: this.action, text: this.text }
    conf.width = '350px';
    this.dialog.open(ConfirmModalComponent, conf);
  }
}