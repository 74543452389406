import { Component, OnInit } from '@angular/core';
import {share} from 'rxjs/operators';
import {StatisticsService} from '@shared/services/statistics.service';
import { Observable } from 'rxjs';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';

@Component({
  selector: 'app-ct-reviews',
  templateUrl: './ct-reviews.component.html',
  styleUrls: ['./ct-reviews.component.scss']
})
export class CtReviewsComponent implements OnInit {

  ctReviews: Observable<StatisticsModel>;
  ctReviewsPerWeek: Observable<StatisticsModel>;
  ctReviewsTime: Observable<StatisticsModel>;
  ctHeartReviewsTime: Observable<StatisticsModel>;
  ctLungReviewsTime: Observable<StatisticsModel>;
  ctBodyReviewsTime: Observable<StatisticsModel>;


  constructor(private statisticsService: StatisticsService) { }

  ngOnInit(): void {
  this.ctReviews = this.statisticsService.ctReviews.pipe(share());
  this.ctReviewsPerWeek = this.statisticsService.ctReviewsPerWeek.pipe(share());
  this.ctReviewsTime = this.statisticsService.ctReviewsTime.pipe(share());
  this.ctHeartReviewsTime = this.statisticsService.ctHeartReviewsTime.pipe(share());
  this.ctLungReviewsTime = this.statisticsService.ctLungReviewsTime.pipe(share());
  this.ctBodyReviewsTime = this.statisticsService.ctBodyReviewsTime.pipe(share());
  }

}
