import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {ActivatedRoute} from '@angular/router';
import {share} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Scapist } from '@shared/models/scapist.model';

@Component({
  selector: 'app-manage-scapist',
  templateUrl: './manage-scapist.component.html',
  styleUrls: ['./manage-scapist.component.scss']
})
export class ManageScapistComponent implements OnInit, OnDestroy {

  private id: number;

  scapist: Observable<Scapist>;

  constructor(private scapistService: ScapistService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.scapist = this.scapistService.scapist.pipe(share());
    this.route.params.subscribe(
      params => this.id = Number(params.id)
    );
    this.scapistService.getById(this.id);
  }

  ngOnDestroy() {
    this.scapistService.clearScapist();
  }

}
