import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { User } from '@shared/models/user.model';
import {UserService} from '@shared/services/user.service';
import { Observable } from 'rxjs';
import {share} from 'rxjs/operators';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit, OnDestroy {

  private id: number;

  user: Observable<User>;


  constructor(
    private userService: UserService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.user = this.userService.currentUser.pipe(share());
    this.route.params.subscribe(
      params => this.id = params.id
    );
    this.userService.getUserById(this.id);

  }

  ngOnDestroy(): void {
    this.userService.clearCurrentUser();
  }

}
