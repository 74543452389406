import { Injectable } from '@angular/core';
import {LetterResponseModel} from '@shared/models/data-models/letter-response.model';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@shared/services/notification.service';
import {environment} from '@environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LetterService {

  private readonly baseUrl: string;
  private eligibilityCheckUrl = '/site-scapist/check-letter-eligibility/';
  private loadAddress = '/site-scapist/load-address/';


  private _letterResponse = new BehaviorSubject<LetterResponseModel>(null);
  public readonly letterResponse = this._letterResponse.asObservable();

  private dataStore: {
    loading: boolean,
    letterResponse: LetterResponseModel,
  } = {
    loading: false,
    letterResponse: new LetterResponseModel(),
  }

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  public checkEligibility(id: number) {
    this.http.get<LetterResponseModel>((this.baseUrl + this.eligibilityCheckUrl + id)).subscribe((data) => {
      this.dispatchLetterResponse(data);
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte hitta Scapist info');
    });
  }

  private dispatchLetterResponse(data: LetterResponseModel) {
    this.dataStore.letterResponse = data;
    this._letterResponse.next(Object.assign({}, this.dataStore).letterResponse);
  }

  fetchAddress(id: number) {
    this.http.post<LetterResponseModel>(this.baseUrl + this.loadAddress + id, {}).subscribe((data) => {
      this.dispatchLetterResponse(data);
    }, error => {
      this.notificationService.sendErrorNotification('Kunde inte hitta adress, vänligen testa igen. Om problemet ' +
        'kvarstår kontakta din lokala koordinator.');
    });
  }
}
