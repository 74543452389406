import {ScapistStatus} from '@shared/constants/scapist-status.enum';

export class ScapistStatusUtils {

  private static nonBookableStatuses = [
    ScapistStatus.NEW, ScapistStatus.FINISHED, ScapistStatus.PASSIVELY_UNINTERESTED,
    ScapistStatus.INVITED, ScapistStatus.REMOVED_AFTER_INCLUSION, ScapistStatus.REMOVED_BEFORE_INCLUSION,
    ScapistStatus.REMINDED
  ]
  private static scapistStatusOrderMap: Map<ScapistStatus, number> = new Map([[ScapistStatus.NEW, 1],
    [ScapistStatus.INVITED, 2],
    [ScapistStatus.REMINDED, 3],
    [ScapistStatus.INTERESTED, 4],
    [ScapistStatus.PARTLY_BOOKED, 5],
    [ScapistStatus.BOOKED, 6],
    [ScapistStatus.DONE_START, 7],
    [ScapistStatus.NO_SHOW_CONTINUED_START, 7],
    [ScapistStatus.NO_SHOW_CT, 7],
    [ScapistStatus.NO_SHOW_START, 7],
    [ScapistStatus.DONE_CT, 8],
    [ScapistStatus.PARTLY_BOOKED_AFTER_INCLUSION, 8],
    [ScapistStatus.BOOK_PREVENTION, 9],
    [ScapistStatus.NO_SHOW_PREVENTION, 9],
    [ScapistStatus.PREVENTION_BOOKED, 10],
    [ScapistStatus.DONE_PREVENTION, 11],
    [ScapistStatus.NO_SHOW_PREVENTION_FOLLOW_UP, 11],
    [ScapistStatus.NO_PREVENTION_FOLLOW_UP_BOOKED, 11],
    [ScapistStatus.FOLLOW_UP_PREVENTION_BOOKED, 12],
    [ScapistStatus.FINISHED, 13]]);

  public static isTargetStatusEligible(currentStatus: ScapistStatus, targetStatus: ScapistStatus) {
    const currentScapistStatusOrder = this.scapistStatusOrderMap.get(currentStatus);
    const targetScapistStatusOrder = this.scapistStatusOrderMap.get(targetStatus);

    if (currentStatus === ScapistStatus.REMOVED_BEFORE_INCLUSION || currentStatus === ScapistStatus.REMOVED_AFTER_INCLUSION) {
      return false;
    }

    if (currentScapistStatusOrder != null && targetScapistStatusOrder != null) {
      return currentScapistStatusOrder >= targetScapistStatusOrder;
    }
    return false;
  }

  public static isScapistPassivelyUninterested(status: ScapistStatus) {
    return status === ScapistStatus.PASSIVELY_UNINTERESTED;
  }

  public static isScapistRemoved(status: ScapistStatus) {
    return status === ScapistStatus.REMOVED_BEFORE_INCLUSION || status === ScapistStatus.REMOVED_AFTER_INCLUSION;
  }

  public static isScapistNotBookable(status: ScapistStatus): boolean {
    return this.nonBookableStatuses.includes(status)
  }

}
