import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TimeSlot} from '@shared/models/time-slot.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TimeSlotFormComponent} from '@modules/time-slots/time-slot-form/time-slot-form.component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TimeSlotFilter, TimeSlotFilterEnum} from '@shared/constants/time-slot-filter.enum';
import {DayFilterEnum} from '@shared/constants/day-filter.enum';
import {SummeredTimeSlot} from '@shared/utils/day-type-slots.pipe';
import {RepeatingTimeSlotFormComponent} from '../repeating-time-slot-form/repeating-time-slot-form.component';
import {HandleTimeslotsComponent} from '../handle-timeslots/handle-timeslots.component';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {share} from 'rxjs/operators';
import {GroupTimeSlotsPipe} from '@shared/utils/group-time-slots.pipe';
import {DateUtils} from '@shared/utils/date.utils';
import {ConfirmModalComponent} from "@modules/common/confirm-modal/confirm-modal.component";
import {DatePipe} from "@angular/common";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-time-slot-index',
  templateUrl: './time-slot-index.component.html',
  styleUrls: ['./time-slot-index.component.scss']
})
export class TimeSlotIndexComponent implements OnInit {

  $timeSlots: Observable<TimeSlot[]>;
  timeSlots: Map<string, TimeSlot[]>;

  @Output() deletedTimeSlot: EventEmitter<TimeSlot> = new EventEmitter<TimeSlot>()

  form: FormGroup;

  from: Date;
  to: Date;
  filterList = TimeSlotFilter;
  typeFilter = {...DayFilterEnum};
  filteredTimeSlotsMap: Map<string, TimeSlot[]> = new Map();
  hours: string[];
  minutes = ['00', '15', '30', '45'];
  MAX_DATE_SPAN = 182;

  dates: Date[][];

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private datePipe: DatePipe,
              private timeSlotService: TimeSlotService, private groupTimeSlots: GroupTimeSlotsPipe) {
    delete this.typeFilter.FREE_BOOK;

    this.hours = Array.from({length: 11}, (_, i) => {
      const hour = i + 6;
      return hour < 10 ? `0${hour}` : `${hour}`;
    });
  }

  ngOnInit(): void {
    this.$timeSlots = this.timeSlotService.timeSlots.pipe(share());
    this.resetFilters();

    this.from = new Date(new Date().toDateString());
    this.to = new Date(new Date().toDateString());
    this.timeSlotService.getTimeSlotsWithInterval(this.to, this.from);
    this.$timeSlots.subscribe(list => {
      this.timeSlots = this.groupTimeSlots.transform(list);
      this.filterTimeSlots();
    });
    this.to.setMonth(this.to.getMonth() + 1);
    this.setupDateList();

    const conf = {
      from: new FormControl(this.from),
      to: new FormControl(this.to)
    }
    this.form = this.formBuilder.group(conf);

    this.form.valueChanges.subscribe((value) => {
      if (DateUtils.isValidDateRange(value.from, value.to)) {
        const diffInDays = DateUtils.dateDifferenceInDays(value.from, value.to);
        if (diffInDays < this.MAX_DATE_SPAN) {
          this.from = new Date(value.from);
          this.to = new Date(value.to);
          this.setupDateList();
        }
      }
    });
  }

  resetFilters() {
    for (const key in this.typeFilter) {
      if (DayFilterEnum.hasOwnProperty(key)) {
        this.typeFilter[key].active = true;
      }
    }
    for (const key in TimeSlotFilterEnum) {
      if (TimeSlotFilterEnum.hasOwnProperty(key)) {
        this.filterList[key].active = true;
      }
    }
  }

  setupDateList() {
    this.dates = [];
    if (DateUtils.isValidDateRange(this.from, this.to)) {
      this.from.setDate(this.from.getDate() - ((this.from.getDay() + 6) % 7));
      this.to.setDate(this.to.getDate() - ((this.to.getDay() + 6) % 7) + 6);
      this.timeSlotService.getTimeSlotsWithInterval(this.to, this.from);
      for (const dt = new Date(this.from); dt <= new Date(this.to); dt.setDate(dt.getDate() + 1)) {
        if (dt.getDay() === 1) {
          this.dates.push([]);
        }
        this.dates[this.dates.length - 1].push(new Date(dt));
      }
    }
  }

  openNewTimeSlotModal(day: Date) {
    const conf = new MatDialogConfig();
    conf.data = day;
    conf.width = '800px';
    this.dialog.open(TimeSlotFormComponent, conf);
  }

  openRepeatingTimeSlotModal() {
    const conf = new MatDialogConfig();
    conf.width = '1180px';
    conf.maxHeight = '90vh';
    conf.data = {
      from: this.from,
      to: this.to
    }
    this.dialog.open(RepeatingTimeSlotFormComponent, conf);
  }

  getDayString(timeSlotType: string) {
    if (!timeSlotType) {
      return 'Okänt';
    }
    return DayFilterEnum[timeSlotType].display;
  }

  getTooltip(slot: SummeredTimeSlot) {
    return `${slot.numberOfAvailableTimeSlots} av ${slot.totalNumberOfTimeSlots} tillgängliga ${this.getDayString(slot.type)} klockan ${slot.time}`;
  }

  filterTimeSlots() {
    const newFiltredTimeSlotsMap = new Map();
    this.timeSlots.forEach((timeSlots, key) => {
      const filteredObjects = timeSlots.filter(obj => this.filterList[obj.status].active && this.typeFilter[obj.timeSlotType].active);
      if (filteredObjects.length > 0) {
        newFiltredTimeSlotsMap.set(key, filteredObjects);
      }
    });
    this.filteredTimeSlotsMap = newFiltredTimeSlotsMap;
  }

  getTopPosition(minutes) {
    return `${(parseFloat(minutes) / 60) * 100}%`;
  }

  checkFilter(status: string, checked: boolean) {
    if (this.typeFilter[status]) {
      this.typeFilter[status].active = checked;
    } else {
      if (status === TimeSlotFilterEnum.BOOKED) {
        this.filterList[TimeSlotFilterEnum.ATTENDED].active = checked;
        this.filterList[TimeSlotFilterEnum.ABSENT].active = checked;
      }
      this.filterList[status].active = checked;
    }
    this.filterTimeSlots();
  }


  removeTimeSlot(timeSlot: TimeSlot) {
    this.deletedTimeSlot.emit(timeSlot);
  }

  cursorPosition() {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    if (hour < 6 || hour > 16) {
      return -1;
    }
    const currentTime = hour + (minute / 60);
    const passedHours = currentTime - parseFloat(this.hours[0]);
    return `calc((112px * ${passedHours}) + 3em + 5px)`;
  }

  openTimeslotHandler(slot: SummeredTimeSlot, date: Date) {
    let slots = [];
    const [h, m] = slot.time.split(':');
    date.setHours(parseFloat(h));
    date.setMinutes(parseFloat(m));

    for (const [_, array] of this.timeSlots.entries()) {
      slots = [
        ...slots,
        ...array.filter(({time, timeSlotType}) => {
          time.setSeconds(0);
          return time.getTime() === date.getTime() && timeSlotType === slot.type;
        })];
    }
    const conf = new MatDialogConfig();
    conf.data = slots;
    conf.width = '800px';
    this.dialog.open(HandleTimeslotsComponent, conf);

  }

  doNotSort() {
    return 0;
  }

  removeAll(day: Date) {
    const conf = new MatDialogConfig();
    conf.data = { action: this.timeSlotService.deleteByDate.bind(this.timeSlotService, day), text: `Är du säker på att du vill ta bort alla obokade tider den ${this.datePipe.transform(day, 'YYYY-MM-dd')}?` }
    conf.width = '350px';
    this.dialog.open(ConfirmModalComponent, conf);
  }
}
