<h2>
  Studietakt
</h2>
<div class="row">
  <div class="col-md-6 offset-md-3">
    <app-progress-bar [currentValue]="currentFinished" [goalValue]="studyGoal"></app-progress-bar>
  </div>

  <div class="col-md-6">
    <line-chart
      *ngIf="studyPaceCumulative | async as loadedStudyPaceCumulative"
      [labels]="loadedStudyPaceCumulative.serieNames"
      [data]="loadedStudyPaceCumulative.data"
    >
    </line-chart>
  </div>
  <div class="col-md-6">
    <bars-chart
      *ngIf="studyPace | async as loadedStudyPace"
      [labels]="loadedStudyPace.serieNames"
      [data]="loadedStudyPace.data">
    </bars-chart>
  </div>
  <div class="col-md-6" *ngIf="studyPaceTimeDistributionSUP | async as loadedStudyPaceTimeDistributionSUP">
    <h3>Med prevention</h3>
    <pie-chart

      [labels]="loadedStudyPaceTimeDistributionSUP.serieNames"
      [data]="loadedStudyPaceTimeDistributionSUP.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid från inklusion: <strong>{{loadedStudyPaceTimeDistributionSUP.average | number: '1.0-2'}} dagar</strong></p>
  </div>
  <div class="col-md-6" *ngIf="studyPaceTimeDistributionNoSUP | async as loadedStudyPaceTimeDistributionNoSUP">
    <h3>Utan prevention</h3>
    <pie-chart

      [labels]="loadedStudyPaceTimeDistributionNoSUP.serieNames"
      [data]="loadedStudyPaceTimeDistributionNoSUP.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid från inklusion: <strong>{{loadedStudyPaceTimeDistributionNoSUP.average | number: '1.0-2'}} dagar</strong></p>
  </div>
</div>
