import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {UserNavComponent} from '../user-nav/user-nav.component';
import {environment} from '@environments/environment';
import {EnvironmentMode} from '@shared/constants/environment-mode.enum';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, RouterLink, RouterLinkActive, MatMenuModule, UserNavComponent]
})
export class MainNavComponent implements OnInit {

  public menuTriggers: MatMenuTrigger[] = [];
  mode: EnvironmentMode;

  navItems = []
  constructor(private authenticationService: AuthenticationService) {
    this.mode = environment.environmentString;
    this.navItems = [
      {
        label: 'Bjuda in',
        route: 'invite-scapists',
        icon: 'person_add',
        authorized: this.authenticationService.userHasRole(UserTypeEnum.ROLE_REKRYTERARE)
      },
      {
        label: 'Ringlistor',
        route: 'call-list',
        icon: 'call_log',
        authorized: this.authenticationService.userHasAnyRole([UserTypeEnum.ROLE_BOKARE, UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA])
      },
      {
        label: 'Besöklista',
        route: 'visit-list',
        icon: 'list_alt',
        authorized: this.authenticationService.userHasAnyRole([
          UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL,
          UserTypeEnum.ROLE_RONTGENSJUKSKOTERSKA,
          UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA,
          UserTypeEnum.ROLE_LS_LAKARE,
          UserTypeEnum.ROLE_LS_PERSONAL,
          UserTypeEnum.ROLE_SPIRO
        ])
      },
      {
        label: 'Arbetslistor',
        route: 'work-list',
        icon: 'receipt_long',
        authorized: this.authenticationService.userHasAnyRole([
          UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL,
          UserTypeEnum.ROLE_STUDIELAKARE,
          UserTypeEnum.ROLE_RONTGENSJUKSKOTERSKA,
          UserTypeEnum.ROLE_GRANSKARE_HJARTA,
          UserTypeEnum.ROLE_GRANSKARE_LUNGA,
          UserTypeEnum.ROLE_GRANSKARE_KROPP,
          UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA,
          UserTypeEnum.ROLE_SPIRO,
        ])
      },
      {
        label: 'Deltagare',
        route: 'find-scapist',
        icon: 'people',
        authorized: this.authenticationService.userHasAnyRole([
          UserTypeEnum.ROLE_BOKARE,
          UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL,
          UserTypeEnum.ROLE_STUDIELAKARE,
          UserTypeEnum.ROLE_RONTGENSJUKSKOTERSKA,
          UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA,
          UserTypeEnum.ROLE_SPIRO,
          UserTypeEnum.ROLE_GRANSKARE_HJARTA,
          UserTypeEnum.ROLE_GRANSKARE_LUNGA,
          UserTypeEnum.ROLE_GRANSKARE_KROPP,
          UserTypeEnum.ROLE_LS_LAKARE,
          UserTypeEnum.ROLE_LS_PERSONAL
        ])
      },
      {
        label: 'Tidsbok',
        route: 'time-slots',
        icon: 'calendar_month',
        authorized: this.authenticationService.userHasRole(UserTypeEnum.ROLE_TIDBOKNINGSANSVARIG)
      },
      {
        label: 'Statistik',
        route: 'statistics',
        icon: 'bar_chart_4_bars',
        authorized: this.authenticationService.userHasAnyRole([
          UserTypeEnum.ROLE_STATISTIK_NATIONELLT,
          UserTypeEnum.ROLE_STATISTIK_SITE
        ])
      },
      {
        label: 'Användare',
        route: 'users',
        icon: 'groups',
        authorized: this.authenticationService.userHasRole(UserTypeEnum.ROLE_BEHORIGHETSANSVARIG)
      },
      {
        label: 'Lokala substudier',
        route: 'sub-studies',
        icon: 'groups',
        authorized: this.authenticationService.userHasRole(UserTypeEnum.ROLE_LOKALA_SUBSTUDIER)
      },
      {
        label: 'Generera testscapister',
        route: 'generate-test-scapists',
        icon: 'baby_changing_station',
        authorized: this.mode !== EnvironmentMode.PRODUCTION && this.mode !== EnvironmentMode.EDU
      },
    ]
  }

  ngOnInit() {
  }

}
