import { Component, OnInit } from '@angular/core';
import {share} from 'rxjs/operators';
import {StatisticsService} from '@shared/services/statistics.service';
import { Observable } from 'rxjs';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';

@Component({
  selector: 'app-prevention',
  templateUrl: './prevention.component.html',
  styleUrls: ['./prevention.component.scss']
})
export class PreventionComponent implements OnInit {

    preventionShare: Observable<StatisticsModel>;
    preventionVisitShare: Observable<StatisticsModel>;
    preventionFollowupShare: Observable<StatisticsModel>;
    preventionVisitTime: Observable<StatisticsModel>;
    preventionFollowupTime: Observable<StatisticsModel>;


  constructor(private statisticsService: StatisticsService) { }

  ngOnInit(): void {
    this.preventionShare = this.statisticsService.preventionShare.pipe(share());
    this.preventionVisitShare = this.statisticsService.preventionVisitShare.pipe(share());
    this.preventionFollowupShare = this.statisticsService.preventionFollowupShare.pipe(share());
    this.preventionVisitTime = this.statisticsService.preventionVisitTime.pipe(share());
    this.preventionFollowupTime = this.statisticsService.preventionFollowupTime.pipe(share());
  }

}
