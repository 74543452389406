<div *ngIf="featureToggle">
  <div class="card">
    <div class="card-body">
      <h3>Scapister</h3>
      <ng-container *ngIf="scapistList.length === 0; then noPersonsInWorkList; else showTable"></ng-container>
      <ng-template #noPersonsInWorkList>
        <p>Inga ohanterade scapister inkluderade scapister.</p>
      </ng-template>

      <ng-template #showTable>
        <mat-table [dataSource]="scapistList" matSort (matSortChange)="sortData($event)" [matSortActive]="sessionSort.active" [matSortDirection]="sessionSort.direction">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name" sortActionDescription="Sortera efter name" id="name">Namn</th>
            <td mat-cell *matCellDef="let column" class="clickable-cell" (click)="this.redirectToBookingPage(column.id)"
            >
              {{column.firstName}}  {{column.lastName}} {{column.status == 'REMOVED_AFTER_INCLUSION' ? '(AVBRUTEN)' : ''}}
              <mat-icon [inline]="true" class="inline-icon">open_in_new</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="personNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="personNumber" sortActionDescription="Sortera efter personNumber" id="personNumber">Personnummer</th>
            <td mat-cell *matCellDef="let column" class="td-container clickable-cell" (click)="this.copyToClipboard(column.personNumber)">{{column.personNumber | personNumber}}
              <mat-icon [inline]="true" class="inline-icon">content_copy</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status" sortActionDescription="Sortera efter status" id="status">Status</th>
            <td mat-cell *matCellDef="let column">
              {{column.status | displayName: 'ScapistStatus'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="inclusion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="inclusion" sortActionDescription="Sortera efter start besök" id="inclusion">Startbesöksdatum</th>
            <td mat-cell *matCellDef="let column">
              {{column.inclusion | date : 'YYYY-MM-dd HH:mm'}}
            </td>

          </ng-container>
          <ng-container matColumnDef="ctDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ctDate" sortActionDescription="Sortera efter start besök" id="ctDate">CT-besöksdatum</th>
            <td mat-cell *matCellDef="let column">
              {{column.ctDate | date : 'YYYY-MM-dd HH:mm'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="phone"
                sortActionDescription="Sortera efter telefonnummer" id="phone">Telefonnummer
            </th>
            <td mat-cell *matCellDef="let column">{{column.phone}}</td>
          </ng-container>


          <ng-container matColumnDef="manage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="manage"
                sortActionDescription="Sortera efter hanterad" id="manage">Hanterad
            </th>
            <td mat-cell *matCellDef="let column">
              <confirm-modal-button
                [action]="this.manage.bind(this, column)"
                [text]="'Är du säker på att du vill markera denna scapist som hanterad?'">
                <button dialogButton mat-flat-button color="accent" type="submit">
                  <mat-icon class="centered-icon">assignment</mat-icon>
                  Markera som hanterad
                </button>
              </confirm-modal-button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              id="{{row.id}}"
          >
          </tr>

        </mat-table>
      </ng-template>
    </div>
  </div>
</div>
