<div class="container">
  <div class="row">
    <div class="col-md-12">
      <button mat-stroked-button (click)="addRow()">Skapa scapist</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form class="create-scapist-form" [formGroup]="createScapistForm">
        <mat-table [dataSource]="scapists" matSort>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef id="name">Namn</th>
            <td mat-cell *matCellDef="let scapist">
              <ng-container *ngIf="scapist.id">
                {{scapist.firstName}}  {{scapist.lastName}}
              </ng-container>
              <ng-container *ngIf="!scapist.id">
                <mat-form-field><input matInput formControlName="firstName" [value]="scapist.firstName"></mat-form-field>
                <mat-form-field><input matInput formControlName="lastName" [value]="scapist.lastName"></mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef id="phone">Telefonnummer</th>
            <td mat-cell *matCellDef="let scapist">
              <ng-container *ngIf="scapist.id">
                {{scapist.phone}}
              </ng-container>
              <ng-container *ngIf="!scapist.id">
                <mat-form-field><input matInput formControlName="phone" [value]="scapist.phone"></mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef id="email">Email</th>
            <td mat-cell *matCellDef="let scapist">
              <ng-container *ngIf="scapist.id">
                {{scapist.email}}
              </ng-container>
              <ng-container *ngIf="!scapist.id">
                <mat-form-field class="email"><input matInput formControlName="email" type="email" [value]="scapist.email"></mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef id="gender">Kön</th>
            <td mat-cell *matCellDef="let scapist">
              <ng-container *ngIf="scapist.id">
                {{scapist.gender}}
              </ng-container>
              <ng-container *ngIf="!scapist.id">
                <mat-form-field>
                  <mat-select formControlName="gender" [value]="scapist.gender">
                    <mat-option value="MALE">
                      Man
                    </mat-option>
                    <mat-option value="FEMALE">
                      Kvinna
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="personNumber">
            <th mat-header-cell *matHeaderCellDef id="personNumber">Personnummer</th>
            <td mat-cell *matCellDef="let scapist">
              <ng-container *ngIf="scapist.id">
                {{scapist.personNumber}}
              </ng-container>
              <ng-container *ngIf="!scapist.id">
                <mat-form-field><input matInput formControlName="personNumber" [defaultValue]="scapist.personNumber"></mat-form-field>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef id="actions">Personnummer</th>
            <td mat-cell *matCellDef="let scapist">
              <button *ngIf="!scapist.id" mat-icon-button color="primary" (click)="createScapist()">
                <mat-icon>save</mat-icon>
              </button>
              <confirm-modal-button
                text="Är du säker på att du vill ta bort {{scapist.firstName}} {{scapist.lastName}}?"
                [action]="deleteScapist.bind(this,scapist.personNumber)">
                <button mat-icon-button dialogButton>
                  <mat-icon>delete</mat-icon>
                </button>
              </confirm-modal-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"
              id="{{row.id}}"
              (click)="redirectToBookingPage(row.id)"
          ></tr>
        </mat-table>
      </form>
    </div>
  </div>
</div>
