import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {share} from 'rxjs/operators';
import {VisitListService} from '@shared/services/visit-list.service';
import {TimeSlot} from '@shared/models/time-slot.model';
import {VisitListDay} from '@shared/models/data-models/visit-list-day.model';
import {DayFilterEnum} from '@shared/constants/day-filter.enum';
import {DateUtils} from '@shared/utils/date.utils';
import {SessionVisitListFilter} from '@shared/models/session.visit-list-filter';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-visit-list',
  templateUrl: './visit-list.component.html',
  styleUrls: ['./visit-list.component.scss']
})
export class VisitListComponent implements OnInit {

  form: FormGroup;
  $days: Observable<VisitListDay[]>;
  $pastDays: Observable<VisitListDay[]>;
  loading: Observable<boolean>;
  dayEnumType = DayFilterEnum;

  pages = ['Besökslista', 'Omarkerade tidigare besök'];

  openPage = 0;

  filteredDays: VisitListDay[];
  days: VisitListDay[];
  filteredPastDays: VisitListDay[];
  pastDays: VisitListDay[];
  MAX_DATE_SPAN = 182;
  sessionVisitListFilter: SessionVisitListFilter = { to: undefined, from: undefined, dayFilterEnum : DayFilterEnum};

  constructor(private visitListService: VisitListService, private formBuilder: FormBuilder) {
  }
  ngOnInit(): void {
    this.$days = this.visitListService.timeSlots.pipe(share());
    this.$pastDays = this.visitListService.pastTimeSlots.pipe(share());
    this.loading = this.visitListService.loading.pipe(share());
    if (sessionStorage.getItem('sessionVisitListFilter')) {
      this.sessionVisitListFilter = JSON.parse(sessionStorage.getItem('sessionVisitListFilter'));
    }
    this.dayEnumType = this.sessionVisitListFilter.dayFilterEnum;
    const form = {
      from: new FormControl(this.sessionVisitListFilter.from? this.sessionVisitListFilter.from : null, Validators.required),
      to: new FormControl(this.sessionVisitListFilter.to? this.sessionVisitListFilter.to : null, Validators.required)
    }
    this.form = this.formBuilder.group(form);
    const self = this;
    form.to.valueChanges.subscribe( ()=> {
      self.loadData();
    });
    this.loadData();
    this.$days.subscribe(data => {
      this.days = data;
      this.filterDays();
    });
    this.$pastDays.subscribe(data => {
      this.pastDays = data;
      this.filterPastDays();
      this.pages[1] = `Omarkerade tidigare besök (${this.countSlots(data)})`;
    })
  }
  countSlots(data: VisitListDay[]): number {
    let count = 0;
    for (const day of data) {
      count += day.timeSlots.length;
    }
    return count;
  }

  attend(slot: TimeSlot) {
    this.visitListService.attend(slot, this.getLoader());
  }

  absent(slot: TimeSlot) {
    this.visitListService.absent(slot, this.getLoader());
  }

  reset(slot: TimeSlot) {
    this.visitListService.reset(slot, this.getLoader());
  }

  getLoader(): () => void {
    const self = this;
    return () => {
      self.loadData()
    };
  }

  selectPage(index: number) {
    this.openPage = index;
  }

  className(index: number) {
    return this.openPage === index ? 'active' : '';
  }

  loadData() {
    const from = this.form.get('from').value ? new Date(this.form.get('from').value) : new Date();
    const to = this.form.get('to').value ? new Date(this.form.get('to').value) : new Date();
    if (DateUtils.isValidDateRange(from, to)) {
      const diffInDays = DateUtils.dateDifferenceInDays(from, to);
      if (diffInDays <= this.MAX_DATE_SPAN) {
        this.visitListService.loadData(from, to);
        this.sessionVisitListFilter = {...this.sessionVisitListFilter, from: this.from, to: this.to};
        sessionStorage.setItem('sessionVisitListFilter', JSON.stringify(this.sessionVisitListFilter))
      }
    }
    this.visitListService.loadPastTimeSlots();
  }

  get from() {
    return this.form.get('from').value;
  }

  get to() {
    return this.form.get('to').value;
  }

  setToday() {
    this.form.setValue({
      from: new Date().toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
    });
    this.sessionVisitListFilter = {...this.sessionVisitListFilter, from: this.from, to:this.to};
    sessionStorage.setItem('sessionVisitListFilter', JSON.stringify(this.sessionVisitListFilter))
  }

  checkFilter(status: string, checked: boolean) {
    this.dayEnumType[status].active = checked;
    this.sessionVisitListFilter.dayFilterEnum = this.dayEnumType;
    sessionStorage.setItem('sessionVisitListFilter', JSON.stringify(this.sessionVisitListFilter));
    this.filterDays();
    this.filterPastDays();
  }

  clearFilter() {
    for (const [key] of Object.entries(this.dayEnumType)) {
      this.dayEnumType[key].active = false;
    }
    this.sessionVisitListFilter.dayFilterEnum = this.dayEnumType;
    sessionStorage.setItem('sessionVisitListFilter', JSON.stringify(this.sessionVisitListFilter));
    this.filterDays();
  }

  filterDays() {
    this.filteredDays = this.days;
    this.filteredDays = this.applyFilter(this.days);
  }

  filterPastDays() {
    this.filteredPastDays = this.pastDays;
    this.filteredPastDays = this.applyFilter(this.pastDays);
  }

  private applyFilter(visitDays: VisitListDay[]): VisitListDay[] {
    return visitDays.map((visitDay) => {
      const filteredTimeSlots = visitDay.timeSlots.filter((d) => this.dayEnumType[d.timeSlotType].active);
      return {...visitDay, timeSlots: filteredTimeSlots};
    });
  }

  doNotSort() {
    return 0;
  }

}
