<div class="container">
  <div class="row">
    <div class="col-md-6">
      <mat-card appearance="outlined" class="h-100">
        <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="onSubmit(form.getRawValue())">
            <mat-form-field>
              <mat-label>Hur många vill du bjuda in?</mat-label>
              <input matInput type="number" class="form-control" formControlName="number" id="number" min="1">
              <mat-error>
                {{errorMessage()}}
              </mat-error>
            </mat-form-field>
              <button [color]="isLoading ? 'accent' : 'primary'" mat-raised-button [disabled]="!form.valid || isLoading" type="submit">
                <ng-container *ngIf="!isLoading">Bjud in</ng-container>
                <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
              </button>
          </form>
          <mat-progress-bar *ngIf="isLoading" mode="determinate" [value]="progress"></mat-progress-bar>
        </div>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card appearance="outlined" class="h-100">
        <app-invitation-basis-info [info]="info"></app-invitation-basis-info>
      </mat-card>
    </div>
  </div>
</div>
