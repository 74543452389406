<mat-card appearance="outlined">
    <h4>Extrabesök</h4>
    <mat-table [dataSource]="scapist.freebooks" matSort>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef id="freebook-status" mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let freeBook">{{freeBook.status | visitStatus: true}}</td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef id="title" mat-sort-header>Besök</th>
            <td mat-cell *matCellDef="let freeBook">{{freeBook.title | visitType}} - {{freeBook.description}}</td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef id="time" mat-sort-header>Tid</th>
            <td mat-cell *matCellDef="let freeBook">{{freeBook.time | date:'yyyy-MM-dd HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef id="actions" mat-sort-header>Hantera</th>
            <td mat-cell *matCellDef="let freeBook">
                <confirm-modal-button
                *ngIf="freeBook.status === 'BOOKED' && canTakeActions"
                text="Är du säker på att du vill avboka {{freeBook.title | visitType}} för {{scapist.firstName}} {{scapist.lastName}}?"
                [action]="deleteFreeBook.bind(this, freeBook.id)">
                <button dialogButton mat-button color="warn">AVBOKA</button>
                </confirm-modal-button>
                <confirm-modal-button
                *ngIf="freeBook.status === 'ABSENT' && canTakeActions"
                text="Har du bokat in nytt {{freeBook.title | visitType}} för {{scapist.firstName}} {{scapist.lastName}} eller säkerställt att det inte behövs?"
                [action]="manageFreeBook.bind(this, freeBook.id)">
                <button dialogButton mat-button>MARKERA HANTERAD</button>
                </confirm-modal-button>
            </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"
            id="{{row.id}}"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    </mat-table>
    <button (click)="openNewFreeBookModal()" [disabled]="isNotBookable() || !canTakeActions" mat-raised-button>+</button>
</mat-card>
