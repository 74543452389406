
export class Site {

  id: string;
  name: string;

  number: number;

  telephoneNumber: string;
  email: string;
  address: string;
  performer: string;
  recruitmentTarget: number;
  hasContinuedStartVisit: boolean;
}
