import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { StatisticsDataModel } from '@shared/models/data-models/statistics.model';
import { BarController, BarElement, CategoryScale, Chart, ChartOptions, LinearScale } from 'chart.js';

@Component({
  selector: 'bars-chart',
  templateUrl: './bars.component.html',
  styleUrls: ['./bars.component.scss']
})
export class BarsComponent implements OnInit, OnChanges {

  @Input() data: StatisticsDataModel[];
  @Input() labels: string[];

  barsChartOptions: ChartOptions<'bar'>;
  barsChartLabels: number[] = [];
  public barsChartDatasets = [];

  constructor() {
  }

  ngOnInit() {
    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.data || changes.labels) {
      this.drawChart();
    }
  }

  drawChart(): void {
    if(!this.data) {
      this.data = [];
    }
    if(!this.labels) {
      this.labels = [];
    }
    this.barsChartLabels = [];
    this.barsChartDatasets = [];
    this.labels.forEach((label, index) => {
      this.barsChartDatasets.push({ label, data: this.data.map(dataSet => dataSet.values[index]), pointLabels: { display: false } })
    });
    this.data.forEach(({ label }) => this.barsChartLabels.push(label));
     this.barsChartOptions = {
      plugins: {
        datalabels: { display: false }
      },
      scales: {
        x: {
          display: true,
            title: {
            display: true,
            text: 'Vecka'
          }
        },
        y: {
          display: true,
            title: {
            display: true,
            text: 'Antal scapister'
          }
        }
      }
    };
    Chart.register(BarController);
    Chart.register(BarElement);
    Chart.register(CategoryScale);
    Chart.register(LinearScale);
  }

}
