import {Component} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import { Observable } from 'rxjs';
import {share} from 'rxjs/operators';

@Component({
  selector: 'app-code-page',
  templateUrl: './code-page.component.html',
  styleUrls: ['./code-page.component.css']
})
export class CodePageComponent {
  qrCodeUri: Observable<string>;

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.qrCodeUri = this.authenticationService.qrToken.pipe(share());
  }

  checkAuthCode(authCode: any) {
    this.authenticationService.verify(authCode);
  }

}
