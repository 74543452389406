import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "@shared/services/authentication.service";
import {Site} from "@shared/models/site.model";

@Component({
  selector: 'app-site-page',
  templateUrl: './site-page.component.html',
  styleUrls: ['./site-page.component.scss']
})
export class SitePageComponent implements OnInit {

  site: Site;
  constructor(private service: AuthenticationService) { }

  ngOnInit(): void {
    this.site = this.service.getUser().site;
  }

}
