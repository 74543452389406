import {TimeSlot} from '@shared/models/time-slot.model';

export class EmptyTimeSlot {
  timesStart: string[];
  startDaySlots: Map<string, TimeSlot>
  timesContinuedStart: string[];
  continuedStartDaySlots: Map<string, TimeSlot>
  timesCT: string[];
  ctDaySlots: Map<string, TimeSlot>
  timesPrevention: string[];
  preventionDaySlots: Map<string, TimeSlot>
  timesPreventionFollowUp: string[];
  preventionFollowUpDaySlots: Map<string, TimeSlot>
}
