
<ng-container *ngIf="subStudies.length == 0; then noStudies; else showTable"></ng-container>
<ng-template #noStudies>
  <p> Det finns inga substudier</p>
</ng-template>

<ng-template #showTable>
  <mat-table [dataSource]="subStudies" class="table table-borderless">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef id="title">Titel</th>
      <td mat-cell *matCellDef="let column" class="clickable-cell" (click)="redirectToDetailsPage(column)"> {{column.identifier}} - {{column.title}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef id="email">Epost</th>
      <td mat-cell *matCellDef="let column"> {{column.email}}</td>
    </ng-container>


    <ng-container matColumnDef="numberOfScapists">
      <th mat-header-cell *matHeaderCellDef id="numberOfScapists">Antal potentiella scapister</th>
      <td mat-cell *matCellDef="let column"> {{column.numberOfScapists}}</td>
    </ng-container>


    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef id="delete">Ta bort</th>
      <td mat-cell *matCellDef="let column">

        <mat-icon color="warn" class="delete-icon" (click)="deleteStudy(column, $event)">delete</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        id="study-{{row.id}}"></tr>
  </mat-table>
</ng-template>
