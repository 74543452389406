<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle *ngIf="timeSlot"><mat-icon [inline]="true" aria-hidden="true" fontSet="material-icons-outlined">calendar_today</mat-icon>{{timeSlot.time | date: "YYYY-MM-dd HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle *ngIf="!timeSlot"><mat-icon [inline]="true" aria-hidden="true" fontSet="material-icons-outlined">calendar_today</mat-icon>Ingen bokad tid</mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions>
    <confirm-modal-button
      *ngIf="timeSlot && timeSlot.status == 'BOOKED'"
      text="Är du säker på att du vill avboka {{name}} för {{scapist.firstName}} {{scapist.lastName}}?"
      [action]="cancel.bind(this, timeSlot)">
      <button class="cancel-booking" [disabled]="!canBook" dialogButton mat-button color="warn">AVBOKA</button>
    </confirm-modal-button>
    <button class="open-booking" mat-button *ngIf="!timeSlot" [disabled]="!canBook" (click)="openBooking()">
      BOKA TID
    </button>
    <button mat-button *ngIf="timeSlot && timeSlot.status == 'ATTENDED'" class="attended" [disabled]="true">
      ANKOMMIT
    </button>
  </mat-card-actions>
</mat-card>
