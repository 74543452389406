import {RedcapFlowStatusEnum} from '@shared/constants/redcap-flow-status.enum';
import {ScapistStatus} from '@shared/constants/scapist-status.enum';

export class WorkListScapistModel {
  id: number;
  firstName: string;
  lastName: string;
  personNumber: string;
  status: ScapistStatus;
  flowStatus: RedcapFlowStatusEnum;
  lastOpenedBy: string;
  lastOpenedTimestamp: Date;
  transitionsToRelevantTimestamp: Date;
  transitionsToDoneTimestamp: Date;
}



