<h2>
  Prevention
</h2>
<div class="row">


  <div class="col-md-6 offset-md-3" *ngIf="preventionShare | async as loadedPreventionShare">
    <h3>Preventionsbeslut</h3>

    <pie-chart

      [labels]="loadedPreventionShare.serieNames"
      [data]="loadedPreventionShare.data"
    >
    </pie-chart>
  </div>
  <div class="col-md-6" *ngIf="preventionVisitShare | async as loadedPreventionVisitShare">
    <h3>Preventionsbesök</h3>

    <pie-chart

      [labels]="loadedPreventionVisitShare.serieNames"
      [data]="loadedPreventionVisitShare.data"
    >
    </pie-chart>
  </div>
  <div class="col-md-6" *ngIf="preventionFollowupShare | async as loadedPreventionFollowupShare">
    <h3>Uppföljningssamtal</h3>
    <pie-chart

      [labels]="loadedPreventionFollowupShare.serieNames"
      [data]="loadedPreventionFollowupShare.data"
    >
    </pie-chart>
  </div>

  <div class="col-md-6" *ngIf="preventionVisitTime | async as loadedPreventionVisitTime">
    <h3>Tid för Preventionsbesök</h3>
    <pie-chart

      [labels]="loadedPreventionVisitTime.serieNames"
      [data]="loadedPreventionVisitTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid från granskning: <strong>{{loadedPreventionVisitTime.average | number: '1.0-2'}} dagar</strong></p>

  </div>
  <div class="col-md-6" *ngIf="preventionFollowupTime | async as loadedPreventionFollowupTime">
    <h3>Tid för Uppföljningssamtal</h3>
    <pie-chart

      [labels]="loadedPreventionFollowupTime.serieNames"
      [data]="loadedPreventionFollowupTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid från prevention: <strong>{{loadedPreventionFollowupTime.average | number: '1.0-2'}} dagar</strong></p>

  </div>
</div>
