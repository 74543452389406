import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TopMenuComponent} from './top-menu/top-menu.component';
import {TopMenuItemComponent} from './top-menu-item/top-menu-item.component';
import {UtilsModule} from '@shared/utils/utils.module';
import {AuthModule} from '../auth/auth.module';
import {RouterModule} from '@angular/router';
import {AdminResourcesComponent} from '@modules/navigation/admin-resources/admin-resources.component';

const components = [
  TopMenuComponent,
  TopMenuItemComponent,
  AdminResourcesComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    RouterModule,
    BrowserModule,
    UtilsModule,
    AuthModule,
  ],
  providers: []
})
export class NavigationModule { }
