import { Component, OnInit } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { UserNavComponent } from './user-nav/user-nav.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterModule } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatButtonModule, MatIconModule, UserNavComponent, MainNavComponent, RouterModule]
})
export class HeaderComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService) {
  }
  ngOnInit(): void {
  }
  isLoggedIn(): boolean {
    return this.authenticationService.isUserLoggedIn();
  }
}
