import {TimeSlot} from '@shared/models/time-slot.model';
import {ScapistStatus} from '@shared/constants/scapist-status.enum';
import {ScapistEvent} from '@shared/models/scapis-event.model';
import {Gender} from '@shared/constants/gender.enum';
import {SubStudy} from '@shared/models/sub-study.model';
import {ScapistAddress} from '@shared/models/scapist-address.model';
import {FreeBook} from '@shared/models/free-book.model';
import {Flow} from '@shared/models/flow.model';
import {Site} from "@shared/models/site.model";

export class Scapist {
  id: number;
  timeSlots: TimeSlot[];
  firstName: string;
  lastName: string;
  personNumber: string;
  address: ScapistAddress;
  status: ScapistStatus;
  phone: string;
  email: string;
  gender: Gender;
  flows: Flow[];
  lastLogin: Date;
  latestCallAttempt: Date;
  accepted: Date;
  callAttempts: number;
  createdAt: Date;
  events: ScapistEvent[];
  remindedViaSms: boolean;
  remindedViaEmail: boolean;
  subStudies: SubStudy[];
  randomizationNumber: number;
  freebooks: FreeBook[];
  site: Site;
}
