export enum TimeSlotTypeEnum {
  START = 'START',
  CONTINUED_START = 'CONTINUED_START',
  CT = 'CT',
  PREVENTION = 'PREVENTION',
  PREVENTION_FOLLOW_UP = 'PREVENTION_FOLLOW_UP',
  FREE_BOOK = 'FREE_BOOK'
}

export const TimeSlotTypeDisplay = {
  [TimeSlotTypeEnum.START]: 'Startbesök',
  [TimeSlotTypeEnum.CONTINUED_START]: 'Forts. startbesök',
  [TimeSlotTypeEnum.CT]: 'CT-besök',
  [TimeSlotTypeEnum.PREVENTION]: 'Preventionsbesök',
  [TimeSlotTypeEnum.PREVENTION_FOLLOW_UP]: 'Uppföljningssamtal',
  [TimeSlotTypeEnum.FREE_BOOK]: 'Extrabesök'
}
