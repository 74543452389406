import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TimeSlotTypeEnum } from '@shared/constants/time-slot-type.enum';
import { Scapist } from '@shared/models/scapist.model';
import { TimeSlot } from '@shared/models/time-slot.model';
import { TimeSlotService } from '@shared/services/entity-services/time-slot.service';
import { DateUtils } from '@shared/utils/date.utils';
import {TimeSlotUtils} from "@shared/utils/time-slot.utils";

@Component({
  selector: 'app-booking-visit',
  templateUrl: './booking-visit.component.html',
  styleUrls: ['./booking-visit.component.scss']
})
export class BookingVisitComponent implements OnInit {
  @Input() type: TimeSlotTypeEnum;
  @Input() scapist: Scapist;
  @Input() form: FormGroup;

  today = new Date();

  timeSlotService: TimeSlotService;
  timeSlot: TimeSlot;
  availableTimeSlots: TimeSlot[];
  dateValue: Date;
  timeValue: string;

  dateControl: FormControl;
  timeControl: FormControl;

  ids: {};

  constructor(timeSlotService: TimeSlotService) {
    this.dateFilter = this.dateFilter.bind(this);
    this.timeSlotService = timeSlotService;
  }

  ngOnInit(): void {
    this.timeSlot = this.scapist.timeSlots.find(slot => slot.timeSlotType === this.type && slot.status !== 'ABSENT');

    this.timeSlotService.bookableTimeSlots.subscribe(data => {
      this.availableTimeSlots = data
      .filter(timeslot => timeslot.timeSlotType === this.type && timeslot.status === 'EMPTY' && new Date(timeslot.time).getTime() > this.today.getTime())
      .map(timeslot => ({...timeslot, time: typeof timeslot.time === 'number' ? new Date(timeslot.time) : timeslot.time}))
      .sort((a, b) => a.time > b.time ? 1 : -1);
      if (this.availableTimeSlots.length > 0) {
        this.dateValue = this.availableTimeSlots.length > 0 ? this.availableTimeSlots[0].time : new Date();
        this.timeValue = this.availableTimes().length > 0 ? this.availableTimes()[0] : '';
        this.dateControl = new FormControl(this.dateValue);
        this.timeControl = new FormControl(this.timeValue);
        this.form.addControl(`${this.type}_date`, this.dateControl);
        this.form.addControl(`${this.type}_time`, this.timeControl);
        this.form.addControl(`${this.type}_select`, new FormControl(false));
        this.form.addControl(`${this.type}_id`, new FormControl(this.getSelectedId()));
        this.dateControl.valueChanges.subscribe(value => {
          this.dateValue = value;
          this.timeValue = this.availableTimes().length > 0 ? this.availableTimes()[0] : '';
          this.form.controls[`${this.type}_time`].setValue(this.timeValue);
        });
        this.timeControl.valueChanges.subscribe(value => {
          this.timeValue = value;
          this.form.controls[`${this.type}_id`].setValue(this.getSelectedId());
        });
      }
    });
  }

  isTimeSlotBookable() {
    return !this.timeSlot || this.timeSlot.status === 'ABSENT';
  }

  getTitle() {
    return TimeSlotUtils.getTitle(this.type);
  }

  dateFilter(date: Date): boolean {
    return this.availableTimeSlots.some(x => DateUtils.isSameDate(x.time, date));
  }

  availableTimes() {
    if (!this.dateValue || this.availableTimeSlots.length === 0) {
      return [];
    }
    const availableSlots = this.availableTimeSlots.filter(x => DateUtils.isSameDate(x.time, this.dateValue));
    const ids = {};
    const slots =  availableSlots.
        map(slot => {
          const time = slot.time.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' });
            ids[`${this.dateValue}-${time}`] = slot.id;
          return time;
    })
      .filter(this.onlyUnique);
    this.ids = ids;
    return slots;
  }

  getSelectedId() {
    return this.ids[`${this.dateValue}-${this.timeValue}`];
  }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

}
