import {UserRole} from '@shared/models/user-role.model';
import {Site} from '@shared/models/site.model';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  authorities: UserRole[];
  enabled: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  hasStudies: boolean;
  lastLoginAttempt: Date;

  site: Site;

  isLoggedIn(): boolean {
    return this.email != null;
  }


  private isUserOfType(requestedRole): boolean {
    if (this.authorities == null) {
      return false;
    }
    const role = this.authorities.find(
      r => r.authority === requestedRole
    );
    return role !== undefined;
  }

  hasRole(roleAsString: string) {
    return this.isUserOfType(roleAsString);
  }
}
