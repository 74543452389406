import {User} from '@shared/models/user.model';
import {Site} from '@shared/models/site.model';
import { InvitationStatus } from '@shared/invitation-status.enum';

export class Invitation {
  id: number;
  createdAt: Date;
  addressListAt: Date;
  remindersSentAt: Date;
  timedOut: boolean;
  site: Site;
  user: User;
  expiredAt: Date;
  total: number;
  interested: number;
  notParticipating: number;
  booked: number;
  finished: number;
  invited: number;
  status: InvitationStatus;
  included: number;
}
