<div>
  <form [formGroup]="form"(ngSubmit)="onSubmit(form.getRawValue())">
    <mat-form-field class="form-group">
      <mat-label for="phone">Telefonummer</mat-label>
      <input matInput type="text" class="form-control" formControlName="phone" id="phone">
    </mat-form-field>
    <mat-form-field>
      <mat-label for="email">E-post</mat-label>
      <input matInput type="text" class="form-control" formControlName="email" id="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label for="address">Adress</mat-label>
      <input matInput type="text" class="form-control" formControlName="address" id="address">
    </mat-form-field>
    <mat-form-field>
      <mat-label for="performer">Utförare</mat-label>
      <input matInput type="text" class="form-control" formControlName="performer" id="performer">
    </mat-form-field>
    <mat-form-field>
      <mat-label for="recruitmentTarget">Rekryteringsmål</mat-label>
      <input matInput type="number" class="form-control" formControlName="recruitmentTarget" id="recruitmentTarget">
    </mat-form-field>
      <mat-checkbox formControlName="useSecondStartVisit">Använd forts. startbesök</mat-checkbox>
      <button color="primary" mat-raised-button [disabled]="!form.valid" type="submit">Spara</button>
  </form>
</div>
