<nav>
  <button *ngFor="let page of pages; let i = index" mat-stroked-button (click)="selectPage(i)" class="{{className(i)}}">{{page}}</button>
</nav>
<div class="container">

  <h1>{{pages[openPage]}}</h1>
  <form [formGroup]="form">
    <div class="filter-border">
      <div class="row" *ngIf="openPage === 0">
        <mat-form-field class="date-range col-md-4 col-sm-6">
          <mat-date-range-input [rangePicker]="picker"(click)="picker.open()" class="date-input">
            <input matStartDate (click)="picker.open()" readonly formControlName="from" placeholder="Från" class="date-input">
            <input matEndDate (click)="picker.open()"readonly formControlName="to" placeholder="Till" class="date-input">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div class="col">
          <button color="primary" mat-raised-button (click)="setToday()">Endast dagens besök</button>
        </div>
      </div>
      <div class="row filters">
        <div class="col-md-2" *ngFor="let filter of dayEnumType | keyvalue: doNotSort">
          <mat-checkbox [checked]="filter.value.active"
                        (change)="checkFilter(filter.key,$event.checked)">{{filter.value.display}}</mat-checkbox>
        </div>
      </div>
      <button mat-raised-button (click)="clearFilter()">Rensa filter</button>
    </div>
  </form>

  <div *ngIf="openPage === 0">
    <app-day-visit-list *ngFor="let day of filteredDays" [day]="day"
                        (attend)="attend($event)"
                        (reset)="reset($event)"
                        (absent)="absent($event)"></app-day-visit-list>
  </div>
  <div *ngIf="openPage === 1">
    <app-day-visit-list *ngFor="let day of filteredPastDays" [day]="day"
                        (attend)="attend($event)"
                        (reset)="reset($event)"
                        (absent)="absent($event)"></app-day-visit-list>
  </div>


</div>
