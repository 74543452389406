import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService } from "./loader.service";
import { Observable } from "rxjs";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
   private requests: HttpRequest<any>[] = [];

   constructor(private loaderService: LoaderService) {}

   removeRequest(req: HttpRequest<any>) {
       const requestToRemove = this.requests.indexOf(req);
       if (requestToRemove > -1) {
          this.requests.splice(requestToRemove, 1);
       }
       this.loaderService.isLoading.next(this.requests.length > 0);
   }
  
   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    return new Observable(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          }
        );
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}