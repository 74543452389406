<h2>
  CT-Granskningar
</h2>
<div class="row">

  <div class="col-md-6">
    <line-chart
      *ngIf="ctReviews | async as loadedCtReviews"
      [labels]="loadedCtReviews.serieNames"
      [data]="loadedCtReviews.data"
    >
    </line-chart>
  </div>
  <div class="col-md-6">
    <bars-chart
      *ngIf="ctReviewsPerWeek | async as loadedCtReviewsPerWeek"
      [labels]="loadedCtReviewsPerWeek.serieNames"
      [data]="loadedCtReviewsPerWeek.data">
    </bars-chart>
  </div>
  <div class="col-md-6" *ngIf="ctReviewsTime | async as loadedCtReviewsTime">
    <h3>Kompletta granskningar</h3>
    <pie-chart

      [labels]="loadedCtReviewsTime.serieNames"
      [data]="loadedCtReviewsTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid för granskning: <strong>{{loadedCtReviewsTime.average | number: '1.0-2'}} dagar</strong></p>

  </div>
  <div class="col-md-6" *ngIf="ctHeartReviewsTime | async as loadedCtHeartReviewsTime">
    <h3>Hjärtgranskningar</h3>
    <pie-chart

      [labels]="loadedCtHeartReviewsTime.serieNames"
      [data]="loadedCtHeartReviewsTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid för granskning: <strong>{{loadedCtHeartReviewsTime.average | number: '1.0-2'}} dagar</strong></p>

  </div>
  <div class="col-md-6" *ngIf="ctLungReviewsTime | async as loadedCtLungReviewsTime">
    <h3>Lunggranskningar</h3>
    <pie-chart

      [labels]="loadedCtLungReviewsTime.serieNames"
      [data]="loadedCtLungReviewsTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid för granskning: <strong>{{loadedCtLungReviewsTime.average | number: '1.0-2'}} dagar</strong></p>

  </div>
  <div class="col-md-6" *ngIf="ctBodyReviewsTime | async as loadedCtBodyReviewsTime">
    <h3>Kroppsgranskningar</h3>
    <pie-chart

      [labels]="loadedCtBodyReviewsTime.serieNames"
      [data]="loadedCtBodyReviewsTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid för granskning: <strong>{{loadedCtBodyReviewsTime.average | number: '1.0-2'}} dagar</strong></p>

  </div>
</div>
