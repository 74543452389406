import {ViewFormatter} from './view-formatter';

export class DateUtils {

  /**
   * Returns string for the day of the week of the provided date
   * @param date: Date
   */
  public static getDayString(date: Date): string {
    date = new Date(date);
    switch (date.getDay()) {
      case 0:
        return 'Söndag';
      case 1:
        return 'Måndag';
      case 2:
        return 'Tisdag';
      case 3:
        return 'Onsdag';
      case 4:
        return 'Torsdag';
      case 5:
        return 'Fredag';
      case 6:
        return 'Lördag';
    }
  }


  /**
   * Returns string for the month of the provided date
   * @param date: Date
   */
  public static getMonthString(date: Date): string {
    date = new Date(date);
    switch (date.getMonth()) {
      case 0:
        return 'jan';
      case 1:
        return 'feb';
      case 2:
        return 'mar';
      case 3:
        return 'apr';
      case 4:
        return 'maj';
      case 5:
        return 'jun';
      case 6:
        return 'jul';
      case 7:
        return 'aug';
      case 8:
        return 'sep';
      case 9:
        return 'okt';
      case 10:
        return 'nov';
      case 11:
        return 'dec';
    }
  }

  /**
   * Checks if two date objects are the same date (ignoring time)
   * @param date: Date
   */
  public static isSameDate(first: Date, second: Date): boolean {

    if (!first || !second) {
      return false;
    }

    return first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
  }

  /**
   * Checks if two date is in same quarter
   * @param date: Date
   */
  public static isSameQuarter(first: Date, second: Date): boolean {

    if (!first || !second) {
      return false;
    }

    return first.getHours() === second.getHours() &&
    (first.getMinutes() >= second.getMinutes() && first.getMinutes() < second.getMinutes() + 15)
  }

  public static isValidDateRange(from: Date, to: Date): boolean {
    return from && to && from.getTime() && to.getTime() >= from.getTime();
  }


  /**
   * Returns number of days between to dates.
   * @param from: Date
   * @param to: Date
   */
  public static dateDifferenceInDays(from: Date, to: Date): number {
    return (to.getTime() - from.getTime()) / (1000 * 3600 * 24);
  }

}
