import { Component, OnInit } from '@angular/core';
import {share} from "rxjs/operators";
import {SubStudyService} from "@shared/services/entity-services/sub-study.service";
import { Observable } from 'rxjs';
import { SubStudy } from '@shared/models/sub-study.model';

@Component({
  selector: 'app-sub-studies-page',
  templateUrl: './sub-studies-page.component.html',
  styleUrls: ['./sub-studies-page.component.scss']
})
export class SubStudiesPageComponent implements OnInit {

  subStudies: Observable<SubStudy[]>;
  constructor(private subStudyService: SubStudyService) {
  }

  ngOnInit(): void {
    this.subStudies = this.subStudyService.subStudies.pipe(share());
  }

}
