import {Component, Input, OnInit} from '@angular/core';
import {TimeSlot} from '@shared/models/time-slot.model';
import {Scapist} from '@shared/models/scapist.model';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TimeBookingComponent} from '@modules/scapists/time-booking/time-booking.component';

@Component({
  selector: 'app-time-slot-card',
  templateUrl: './time-slot-card.component.html',
  styleUrls: ['./time-slot-card.component.scss']
})
export class TimeSlotCardComponent implements OnInit {

  @Input() timeSlot: TimeSlot;
  @Input() scapist: Scapist;
  @Input() title: string;
  @Input() name: string;
  @Input() canBook: boolean;

  constructor(private timeSlotService: TimeSlotService, private scapistService: ScapistService,
  public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  cancel(timeslot: TimeSlot) {
    this.timeSlotService.cancel(timeslot, this.scapist, this.getCancelCallback());
  }
  private getCancelCallback() {
    const self = this;
    return () => {
      self.scapistService.clearScapist();
      self.scapistService.getById(self.scapist.id);
    };
  }

  openBooking() {
    const conf = new MatDialogConfig();
    conf.data = this.scapist;
    conf.width = '800px';
    this.dialog.open(TimeBookingComponent, conf);
  }

}
