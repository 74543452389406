<ng-container *ngIf="scapist.status == 'REMOVED_BEFORE_INCLUSION' || scapist.status == 'REMOVED_AFTER_INCLUSION'; then scapistIsRemoved; else scapistNotRemoved"></ng-container>
<ng-template #scapistNotRemoved>
  <ng-container
    *ngIf="isScapistInterested || isScapistUnreachable; then showBookings; else notMarkedAsInterested"></ng-container>
  <ng-template #showBookings>
    <div class="row" >
      <h2>Besök</h2>
      <div class="col-md-6">
          <app-time-slot-card [scapist]="scapist" [canBook]="isBooker && !isDone" [timeSlot]="timeSlotStart" [title]="getTimeSlotTitle(TimeSlotTypeEnum.START)" [name]="'startbesök'"></app-time-slot-card>
      </div>
      <div class="col-md-6" *ngIf="showContinuedStart">
        <app-time-slot-card [scapist]="scapist" [canBook]="isBooker && !isDone" [timeSlot]="timeSlotContinuedStart" [title]="getTimeSlotTitle(TimeSlotTypeEnum.CONTINUED_START)" [name]="'fortsättning på startbesök'"></app-time-slot-card>
      </div>
      <div class="col-md-6">
        <app-time-slot-card [scapist]="scapist" [canBook]="isBooker && !isDone" [timeSlot]="timeSlotCT" [title]="getTimeSlotTitle(TimeSlotTypeEnum.CT)" [name]="'CT-besök'"></app-time-slot-card>
      </div>
      <div class="col-md-6" *ngIf="isScapistReadyForPrevention">
        <app-time-slot-card [scapist]="scapist" [canBook]="isPreventionNurse && !isDone" [timeSlot]="timeSlotPrevention" [title]="getTimeSlotTitle(TimeSlotTypeEnum.PREVENTION)" [name]="'preventionsbesök'"></app-time-slot-card>
      </div>
      <div class="col-md-6" *ngIf="isScapistReadyForPreventionFollowUp">
        <app-time-slot-card [scapist]="scapist" [canBook]="isPreventionNurse && !isDone" [timeSlot]="timeSlotPreventionFollowUp" [title]="getTimeSlotTitle(TimeSlotTypeEnum.PREVENTION_FOLLOW_UP)" [name]="'uppföljning av preventionsbesök'"></app-time-slot-card>
      </div>


    </div>
  </ng-template>
  <ng-template #notMarkedAsInterested>
    <p>Scapisten måste vara markerad som intresserad för att kunna bokas.</p>
  </ng-template>
</ng-template>
<div>
  <ng-template #scapistIsRemoved>
    <p>Scapisten är avbruten och därmed låst för bokningar. </p>
    <div *ngIf="scapist.status === 'REMOVED_AFTER_INCLUSION'">

      <div *ngIf="timeSlotStart || timeSlotContinuedStart || timeSlotCT || timeSlotPrevention">
        Följande besök är genomförda:
        <div *ngIf="timeSlotStart && timeSlotStart.status == 'ATTENDED'">
          <strong>{{getTimeSlotTitle(timeSlotStart.timeSlotType) }}:</strong> {{timeSlotStart.time | date: "YYYY-MM-dd HH:mm" }}
        </div>
        <div *ngIf="timeSlotContinuedStart && timeSlotContinuedStart.status == 'ATTENDED'">
          <strong>{{getTimeSlotTitle(timeSlotContinuedStart.timeSlotType) }}: </strong> {{timeSlotContinuedStart.time | date: "YYYY-MM-dd HH:mm" }}
        </div>
        <div *ngIf="timeSlotCT && timeSlotCT.status == 'ATTENDED'">
          <strong>{{getTimeSlotTitle(timeSlotCT.timeSlotType) }}: </strong> {{timeSlotCT.time | date: "YYYY-MM-dd HH:mm" }}
        </div>
        <div *ngIf="timeSlotPrevention && timeSlotPrevention.status == 'ATTENDED' && isScapistReadyForPrevention">
          <strong>{{getTimeSlotTitle(timeSlotPrevention.timeSlotType) }}:</strong> {{timeSlotPrevention.time | date: "YYYY-MM-dd HH:mm" }}
        </div>
        <div *ngIf="timeSlotPreventionFollowUp && timeSlotPreventionFollowUp.status == 'ATTENDED' && isScapistReadyForPreventionFollowUp">
          <strong>{{getTimeSlotTitle(timeSlotPreventionFollowUp.timeSlotType) }}:</strong> {{timeSlotPreventionFollowUp.time | date: "YYYY-MM-dd HH:mm" }}
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="isAnyTimeSlotAbsent">
    <h4>Missade besök:</h4>
    <div *ngFor="let absentTimeslot of absentTimeSlotDays">
      <strong>{{dayType(absentTimeslot.timeSlotType)}}
        : </strong>{{absentTimeslot.time  | date: "YYYY-MM-dd HH:mm"}}
    </div>
  </div>

</div>


