export enum RedcapFlowEnum {
  FLOW_1 = 'FLOW_1',
  FLOW_2 = 'FLOW_2',
  FLOW_3 = 'FLOW_3',
  FLOW_4 = 'FLOW_4',
  FLOW_5 = 'FLOW_5',
  FLOW_6 = 'FLOW_6',
  FLOW_7 = 'FLOW_7',
  FLOW_8 = 'FLOW_8',
  FLOW_9 = 'FLOW_9',
  FLOW_10 = 'FLOW_10',
  FLOW_11 = 'FLOW_11',
  FLOW_12 = 'FLOW_12',
  FLOW_13 = 'FLOW_13',
  FLOW_14 = 'FLOW_14',
  FLOW_15 = 'FLOW_15',
  FLOW_16 = 'FLOW_16',
  FLOW_17 = 'FLOW_17',
  FLOW_18 = 'FLOW_18',
  FLOW_19 = 'FLOW_19',
  FLOW_20 = 'FLOW_20',
  AUX_1 = 'AUX_1',
  STATISTICS_1 = 'STATISTICS_1',
  STATISTICS_2 = 'STATISTICS_2',
}

export const RedcapFlowDisplay = {
  [RedcapFlowEnum.FLOW_1]: 'Labb',
  [RedcapFlowEnum.FLOW_2]: 'CT remiss',
  [RedcapFlowEnum.FLOW_3]: 'CT undersökning',
  [RedcapFlowEnum.FLOW_4]: 'CT hjärta granskning',
  [RedcapFlowEnum.FLOW_5]: 'CT lunga granskning',
  [RedcapFlowEnum.FLOW_6]: 'CT kropp granskning',
  [RedcapFlowEnum.FLOW_7]: 'Preventionsbesök',
  [RedcapFlowEnum.FLOW_8]: 'Uppföljningssamtal',
  [RedcapFlowEnum.FLOW_9]: 'Mottagningen – Core eCRF inkomplett',
  [RedcapFlowEnum.FLOW_10]: 'Studieläkarbedömning',
  [RedcapFlowEnum.FLOW_11]: 'Lungfunktion',
  [RedcapFlowEnum.FLOW_12]: 'Queries på någon av “dina” variabler',
  [RedcapFlowEnum.FLOW_13]: 'Remiss VC efter uppföljningssamtal',
  [RedcapFlowEnum.FLOW_14]: 'Rond hjärta',
  [RedcapFlowEnum.FLOW_15]: 'Rond lunga',
  [RedcapFlowEnum.FLOW_16]: 'Uppföljning eGFR',
  [RedcapFlowEnum.FLOW_17]: 'Avslutningsbrev standard',
  [RedcapFlowEnum.FLOW_18]: 'Avslutningsbrev anpassad',
  [RedcapFlowEnum.FLOW_19]: 'Rond annan',
  [RedcapFlowEnum.FLOW_20]: 'Accelerometer ej återlämnad',
  [RedcapFlowEnum.AUX_1]: 'Ringlista preventionsbesök',
  [RedcapFlowEnum.STATISTICS_1]: 'Systemtriggers för Statistik',
  [RedcapFlowEnum.STATISTICS_2]: 'Klar utan prevention',
}
