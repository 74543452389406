import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {FormUtils} from '@shared/utils/form.utils';
import { Site } from '@shared/models/site.model';
import { AuthenticationService } from '@shared/services/authentication.service';
import {TimeSlotUtils} from "@shared/utils/time-slot.utils";
import {TimeSlotTypeEnum} from "@shared/constants/time-slot-type.enum";

@Component({
    selector: 'app-time-slot-form',
    templateUrl: './time-slot-form.component.html',
    styleUrls: ['./time-slot-form.component.scss']
})
export class TimeSlotFormComponent implements OnInit {

    form: FormGroup;
    site: Site;


    constructor(
                public dialogRef: MatDialogRef<TimeSlotFormComponent>,
                @Inject(MAT_DIALOG_DATA) public date: Date,
                private service: TimeSlotService,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService
                ) {
    }

    ngOnInit(): void {
        this.site = this.authenticationService.getUser().site || new Site();
        const conf = {
            number: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100), Validators.pattern('^\\d+$')]),
            hour: new FormControl(null, Validators.required),
            minute: new FormControl(null, Validators.required),
            day: new FormControl(null, Validators.required)
        }
        this.form = this.formBuilder.group(conf);
    }

    onSubmit(rawValue: any) {
        if (!this.form.valid) {
            return;
        }
        const time = new Date(this.date);
        time.setHours(rawValue.hour);
        time.setMinutes(rawValue.minute);
        this.service.create({
            time, number: rawValue.number, day: rawValue.day
        });
        this.dialogRef.close();
    }

    get number() {
        return this.form.get('number');
    }

    isDisplayError(formProperty: string) {
        return FormUtils.isDisplayError(this.form, formProperty);
    }

  protected readonly TimeSlotUtils = TimeSlotUtils;
  protected readonly TimeSlotTypeEnum = TimeSlotTypeEnum;
}
