import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticsComponent } from './statistics.component';
import { BrowserModule } from '@angular/platform-browser';
import { BaseChartDirective } from 'ng2-charts';
import { PieComponent } from './charts/pie/pie.component';
import { LineComponent } from './charts/line/line.component';
import { MatButtonModule } from '@angular/material/button';
import { BarsComponent } from './charts/bars/bars.component';
import { InviteStatsPageComponent } from './invite-stats-page/invite-stats-page.component';
import { InclusionStatsPageComponent } from './inclusion-stats-page/inclusion-stats-page.component';
import { ProgressBarComponent } from './charts/progress-bar/progress-bar.component';
import { CtExamsComponent } from './ct-exams/ct-exams.component';
import { CtReviewsComponent } from './ct-reviews/ct-reviews.component';
import { StudyPaceComponent } from './study-pace/study-pace.component';
import { PreventionComponent } from './prevention/prevention.component';
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [StatisticsComponent, PieComponent, LineComponent, BarsComponent, InviteStatsPageComponent, InclusionStatsPageComponent, ProgressBarComponent, CtExamsComponent, CtReviewsComponent, StudyPaceComponent, PreventionComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BaseChartDirective,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ]
})
export class StatisticsModule { }
