import { Component, OnInit } from '@angular/core';
import { Invitation } from '@shared/models/invitation.model';
import {InvitationService} from "@shared/services/entity-services/invitation.service";
import { Observable } from 'rxjs';
import {share} from "rxjs/operators";

@Component({
  selector: 'app-invite-scapists',
  templateUrl: './invite-scapists.component.html',
  styleUrls: ['./invite-scapists.component.scss']
})
export class InviteScapistsComponent implements OnInit {

  invitations: Observable<Invitation[]>
  constructor(private invitationsService: InvitationService) { }

  ngOnInit(): void {
    this.invitations = this.invitationsService.invitations.pipe(share());
  }

}
