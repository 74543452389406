<h2>
  CT-Undersökningar
</h2>
<div class="row">

  <div class="col-md-6">
    <line-chart
      *ngIf="ctExams | async as loadedCtExams"
      [labels]="loadedCtExams.serieNames"
      [data]="loadedCtExams.data"
    >
    </line-chart>
  </div>
  <div class="col-md-6">
    <bars-chart
      *ngIf="ctExamsPerWeek | async as loadedCtExamsPerWeek"
      [labels]="loadedCtExamsPerWeek.serieNames"
      [data]="loadedCtExamsPerWeek.data">
    </bars-chart>
  </div>
  <div class="col-md-6 offset-md-3" *ngIf="ctExamsTime | async as loadedCtExamsTime">
    <pie-chart

      [labels]="loadedCtExamsTime.serieNames"
      [data]="loadedCtExamsTime.data"
    >
    </pie-chart>
    <p class="summary">Genomsnittlig tid från inklusion: <strong>{{loadedCtExamsTime.average | number: '1.0-2'}} dagar</strong></p>
  </div>
</div>
