
const releases = [
  {
    version: '1.6.18',
    date: '2024-05-07',
    features: [
      {title: 'Uppdaterat kriteriet för att hamna på arbetslistan Accelerometer ej återlämnad (9 dygn)'},
      {title: 'Funktion för adresser till avslutnings brev'}
    ]
  },
  {
    version: '1.6.14',
    date: '2024-04-23',
    features: [
      {title: 'Uppdaterad information på statistiksidorna'}
    ]
  },
  {
    version: '1.6.4HF',
    date: '2024-04-16',
    features: [
      {title: 'Mindre tekniska fixar'}
    ]
  },
  {
    version: '1.6.4',
    date: '2024-04-09',
    features: [
      {title: 'Bättre sortering av arbetslistor'}
    ]
  },
  {
    version: '1.5.3',
    date: '2024-03-26',
    features: [
      {title: 'Lösning av sommartidsrelaterade problem'}
    ]
  },
  {
    version: '1.5.0',
    date: '2024-03-19',
    features: [
      {title: 'Arbetslistor kommer ihåg sortering'},
      {title: 'Nationell statiskanvändare kan filtrera på site'},
      {title: 'Nationell kommunikatör'},
      {title: 'Statistik: progressbar till studietakt'}
    ]
  },
  {
    version: '1.4.0',
    date: '2024-03-12',
    features: [
      {title: 'Avbryt scapist'},
      {title: 'Mer statistik', bullets: [
        'Översikt för besökstyper',
        'Visa granskningskö'
      ]},
      {title: 'Nationell site'},
      {title: 'Arbetslistor', bullets: [
        'Accelerometer inte tillbaka lämnad',
        'Mottagningen – Core eCRF inkomplett'
      ]}
    ]
  },
  {
    version: '1.3.0',
    date: '2024-02-27',
    features: [
      {title: 'Snabbare arbetslistor'},
      {title: 'Ta bort alla tider på en dag'},
      {title: 'Utökade statistiksidor'}
    ]
  },
  {
    version: '1.2.1',
    date: '2024-02-12',
    features: [
      {title: 'Buggfix för arbetslistor'}
    ]
  },
  {
    version: '1.2.0',
    date: '2024-02-05',
    features: [
      {
        title: 'Fler arbetslistor',
        bullets: [
          'CT remiss',
          'Rond hjärta',
          'Rond lunga',
          'Rond annan',
          'Uppföljning eGRF'
        ]},
      {title: 'Granskningsflöde för CT'},
      {title: 'Nationel inklusionsstatistik'},
      {title: 'Avslutningsbrev'},
      {title: 'Trafikljus i arbetslistor'},
      {title: 'Mindre buggfixar'}
    ]
  },
  {
    version: '1.1.2',
    date: '2024-01-15',
    features: [
      {title: 'Fler arbetslistor'},
      {title: 'Inklusionsstatistik'},
      {title: 'Påminnelser inför besök: sms och email'},
      {title: 'Preventionsflöde'},
      {title: 'Mindre buggfixar'},
      {title: 'Lista med omarkerade passerade besök'}
    ]
  },
  {
    version: '1.1.1',
    date: '2023-12-01',
    features: [
      {title: 'Bättre översikt över en scapists bokade tider'},
      {title: 'Uppdaterade scapist-texter'},
      {title: 'Smidigare hantering kring ankomstregistrering'},
      {title: 'Mindre buggfixar'}
    ]
  },
  {
    version: '1.1.0',
    date: '2023-11-22',
    features: [
      {title: 'Bättre sida för att hantera enskild Scapist'},
      {title: 'Trigger som startar preventionsflödet'},
      {title: 'Ankomstregistrering'},
      {title: 'ECRF-koppling'},
      {title: 'Arbetslistor'},
      {title: 'Mindre buggfixar'}
    ]
  },
  {
    version: '1.0.1',
    date: '2023-11-14',
    features: [
      {title: 'Enklare hantering av tidbok'},
      {title: 'Versionsinformation'},
      {title: 'Tydligare inbjudningsförfarande'},
      {title: 'Mindre buggfixar'}
    ]
  },
  {
    version: '1.0.0',
    date: '2023-10-30',
    features: [
      {title: 'Skapa bokningsbara tider'},
      {title: 'Hämta adresser för inbjudningar och påminnelser'},
      {title: 'Ringlista'},
      {title: 'Behörighetshantering'},
      {title: 'Bokning'},
      {title: 'Site inställningar'}
    ]
  }
];

const redcapReleases = [
  {
    version: 'CHARLIE',
    date: '2024-03-05',
    features: [
      {
        title: 'Följande nya eCRF sidor:',
        bullets: [
          'Uppföljningsamtal',
          'Remiss efter uppföljningssamtal',
          'Substudiesidor för de site som ännu ej startat är tillagda/uppdaterade',
        ]},
      {
        title: 'Ändringar på sidor som är i bruk:',
        bullets: [
          'CT Undersökning',
          'CT Lunga',
          'CT Remiss',
          'Lungfunktion',
          'Övrig studieläkaruppföljning'
        ]}
    ]
  },
  {
    version: 'BRAVO',
    date: '2024-02-05',
    features: [
      {
        title: 'Följande nya eCRF sidor:',
        bullets: [
          'CT Lunga',
          'CT Hjärta',
          'CT Kropp',
          'Beslutstöd prevention',
          'Övrig studieläkaruppföljning',
          'Avslutningsbrev',
          'Preventionsbesök'
        ]
      },
      {
        title: 'Malmö lokala substudier'
      },
      {
        title: 'Stockholm lokala substudier'
      },
      {
        title: 'Ändringar i ALPHA2 sidorna. Kontakta din site ansvarige för detaljer'
      }
    ]
  },
  {
    version: 'ALPHA 2',
    date: '2024-01-16',
    features: [
      {
        title: 'Följande nya eCRF sidor:',
        bullets: [
          'Samtycke, Demografi, Medicinsk bakgrund',
          'Samtycke Lokala Substudier',
          'Blodtryck och EKG',
          'Antropometri',
          'Provtagning',
          'Uppföljande glukosprov',
          'Lungfunktion',
          'Accelerometer',
          'Livstilsenkät',
          'Kostenkät',
          'Enkätstatus',
          'Hemgång inför CT',
          'Labvärden',
          'CT remiss',
          'CT Undersökning',
          'eGFR uppföljning',
          'Incidentlogg'

        ]
      },
      {
        title: 'Göteborg lokala substudier'
      }
    ]
  }
]

export class VersionUtils {

  public static getReleaseNotes() {
    return releases;
  }

  public static getVersion(): string {
    return releases[0].version;
  }

  static getRedcapReleaseNotes() {
    return redcapReleases;

  }
}
