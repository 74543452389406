import {Component, Input, OnInit} from '@angular/core';
import {SubStudy} from "@shared/models/sub-study.model";
import {SubStudyService} from "@shared/services/entity-services/sub-study.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sub-study-form',
  templateUrl: './sub-study-form.component.html',
  styleUrls: ['./sub-study-form.component.scss']
})
export class SubStudyFormComponent implements OnInit {

  @Input() study: SubStudy;
  studyForm: FormGroup;

  constructor(private subStudyService: SubStudyService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    let fileOpts;
    if(!this.study) {
      this.study = new SubStudy();
      fileOpts = Validators.required;
    }
    const config = {
      email: new FormControl(this.study.email, [Validators.required, Validators.email]),
      title: new FormControl(this.study.title, [Validators.required, Validators.maxLength(30)]),
      identifier: new FormControl(this.study.identifier, [Validators.required, Validators.maxLength(10)]),
      file: new FormControl(null, fileOpts),
      filename: new FormControl(null, fileOpts),
      display: new FormControl()
    };

    this.studyForm = this.formBuilder.group(config);
  }

  isDisplayError(formProperty: string) {
    const value = this.studyForm.get(formProperty);
    return value.invalid && (value.dirty || value.touched);
  }

  onSubmit(value: SubStudy) {
    if(this.studyForm.valid) {
      if(this.study.id) {
        this.subStudyService.update(this.study.id, value);
      } else {
        this.subStudyService.create(value);
        this.router.navigateByUrl('/sub-studies').then().catch(() => {
        });
      }
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.studyForm.get('file').setValue(file);
      this.studyForm.get('filename').setValue(file.name);
    }
  }
}
