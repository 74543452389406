<div class="container">
  <a [routerLink]="['/users']">< Användarlista</a>
  <div *ngIf="user | async as loadedUser">
    <div class="row user-table">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Användarinformation
          </div>
          <div class="card-body">
            <app-user-role-form [user]='loadedUser'></app-user-role-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
