import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '@shared/services/user.service';
import {share} from 'rxjs/operators';
import {UserRole} from '@shared/models/user-role.model';

import {AuthenticationService} from '@shared/services/authentication.service';
import {ViewFormatter} from '@shared/utils/view-formatter';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {FreeBookFormComponent} from "@modules/scapists/free-book-form/free-book-form.component";
import {CreateUserModalComponent} from "@modules/users/create-user-modal/create-user-modal.component";
import {Site} from "@shared/models/site.model";
import { Observable } from 'rxjs';
import { User } from '@shared/models/user.model';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users: Observable<User[]>;
  currentUser: User;
  displayedColumns: string [] = [];

  constructor(
    private userService: UserService, private router: Router, public dialog: MatDialog, private authenticatorService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.users = this.userService.users.pipe(share());
    this.currentUser = this.authenticatorService.getUser();
    this.displayedColumns = ['name', 'email', 'roles', 'site'];
    this.userService.loadData();
  }

  formatRoles(authorities: UserRole[], site: Site) {
    return ViewFormatter.formatRoles(authorities, site);
  }

  redirectToDetailsPage(userId: number) {
    this.router.navigateByUrl('/users/' + userId).then().catch(() => {
    });
  }

  openModal() {
    const conf = new MatDialogConfig();
    conf.width = '800px';
    this.dialog.open(CreateUserModalComponent, conf);
  }
}
