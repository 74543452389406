import {Component} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {User} from '@shared/models/user.model';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserForm} from '@shared/models/user-form.model';
import {FormUtils} from '@shared/utils/form.utils';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {

  user: User;
  editUserForm: FormGroup;

  constructor(private authenticationService: AuthenticationService, private router: Router, private formBuilder: FormBuilder) {
    this.user = this.authenticationService.getUser();
    this.editUserForm = this.formBuilder.group({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      email: new FormControl(this.user.email),
    });
  }

  logOut() {
    this.authenticationService.logOut();
  }

  onSubmit(userFormData: UserForm): void {
    this.authenticationService.updateUserDetails(userFormData);
  }

  isDisplayError(formProperty: string) {
    return FormUtils.isDisplayError(this.editUserForm, formProperty);
  }

  get firstName() {
    return this.editUserForm.get('firstName');
  }

  get lastName() {
    return this.editUserForm.get('lastName');
  }

  get email() {
    return this.editUserForm.get('email');
  }

}
