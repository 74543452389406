import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {DayFilterEnum} from '@shared/constants/day-filter.enum';
import {TimeSlot} from '@shared/models/time-slot.model';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {TimeSlotFilter} from '@shared/constants/time-slot-filter.enum';

@Component({
  selector: 'app-handle-timeslots',
  templateUrl: './handle-timeslots.component.html',
  styleUrls: ['./handle-timeslots.component.scss']
})
export class HandleTimeslotsComponent implements OnInit {
  form: FormGroup;
  dayFilterEnum = DayFilterEnum;
  timeslotFilter = TimeSlotFilter;
  numberOfAvailableSlots: number;

  constructor(public dialogRef: MatDialogRef<HandleTimeslotsComponent>,
    @Inject(MAT_DIALOG_DATA) public slots: TimeSlot[],
    private service: TimeSlotService, private formBuilder: FormBuilder) {
      this.form = this.formBuilder.group({
        slots: new FormControl(null, Validators.required),
      });
}

  ngOnInit(): void {
    this.numberOfAvailableSlots = this.slots.filter(slot  => slot.status === BookingStatusEnum.EMPTY).length
  }

  onSubmit() {
    if (this.form.valid) {
      this.service.deleteMultiple(this.form.get('slots').value);
      this.dialogRef.close();
    }
  }

  isDisabled(slot: TimeSlot) {
    return slot.status !== BookingStatusEnum.EMPTY;
  }

  status(slot: TimeSlot) {
    if (slot.scapist === null) {
      return null;
    }
    return `${slot.scapist.firstName} ${slot.scapist.lastName}`;
  }

}
