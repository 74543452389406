import {FormGroup} from '@angular/forms';

export class FormUtils {

  /**
   * Returns true if the property has been touched and not valid.
   * @param form the form that has the property
   * @param formProperty the property to check if valid
   */
  public static isDisplayError(form: FormGroup, formProperty: string): boolean {
    const value = form.get(formProperty);
    return value && value.invalid && (value.dirty || value.touched);
  }
}
