<mat-card appearance="outlined">
  <ng-container *ngIf="scapist.subStudies && scapist.subStudies.length > 0; then showTable; else noStudies"></ng-container>
  <ng-template #noStudies>
    <h4>Lokala substudier</h4>
    <p> Det finns inga lokala substudier med speciella urval</p>
  </ng-template>

  <ng-template #showTable>
    <h4>Scapistens potentiella substudier</h4>
    <mat-table [dataSource]="scapist.subStudies" class="table table-borderless">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef id="title">Titel</th>
        <td mat-cell *matCellDef="let column"> {{column.identifier}} - {{column.title}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['title']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['title'];"
          ngClass="clickable-row"
          id="study-{{row.id}}"></tr>
    </mat-table>
  </ng-template>
</mat-card>
