import {NgModule} from '@angular/core';
import {SafePipe} from './safe.pipe';
import {HighlightPipe} from './highlight.pipe';
import {GroupTimeSlotsPipe} from '@shared/utils/group-time-slots.pipe';
import {GroupEmptyTimeSlotsPipe} from '@shared/utils/group-empty-time-slots.pipe';
import {PersonNumberPipe} from '@shared/utils/person-number.pipe';
import {VisitStatusFormatterPipe} from '@shared/utils/visit-status-formatter.pipe';
import {DisplayNamePipe} from '@shared/utils/display-name.pipe';
import {VisitTypeFormatterPipe} from '@shared/utils/visit-type-formatter.pipe';
import {DayTypeSlotsPipe} from './day-type-slots.pipe';


@NgModule({
  declarations: [SafePipe, VisitStatusFormatterPipe, VisitTypeFormatterPipe, HighlightPipe, DisplayNamePipe, GroupTimeSlotsPipe, DayTypeSlotsPipe, GroupEmptyTimeSlotsPipe, PersonNumberPipe],
  exports: [SafePipe, VisitStatusFormatterPipe, VisitTypeFormatterPipe, HighlightPipe, GroupTimeSlotsPipe, DayTypeSlotsPipe, GroupEmptyTimeSlotsPipe, PersonNumberPipe, DisplayNamePipe]

})
export class UtilsModule {
}
