import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '@environments/environment';
import {Features} from '../models/features.model';


@Injectable({providedIn: 'root'})
export class FeaturesService {
  private baseUrl: string;

  private featuresURL = '/features/';
  private features: Features;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.features = new Features();
  }

  public isEnabled(feature: string) {
    return this.features[feature];
  }

  public loadData() {
    this.http.get<Features>(this.baseUrl + this.featuresURL ).subscribe(data => {
      this.features = data;
    }, () => {
      this.features = new Features();
    });
  }
}
