import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Scapist} from '@shared/models/scapist.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {NotificationService} from '@shared/services/notification.service';
import {PageModel} from '@shared/models/data-models/page.model';

@Injectable({
  providedIn: 'root'
})
export class ScapistSearchService {

  private readonly baseUrl: string;
  private siteScapistURL = '/site-scapist';
  private dataStore: {
    scapists: Scapist[],
    loading: boolean,
    pageIndex: number,
    searchString: string,
    pageSize: number,
    total: number,
    sort?: string,
    direction?: string
  } = {
    scapists: [],
    loading: false,
    searchString: '',
    pageIndex: 0,
    pageSize: 10,
    total: undefined
  };

  private _loading = new BehaviorSubject<boolean>(false);
  private _scapists = new BehaviorSubject<Scapist[]>([]);
  private _page = new BehaviorSubject<number>(0);
  public readonly loading = this._loading.asObservable();
  public readonly scapists = this._scapists.asObservable();
  public readonly page = this._page.asObservable();

  private timer: any;
  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  // Delay search request 0.3 and wait for further input. Resets delay at every new input.
  public updateSearchString(searchString: string) {
    this.dataStore.searchString = searchString;
    this.unsetTimer();
    this.timer = setTimeout(this.getSearchMethod(), 300);
  }

  public setSort(sort: string, direction: string) {
    this.dataStore.sort = sort;
    this.dataStore.direction = direction;
  }

  resetSearch() {
    this.dataStore.searchString = '';
    this.unsetTimer();
    this.dispatchScapists([]);
  }

  private unsetTimer(){
    clearTimeout(this.timer);
    this.timer = undefined;
  }

  getSearchMethod() {
    const self = this;
    return () => {
      self.getScapists();
    }
  }


  public getScapists() {
    this.dispatchLoading(true);
    this.http.get<PageModel>(this.scapistUrl(null) + 'find-scapists' + this.getParams()).subscribe(data => {
      const scapists = [];
      for (const contentItem of data.content) {
        scapists.push(Object.assign(new Scapist(), contentItem));
      }
      this.dataStore.total = data.totalNumberOfElements;
      this.dataStore.pageSize = data.pageSize;
      this.dataStore.pageIndex = data.page;
      this.dispatchScapists(scapists);
      this.dispatchLoading(false);
    }, () => {
      this.dispatchLoading(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in scapister');
    });
  }

  public getPaginationInfo() {
    return {
      pageIndex: this.dataStore.pageIndex,
      pageSize: this.dataStore.pageSize,
      total: this.dataStore.total
    };
  }


  loadPage(pageIndex: number, pageSize: number) {
    this.dataStore.pageIndex = pageIndex;
    this.dataStore.pageSize = pageSize;
    this.getScapists();
  }

  private dispatchLoading(loading: boolean) {
    this.dataStore.loading = loading;
    this._loading.next(this.dataStore.loading);
  }

  private dispatchScapists(scapists: Scapist[]) {
    this.dataStore.scapists = scapists;
    this._scapists.next(Object.assign({}, this.dataStore).scapists);
  }


  private getParams(): string {

    const sortParams = this.dataStore.sort && this.dataStore.direction ? `sort=${this.dataStore.sort}&direction=${this.dataStore.direction}` : '';
    const params = [`page=${this.dataStore.pageIndex || 0}`,`pageSize=${this.dataStore.pageSize || 10}`,`searchString=${this.dataStore.searchString}&${sortParams}`];

    return `?${params.join('&')}`;
  }

  private scapistUrl(id: number): string {
    return `${this.baseUrl}${this.siteScapistURL}/${id || ''}`;
  }

  getSearchTerm():string {
    return this.dataStore.searchString;
  }


}
