import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {User} from '@shared/models/user.model';
import {Router} from '@angular/router';
import {share} from 'rxjs/operators';
import {FeaturesService} from "@shared/services/features.service";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})



export class AppComponent implements OnInit {

  sessionExpired: Observable<boolean>;

  constructor(private authenticationService: AuthenticationService, private router: Router, private featuresService: FeaturesService) {
  }

  ngOnInit() {
    this.sessionExpired = this.authenticationService.sessionExpired.pipe(share());
    this.featuresService.loadData();
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isUserLoggedIn();
  }

  getActiveRoute() {
    return this.router.url;
  }

  getUser(): User {
    return this.authenticationService.getUser();
  }
}
