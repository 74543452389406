import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScapistSearchService} from '@shared/services/scapist-services/scapist-search.service';
import {share} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Scapist } from '@shared/models/scapist.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeBookingComponent } from '../time-booking/time-booking.component';
import { ScapistStatus } from '@shared/constants/scapist-status.enum';
import { ScapistStatusUtils } from '@shared/utils/scapist-status.utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-find-scapist',
  templateUrl: './find-scapist.component.html',
  styleUrls: ['./find-scapist.component.scss'],
  animations: [
    trigger('expandDetails', [
      state('collapsed', style({ height: 0, minHeight: 0 })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class FindScapistComponent implements OnInit, OnDestroy {

  scapists: Observable<Scapist[]>;
  loading: Observable<boolean>;
  expandedElement: Scapist;
  showMinLengthHint = false;


  displayedColumns: string[] = [];

  pageSizeOptions = [10, 25, 50];
  form: FormGroup;

  constructor(private service: ScapistSearchService, private formBuilder: FormBuilder, private router: Router,public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.scapists = this.service.scapists.pipe(share());
    this.loading = this.service.loading.pipe(share());
    const conf = {
      searchTerm: new FormControl(this.service.getSearchTerm())
    }
    this.form = this.formBuilder.group(conf);

    this.displayedColumns = ['name', 'email', 'personNumber', 'status'];

    this.form.valueChanges.subscribe(() => {
      const term = this.form.get('searchTerm').value;
      if(!term || term.length < 3) {
        this.showMinLengthHint = true;
      } else {
        this.showMinLengthHint = false;
        this.service.updateSearchString(term);
      }
    })
  }

  ngOnDestroy(): void {
    this.form.get('searchTerm').setValue('');
    this.service.resetSearch();
  }

  getTitle(loading: boolean) {
    return loading ? 'Laddar' : 'Resultat';
  }

  get pageInfo() {
    console.log(this.service)
    return this.service.getPaginationInfo();
  }

  handlePageEvent(event: PageEvent) {
    this.service.loadPage(event.pageIndex, event.pageSize)
  }

  redirectToBookingPage(id: number) {
    this.router.navigateByUrl('/scapist/' + id).then();
  }

}
