export function PhoneNumberFormatter(value: string): string {
  if (!value || value.trim() === '') {
    return value;
  }

  // Remove special characters
  const cleanedNumber = value.replace(/\D/g, '');

  // Remove leading 46 or 0046 with 0
  const normalizedNumber = cleanedNumber.replace(/^(0046|46)/, '0');

  const areaCode = normalizedNumber.substring(0, 3);
  const mainNumber = normalizedNumber.substring(3);
  // Concatenate so that the format is: XXX-XXX XX XX
  return `${areaCode}-${mainNumber.slice(0, 3)} ${mainNumber.slice(3, 5)} ${mainNumber.slice(5)}`;

}