import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {VisitListComponent} from './visit-list/visit-list.component';
import {FindScapistComponent} from './find-scapist/find-scapist.component';
import {ManageScapistComponent} from './manage-scapist/manage-scapist.component';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import {ScapistBookingComponent} from './scapist-booking/scapist-booking.component';
import {UtilsModule} from '@shared/utils/utils.module';
import {BookableTimeslotListComponent} from '@modules/scapists/bookable-timeslot-list/bookable-timeslot-list.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ScapistFormComponent} from '@modules/scapists/scapist-form/scapist-form.component';
import {SubstudiesListComponent} from './substudies-list/substudies-list.component';
import {DayVisitListComponent} from './day-visit-list/day-visit-list.component';
import {TestScapistComponent} from '@modules/scapists/test-scapists/test-scapist.component';
import {MatIconModule} from '@angular/material/icon';
import {FreeBooksComponent} from './free-books/free-books.component';
import {FreeBookFormComponent} from './free-book-form/free-book-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {DisplayNamePipe} from '@shared/utils/display-name.pipe';
import {ScapistPageComponent} from './scapist-page/scapist-page.component';
import {CallListModule} from '@modules/scapists/call-list/call-list.module';
import {TimeBookingComponent} from './time-booking/time-booking.component';
import {BookingVisitComponent} from './booking-visit/booking-visit.component';
import {MatDialogModule} from '@angular/material/dialog';
import {PortalCommonModule} from '@modules/common/portal-common.module';
import { MatCardModule } from '@angular/material/card';
import { TimeSlotCardComponent } from './time-slot-card/time-slot-card.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AbortScapistComponent } from './abort-scapist/abort-scapist.component';
import { LetterAddressComponent } from './letter-address/letter-address.component';

const declarations = [
  VisitListComponent,
  FindScapistComponent,
  ManageScapistComponent,
  ScapistBookingComponent,
  BookableTimeslotListComponent,
  ScapistFormComponent,
  TestScapistComponent,
  SubstudiesListComponent,
  DayVisitListComponent,
  FreeBooksComponent,
  FreeBookFormComponent,
  ScapistPageComponent,
  TimeBookingComponent,
  BookingVisitComponent,
  TimeSlotCardComponent,
  AbortScapistComponent,
  LetterAddressComponent
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatSortModule,
    MatPaginatorModule,
    UtilsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    CallListModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    PortalCommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  providers: [DisplayNamePipe, DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' }],
  exports: declarations,

})
export class ScapistsModule {
}
