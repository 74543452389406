import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeSlotsPageComponent} from './time-slots-page/time-slots-page.component';
import {TimeSlotIndexComponent} from './time-slot-index/time-slot-index.component';
import {TimeSlotFormComponent} from './time-slot-form/time-slot-form.component';
import {UtilsModule} from '@shared/utils/utils.module';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {RepeatingTimeSlotFormComponent} from './repeating-time-slot-form/repeating-time-slot-form.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {HandleTimeslotsComponent} from './handle-timeslots/handle-timeslots.component';
import {MatCardModule} from '@angular/material/card';
import {PortalCommonModule} from "@modules/common/portal-common.module";
import {MatMenuModule} from "@angular/material/menu";


@NgModule({
  declarations: [
    TimeSlotsPageComponent,
    TimeSlotIndexComponent,
    TimeSlotFormComponent,
    RepeatingTimeSlotFormComponent,
    HandleTimeslotsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    UtilsModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatExpansionModule,
    MatTooltipModule,
    MatListModule,
    UtilsModule,
    MatCardModule,
    PortalCommonModule,
    MatMenuModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MatDialog,
      useValue: {}
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'sv-SE'
    }
  ]
})
export class TimeSlotsModule {
}
