<div mat-dialog-title class="dialog-title">
  <h3>Lägg till Användare</h3>
  <button mat-icon-button aria-label="Stäng bokning" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-form-field>
      <mat-label for="email">Epost:</mat-label>
      <input matInput class="form-control" type="email" id="email" formControlName="email">
      <mat-error *ngIf="isDisplayError('email') && email.errors.required">
        Får inte vara tomt
      </mat-error>
      <mat-error *ngIf="isDisplayError('email') && email.errors.email">
        Måste vara en giltig epost
      </mat-error>
    </mat-form-field>

    <br/>
    <button mat-raised-button color="primary" [disabled]="!form.valid" type="submit">Skicka</button>
  </form>
</mat-dialog-content>
