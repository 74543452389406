export const TimeSlotFilter = {
  EMPTY: {active: true, display: 'Ledig'},
  BOOKED: {active: true, display: 'Bokad'},
  ABSENT: {active: true, display: 'Utebliven', disabled: true},
  ATTENDED: {active: true, display: 'Avklarad', disabled: true},
}
export const TimeSlotFilterEnum = {
  EMPTY: 'EMPTY',
  BOOKED: 'BOOKED',
  ABSENT: 'ABSENT',
  ATTENDED: 'ATTENDED',
};