import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {AuthenticationService} from '@shared/services/authentication.service';
import {Site} from '@shared/models/site.model';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private baseUrl: string;

  private siteURL = '/site/';
  constructor(private http: HttpClient, private authService: AuthenticationService, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  update(value: any) {
    this.http.post<Site>(this.baseUrl + this.siteURL, value).subscribe(data => {
      this.authService.updateSite(data);
      this.notificationService.sendSuccessNotification('Siteinställningar uppdaterade');
    })
  }
}
