import {Component, OnInit} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {Scapist} from '@shared/models/scapist.model';
import {PageEvent} from '@angular/material/paginator';
import {share} from 'rxjs/operators';
import {ListFunctionsService} from '@shared/services/list-functions.service';
import {PreventionScapistsService} from '@shared/services/scapist-services/prevention-scapists.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-prevention-call-list',
  templateUrl: './prevention-call-list.component.html',
  styleUrls: ['./prevention-call-list.component.scss']
})
export class PreventionCallListComponent implements OnInit {


  scapists: Observable<Scapist[]>;
  interestedScapistList: Scapist[];

  displayedColumns: string [] = [];
  pageSizeOptions = [10, 25, 50];


  constructor(private callListService: PreventionScapistsService, public listFunctionService: ListFunctionsService) {
  }

  ngOnInit(): void {
    this.scapists = this.callListService.scapists.pipe(share());
    this.displayedColumns = ['name', 'personNumber', 'phone', 'accepted', 'attempts', 'latestAttempt', 'callAttempt'];
    this.callListService.getScapists();
    this.scapists.subscribe(list => {
      this.interestedScapistList = list;
      this.pageSizeOptions = [...this.pageSizeOptions, this.pageInfo.total];
    })
    this.sortData({active: 'accepted', direction: 'asc'})
  }

  get pageInfo() {
    return this.callListService.getPaginationInfo();
  }

  sortData(sort: Sort) {
    this.interestedScapistList = this.listFunctionService.sortData(sort, this.interestedScapistList)
  }

  sendCallAttemptEvent(scapist: Scapist, direction: number) {
    // You cannot regret a call attempt scapist when no attempts been made.
    if (direction === -1 && scapist.callAttempts === 0) {
      return;
    }
    scapist.callAttempts += direction;
    if(direction !== -1) {
      scapist.latestCallAttempt = new Date();
    }
    this.callListService.sendCallAttempt(scapist);
  }

  handlePageEvent(event: PageEvent) {
    this.callListService.loadPage(event.pageIndex, event.pageSize)
  }


}
