<div class="container">
  <div class="content-title">
    <h1>
      Logga in
    </h1>
  <div class="content">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
      <mat-form-field>
        <mat-label for="email">Epost:</mat-label> <input matInput autofocus type="email" id="email" formControlName="email">
          <mat-error *ngIf="isDisplayError('email') && email.errors.required">Får inte vara tomt</mat-error>
          <mat-error *ngIf="isDisplayError('email') && email.errors.email">Måste vara en giltig epost</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="password">Lösenord:</mat-label> <input matInput type="password" id="password" formControlName="password">
        <mat-error *ngIf="isDisplayError('password') && password.errors.required">Får inte vara tomt</mat-error>
      </mat-form-field>
      <button mat-raised-button [color]="(isLoading | async) ? 'accent' : 'primary'" [disabled]="!loginForm.valid || (isLoading | async)" type="submit">
        <ng-container *ngIf="!(isLoading | async)">Logga in</ng-container>
        <mat-spinner *ngIf="isLoading | async" diameter="30"></mat-spinner>
      </button>
      <p>
        <a routerLink="/requestNewPassword">Glömt lösenord?</a>
      </p>
    </form>
  </div>
</div>
