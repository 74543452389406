<h1>Hantera {{scapist.firstName}} {{scapist.lastName}} ({{scapist.personNumber | personNumber }})
  <button id="go-to-ecrf-button" *ngIf="isScapistBooked" mat-flat-button color="accent" type="submit" (click)="this.redirectToRedcap()">
  <mat-icon class="centered-icon">assignment</mat-icon>
  eCRF
  <mat-icon class="centered-icon">open_in_new</mat-icon>
</button></h1>
<div class="row">
  <div class="col-md-6">
    <app-scapist-form [scapist]="scapist"></app-scapist-form>
    <app-letter-address [scapist]="scapist"></app-letter-address>
    <app-abort-scapist [scapist]="scapist"></app-abort-scapist>
  </div>
  <div class="col-md-6">
    <app-scapist-booking [scapist]="scapist" *ngIf="canViewBookings"></app-scapist-booking>
    <app-free-books [scapist]="scapist"></app-free-books>
    <app-substudies-list [scapist]="scapist"></app-substudies-list>
  </div>
</div>
