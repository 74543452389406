import { Component, OnInit } from '@angular/core';
import {share} from 'rxjs/operators';
import {StatisticsService} from '@shared/services/statistics.service';
import { Observable } from 'rxjs';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';

@Component({
  selector: 'app-invite-stats-page',
  templateUrl: './invite-stats-page.component.html',
  styleUrls: ['./invite-stats-page.component.scss']
})
export class InviteStatsPageComponent implements OnInit {


  invations: Observable<StatisticsModel>;
  numberOfInvited = 0;

  constructor(private statisticsService: StatisticsService) { }

  ngOnInit(): void {
    this.invations = this.statisticsService.invitations.pipe(share());
    this.statisticsService.invitations.subscribe(value => {
      let sum = 0;
      if(value && value.data && value.data[0]) {
        value.data[0].values.forEach(num => {
          sum += num;
        });
        this.numberOfInvited = sum;
      }
    })
  }

}
