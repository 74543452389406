<div *ngIf="canViewCallList || canViewPreventionCallList" class="container">
  <div class="title-button-container">
    <div class="title">
      <h1>Ringlista över scapister</h1>
    </div>
    <button mat-button class="mat-raised-button button-container" (click)="updateAllCallLists()">
      <mat-icon class="centered-icon">refresh</mat-icon>
      Uppdatera ringlistor
    </button>
  </div>
  <app-interested-call-list *ngIf="canViewCallList"></app-interested-call-list>
  <app-unreachable-call-list *ngIf="canViewCallList"></app-unreachable-call-list>
  <app-no-show-call-list *ngIf="canViewCallList"></app-no-show-call-list>
  <app-prevention-call-list *ngIf="canViewPreventionCallList"></app-prevention-call-list>
  <app-no-show-prevention-call-list *ngIf="canViewPreventionCallList"></app-no-show-prevention-call-list>
</div>
