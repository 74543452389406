<div>
	<h3>Schema</h3>
	<form [formGroup]="form">
		<div class="filter-border">
			<h5>Välj tidsperiod</h5>

			<div class="row">
				<div class="col-md-4">
					<mat-form-field class="date-range">
						<mat-date-range-input [rangePicker]="picker" (click)="picker.open()" class="date-input">
							<input matStartDate (click)="picker.open()" readonly formControlName="from"
							       placeholder="Från" class="date-input">
							<input matEndDate (click)="picker.open()" readonly formControlName="to" placeholder="Till"
							       class="date-input">
						</mat-date-range-input>
						<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
				<div class="col-md-4">
				</div>
			</div>
			<div class="row">
				<h5>Filtrera tider efter status</h5>
				<ng-container *ngFor="let filter of filterList  | keyvalue: doNotSort">
					<mat-checkbox class="col-md-1" *ngIf="!filter.value.disabled" [checked]="filter.value"
					              (change)="checkFilter(filter.key,$event.checked)">{{filter.value.display}}</mat-checkbox>
				</ng-container>
			</div>
			<div>
				<h5>Filtrera tider efter besök</h5>
				<div class="inline" *ngFor="let filter of typeFilter | keyvalue: doNotSort">
					<mat-checkbox [checked]="filter.value"
					              (change)="checkFilter(filter.key,$event.checked)">{{filter.value.display}}</mat-checkbox>
				</div>
			</div>
		</div>
	</form>
	<button class="repeating" mat-raised-button color="primary" (click)="openRepeatingTimeSlotModal()">
		<mat-icon>event_repeat</mat-icon>
		Planera längre period
	</button>
	<table>
		<tbody>

		<tr class="week" *ngFor="let week of dates">
			<div class="week-indicator">
				Vecka {{ week[0] | date: "w" }}
			</div>
			<div [ngStyle]="{ top: cursorPosition()}" class="time-cursor"></div>
			<td class="time">
				<div class="time-column">
					<div class="date"></div>
					<div class="hour" *ngFor="let hour of hours">
						{{ hour }}
					</div>
				</div>
			</td>
			<td class="day" *ngFor="let day of week">
				<div class="date">
					{{day | date: "YYYY-MM-dd"}}<small>{{ day | date: "EEEE" }}</small>
          <button mat-mini-fab class="add-button" [matMenuTriggerFor]="menu" color="primary" matTooltip="Alternativ">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button id="add-new-time-slot"mat-menu-item (click)="openNewTimeSlotModal(day)"
                    matTooltip="Lägg till tider">
              <mat-icon>add</mat-icon><span class="action-label">Lägg till tider</span>
            </button>
            <button mat-menu-item (click)="removeAll(day)"
                    matTooltip="Lägg till tider">
              <mat-icon>delete</mat-icon><span class="action-label">Ta bort alla obokade</span>
            </button>


          </mat-menu>





				</div>
				<div class="hour" *ngFor="let hour of hours">
					<div class="quarter" *ngFor="let quarter of minutes" [ngStyle]="{top: getTopPosition(quarter)}">
						<div *ngIf="$timeSlots | async as loadedTimeSlots">

							<ng-container
									*ngFor="let slot of (day | dayTypeSlots:hour:quarter:filteredTimeSlotsMap:timeSlots) as slotList; let slotIndex = index">
								<div [matTooltip]="getTooltip(slot)" class="time-slot time-slot--{{slot.type}}"
								     (click)="openTimeslotHandler(slot, day)">
									{{ slotIndex === 0 ? slot.time : '' }} {{ getDayString(slot.type) }}
									: {{ slot.totalNumberOfTimeSlots }}st
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</td>
		</tr>
		</tbody>
	</table>
</div>
