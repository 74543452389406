export enum InvitationStatus {
    INVITE = 'INVITE',
    ADDRESSES_DOWNLOADED = 'ADDRESSES_DOWNLOADED',
    TIME_TO_REMIND = 'TIME_TO_REMIND',
    REMINDED = 'REMINDED',
    REMINDER_ADDRESSES_DOWNLOADED = 'REMINDER_ADDRESSES_DOWNLOADED',
    TIMED_OUT = 'TIMED_OUT'
}

export const InvitationStatusDisplay = {
    [InvitationStatus.INVITE]: 'Inbjuden',
    [InvitationStatus.ADDRESSES_DOWNLOADED]: 'Adresser hämtade',
    [InvitationStatus.TIME_TO_REMIND]: 'Dags för påminnelse',
    [InvitationStatus.REMINDED]: 'Påmind',
    [InvitationStatus.REMINDER_ADDRESSES_DOWNLOADED]: 'Adresser för påminnelse hämtade',
    [InvitationStatus.TIMED_OUT]: 'Utgången',
  }
  