import {Component, Input, OnInit} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import { WorkListService } from '@shared/services/work-list.service';
import { ScapistStatusUtils } from '@shared/utils/scapist-status.utils';
import { ScapistStatus } from '@shared/constants/scapist-status.enum';

@Component({
  selector: 'app-scapist-page',
  templateUrl: './scapist-page.component.html',
  styleUrls: ['./scapist-page.component.scss']
})
export class ScapistPageComponent implements OnInit {

  @Input() scapist: Scapist;
  canViewBookings: boolean;
  isScapistBooked: boolean;

  constructor(private authenticationService: AuthenticationService, private workListService: WorkListService) {}

  ngOnInit(): void {
    this.canViewBookings = this.authenticationService.userHasAnyRole([UserTypeEnum.ROLE_BOKARE,
      UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL, UserTypeEnum.ROLE_RONTGENSJUKSKOTERSKA, UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA,
      UserTypeEnum.ROLE_SPIRO]);

    this.isScapistBooked = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.BOOKED);

  }

  redirectToRedcap() {
    this.workListService.redirectToRedcap(this.scapist);
  }

}
