import {Component, Input, OnInit} from '@angular/core';
import {Scapist} from "@shared/models/scapist.model";

@Component({
  selector: 'app-substudies-list',
  templateUrl: './substudies-list.component.html',
  styleUrls: ['./substudies-list.component.scss']
})
export class SubstudiesListComponent implements OnInit {
  @Input() scapist: Scapist;
  constructor() { }

  ngOnInit(): void {
  }

}
