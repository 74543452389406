import {AbstractControl} from '@angular/forms';

// Custom validator function for Swedish mobile phone numbers
export function ValidatePhoneNumber(control: AbstractControl): { [key: string]: any } | null  {
    const value = (control.value || '').toString();

    // Remove spaces and hyphens from the number
    const number = value.replace(/\s+|-/g, '');

    if (number === '') {
        return null; // Allow saving empty
    }

    // Define the regular expression pattern
    const pattern = /^(0046|\+46|0)7[0-9]{8}$/;

    // Check if the number matches the pattern
    if (!pattern.test(number)) {
      return { 'phoneNumber': true };
    }

    return null; // Validation successful
}