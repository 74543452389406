<div class="container" *ngIf="isEnabled()">
  <h3 class="work-list-title">Mina queries att granska</h3>
  <div *ngIf="issues | async as loadedIssues">
    <ng-container *ngIf="loadedIssues.length === 0; then noPersonsInWorkList; else showTable"></ng-container>
    <ng-template #noPersonsInWorkList>
      <p>Inga scapister i denna arbetslista</p>
    </ng-template>

    <ng-template #showTable>
      <app-issue-list [issues]="loadedIssues"></app-issue-list>
    </ng-template>
  </div>
</div>
