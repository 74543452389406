<div class="booking-visit" [formGroup]="form" *ngIf="isTimeSlotBookable() && availableTimeSlots.length > 0 && dateControl">
    <h5>{{getTitle()}}</h5>
    <p>Nästa lediga tid:</p>
    <div class="booking">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
          <input [min]="today" matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilter" formControlName="{{type}}_date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
          <mat-select formControlName="{{type}}_time">
            <mat-option *ngFor="let time of availableTimes()" [value]="time">
              {{time}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox formControlName="{{type}}_select">Välj</mat-checkbox>
      <input type="hidden" formControlName="{{type}}_id"/>
    </div>
</div>
<div class="booking-visit" *ngIf="!isTimeSlotBookable() || availableTimeSlots.length == 0">
  <h5>{{getTitle()}}</h5>
  <div *ngIf="!isTimeSlotBookable()">
    <p><mat-icon class="icon-check" aria-hidden="true" fontSet="material-icons-outlined">check_circle</mat-icon><strong>Bokat:</strong> {{timeSlot.time | date: "YYYY-MM-dd HH:mm" }}</p>
  </div>
  <div *ngIf="availableTimeSlots.length == 0 && isTimeSlotBookable()">
    <p>Inga lediga tider</p>
  </div>
</div>
