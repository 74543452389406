import {Component, OnInit} from '@angular/core';
import {UserService} from '@shared/services/user.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {AuthenticationService} from '@shared/services/authentication.service';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
  mailList: string [] = [];
  mailtoLink = 'mailto:'
  isKommunikatorNationellt = false;

  constructor(private userService: UserService, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.isKommunikatorNationellt = this.authenticationService.userHasRole(UserTypeEnum.ROLE_KOMMUNIKATOR_NATIONELLT);
    if(this.isKommunikatorNationellt) {
      this.userService.getAllUsersEmail().subscribe(data => {
        this.mailList = data;
      });
    }
  }

  sendEmail() {
    this.redirectTo(this.mailtoLink.concat(this.mailList.join(',')));
  }

  redirectTo(url: string): void {
    window.location.href = url;
  }
}
