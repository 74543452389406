import {Pipe, PipeTransform} from '@angular/core';
import {TimeSlot} from '@shared/models/time-slot.model';
import {TimeSlotUtils} from '@shared/utils/time-slot.utils';

@Pipe({
  name: 'personNumber'
})
export class PersonNumberPipe implements PipeTransform {

  public static doTransform(personNumber: string): string {
    if(!personNumber) {
      return personNumber;
    }
    if(personNumber.length === 10) {
      return `${personNumber.substring(0,6)}-${personNumber.substring(6,10)}`;
    }
    if(personNumber.length === 12) {
      return `${personNumber.substring(0,8)}-${personNumber.substring(8,12)}`;
    }
    return personNumber;
  }

  transform(personNumber: string): string {
    return PersonNumberPipe.doTransform(personNumber);
  }



}
