import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Notification} from '@shared/models/notification';
import {NavigationStart, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notification = new BehaviorSubject<Notification>(null);
  public readonly notification = this._notification.asObservable();

  constructor(private router: Router) {
    // Close notification when leaving a view.
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.closeNotification();
      }
    });
  }

  updateNotification(type: string, message: any, actionLabel?: string, action?: any) {
    const text = this.getMessageText(message);
    this._notification.next(new Notification(type, text, actionLabel, action));
  }

  closeNotification() {
    this.updateNotification('', '');
  }

  sendSuccessNotification(msg: string) {
    this.closeNotification();
    this.updateNotification('success', msg);
  }

  sendErrorNotification(err: any) {
    this.closeNotification();
    let msg = err.error;
    if(!msg && err) {
      msg = err;
    }
    if (err.status === '0') {
      msg = 'Could not connect to server';
    } else if (err.status === '401') {
      msg = err.error.error;
    }
    this.updateNotification('error', msg);
  }

  private getMessageText(message: any) {
    if (typeof message === 'string' || !message) {
      return message;
    }
    return this.getMessageText(message.message);
  }
}
