import {Injectable} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {Scapist} from '@shared/models/scapist.model';
import {EventType} from '@shared/constants/event-type.enum';
import {ScapistEvent} from '@shared/models/scapis-event.model';
import {Router} from '@angular/router';
import {FreeBookStatusEnum} from '@shared/constants/free-book-status.enum';
import {WorkListScapistModel} from '@shared/models/work-list-scapist.model';
import {Issue} from "@shared/models/issue.model";

@Injectable({
  providedIn: 'root'
})
export class ListFunctionsService {

  constructor(private router: Router) {
  }


  sortData(sort: Sort, scapists: Scapist[]): Scapist [] {
    const data = scapists.slice();
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.firstName, b.firstName, isDesc);
        case 'personNumber':
          return this.compare(a.personNumber, b.personNumber, isDesc);
        case 'phone':
          return this.compare(a.phone, b.phone, isDesc);
        case 'status':
          return this.compare(a.status, b.status, isDesc);
        case 'gender':
          return this.compare(a.gender, b.gender, isDesc);
        case 'accepted':
          return this.compare(
            Number(this.getAcceptedEvent(a.events).createdAt),
            Number(this.getAcceptedEvent(b.events).createdAt),
            isDesc);
        case 'latestAttempt':
          return this.compare(
            Number(a.latestCallAttempt),
            Number(b.latestCallAttempt),
            isDesc);
        case 'attempts':
          return this.compare(
            a.callAttempts,
            b.callAttempts,
            isDesc);
        default:
          return 0;
      }
    });
  }


  sortIssuesList(sort: Sort, issues: Issue[], dateMap: Map<number, number>) {
    const data = issues.slice();
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.scapist.firstName, b.scapist.firstName, isDesc);
        case 'personNumber':
          return this.compare(a.scapist.personNumber, b.scapist.personNumber, isDesc);
        case 'date':
          return this.compare(Number(a.created), Number(b.created), isDesc);
        case 'days':
          return this.compare(dateMap.get(a.id), dateMap.get(b.id), isDesc);
        case 'instrument':
          return this.compare(a.instrument, b.instrument, isDesc);
        default:
          return 0;
      }
    });

  }

  sortDataWorkList(sort: Sort, scapists: WorkListScapistModel[], dateMap: Map<number, number>): WorkListScapistModel [] {
    const data = scapists.slice();
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      let sortValue = 0;
      switch (sort.active) {
        case 'name':
          sortValue = this.compare(a.firstName, b.firstName, isDesc);
          break;
        case 'personNumber':
          sortValue = this.compare(a.personNumber, b.personNumber, isDesc);
          break;
        case 'date':
          sortValue = this.compare(Number(a.transitionsToRelevantTimestamp), Number(b.transitionsToRelevantTimestamp), isDesc);
          break;
        case 'days':
          sortValue = this.compare(dateMap.get(a.id), dateMap.get(b.id), isDesc);
          break;
        case 'status':
          sortValue = this.compare(a.flowStatus, b.flowStatus, isDesc);
          break;
        case 'latestReviewBy':
          sortValue = this.compare(a.lastOpenedBy, b.lastOpenedBy, isDesc);
          break;
        case 'latestReview':
          sortValue = this.compare(
              Number(a.lastOpenedTimestamp),
              Number(b.lastOpenedTimestamp),
              isDesc);
          break;
        default:
          sortValue = 0;
      }
      if(sortValue === 0) {
        sortValue = this.compare(a.personNumber, b.personNumber, isDesc);
      }
      return sortValue;
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {
      return 0;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getAcceptedEvent(events: ScapistEvent[]) {
    return events.find(event => event.eventType === EventType.ACCEPT);
  }

  getAllByScapistStatusAndFreebook(scapists: Scapist[], statues: string[], freebook: FreeBookStatusEnum): Scapist[] {
    return scapists.filter(scap => statues.includes(scap.status) || scap.freebooks?.some(book => book.status === freebook));
  }

  redirectToBookingPage(id: number) {
    this.router.navigateByUrl('/scapist/' + id).then();
  }

}
