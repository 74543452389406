import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import {EnvironmentIndicatorComponent} from '@modules/common/environment-indicator/environment-indicator.component';
import {NotificationComponent} from '@modules/common/notification/notification.component';
import {PageNotFoundComponent} from '@modules/common/page-not-found/page-not-found.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ReactiveFormsModule} from '@angular/forms';
import {ConfirmModalButtonComponent, ConfirmModalComponent} from './confirm-modal/confirm-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {DialogDirective} from './confirm-modal/dialog.directive';
import {MatButtonModule} from '@angular/material/button';
import {VersionPageComponent} from './version-page/version-page.component';
import {ExpandableTableComponent} from './expandable-table/expandable-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  exports: [
    EnvironmentIndicatorComponent,
    NotificationComponent,
    ConfirmModalComponent,
    ConfirmModalButtonComponent,
    DialogDirective,
    ExpandableTableComponent
  ],
  providers: [
    AsyncPipe
  ],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule
  ],
  declarations: [
    EnvironmentIndicatorComponent,
    NotificationComponent,
    PageNotFoundComponent,
    ConfirmModalComponent,
    ConfirmModalButtonComponent,
    DialogDirective,
    VersionPageComponent,
    ExpandableTableComponent
  ],
})
export class PortalCommonModule {
}
