<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h1>Användare</h1>
        </div>
    </div>
    <div class="container">
        <div class="users" *ngIf="users | async as loadedUsers">

            <ng-container *ngIf="loadedUsers.length == 0; then noUsers; else showTable"></ng-container>
            <ng-template #noUsers>
                <p> Finns inga personer som matchar din sökning</p>
            </ng-template>

            <ng-template #showTable>
                <mat-table [dataSource]="loadedUsers">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef id="name">Namn</th>
                        <td mat-cell *matCellDef="let column"> {{column.firstName}}  {{column.lastName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef id="email">E-post</th>
                        <td mat-cell *matCellDef="let column">{{column.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="roles">
                        <th mat-header-cell *matHeaderCellDef id="roles">Roles</th>
                        <td mat-cell *matCellDef="let column">{{formatRoles(column.authorities, column.site)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="site">
                        <th mat-header-cell *matHeaderCellDef id="site">Site</th>
                        <td mat-cell *matCellDef="let column">
                            <div *ngIf="column.site">
                                {{column.site.name}}
                            </div>
                            <div *ngIf="!column.site">
                                Personen tillhör ingen site
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        id="{{row.email}}"
                        (click)="redirectToDetailsPage(row.id)"
                    ></tr>

                </mat-table>

            </ng-template>

        </div>
      <button mat-raised-button color="primary" (click)="openModal()">Lägg till användare</button>

    </div>
</div>
