
<mat-table [dataSource]="sortedIssues" matSort matSortActive="{{sort.active}}" matSortDirection="{{sort.direction}}" (matSortChange)="sortData($event)">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="name" sortActionDescription="Sortera efter name" id="name">Namn</th>
    <td mat-cell *matCellDef="let column" class="clickable-cell" (click)="this.redirectToBookingPage(column.id)"
    >
      {{column.scapist.firstName}}  {{column.scapist.lastName}} {{column.scapist.status == 'REMOVED_AFTER_INCLUSION' ? '(AVBRUTEN)' : ''}}
      <mat-icon [inline]="true" class="inline-icon">open_in_new</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="personNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="personNumber" sortActionDescription="Sortera efter personNumber" id="personNumber">Personnummer</th>
    <td mat-cell *matCellDef="let column" class="td-container clickable-cell" (click)="this.copyToClipboard(column.scapist.personNumber)">{{column.scapist.personNumber | personNumber}}
      <mat-icon [inline]="true" class="inline-icon">content_copy</mat-icon>
    </td>
  </ng-container>7


  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="date" sortActionDescription="Sortera efter datum" id="date">Datum</th>
    <td mat-cell *matCellDef="let column" class="td-container">
      {{column.created | date: 'YYYY-MM-dd'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="days">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="days" sortActionDescription="Sortera efter days" id="days">Dagar</th>
    <td mat-cell *matCellDef="let column">{{dateMap.get(column.id)}}</td>
  </ng-container>


  <ng-container matColumnDef="instrument">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="instrument" sortActionDescription="Sortera efter Regeltyp" id="instrument">Regeltyp</th>
    <td mat-cell *matCellDef="let column" class="td-container">
      {{column.instrument ? 'Instrument: ' + column.instrument : 'Avancerad regel'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="ecrf">
    <th mat-header-cell *matHeaderCellDef id="ecrf">Öppna i eCRF</th>
    <td mat-cell *matCellDef="let column" class="button-cell">
      <button mat-flat-button color="accent" type="submit" (click)="this.redirectToRedcap(column)">
        <mat-icon class="centered-icon">assignment</mat-icon>
        eCRF
        <mat-icon class="centered-icon">open_in_new</mat-icon>
      </button>
    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
</mat-table>

