import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class ValidatePassword {
  static readonly UPPERCASE_REGEX = /^(?=.*[A-Z])/;
  static readonly LOWERCASE_REGEX = /^(?=.*[a-z])/;
  static readonly DIGIT_REGEX = /^(?=.*[0-9])/;
  static readonly SPECIAL_CHAR_REGEX = /^(?=.*[!@#$%^&*()\-_=+{}[\]|\\:;'"<>./?])/;
  constructor() {
  }

  static patternValidator(pattern: RegExp, errorObj: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        // If the control value is empty, return no error.
        return null;
      }

      // Test the value of the control against the supplied pattern.
      const valid = pattern.test(control.value);

      // If true, return no error, otherwise return the error object passed as the second parameter.
      return valid ? null : errorObj;
    };
  }
}