<div class="wrapper">
    <div mat-dialog-title class="dialog-title">
        <h3>Boka tid</h3>
        <button mat-icon-button aria-label="Stäng bokning" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    <mat-spinner *ngIf="isLoading | async" [diameter]="50"></mat-spinner>
    <form *ngIf="!(loaderService.isLoading | async)" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div mat-dialog-content>
            <div class="scapist-summary">
                <div class="row">
                    <div class="col-md-4">
                        <strong>{{scapist.firstName}} {{scapist.lastName}}</strong>
                        {{scapist.personNumber}}
                    </div>
                    <div class="col-md-4">
                        <span>Telefon:</span>
                        <a href="tel:{{scapist.phone}}">{{scapist.phone}}</a>
                    </div>
                    <div class="col-md-4">
                        <span>E-postadress:</span>
                        <a href="mailto:{{scapist.email}}">{{scapist.email}}</a>
                    </div>
                </div>
            </div>
            <div *ngIf="availableTimeSlots">
                <h4>Välj tider för besök</h4>
                <app-booking-visit [form]="form" [scapist]="scapist" id="booking-visit-{{timeSlotTypeEnum.START}}" [type]="timeSlotTypeEnum.START"></app-booking-visit>
                <app-booking-visit *ngIf="site.hasContinuedStartVisit" id="booking-visit-{{timeSlotTypeEnum.CONTINUED_START}}" [form]="form" [scapist]="scapist" [type]="timeSlotTypeEnum.CONTINUED_START"></app-booking-visit>
                <app-booking-visit [form]="form" [scapist]="scapist" id="booking-visit-{{timeSlotTypeEnum.CT}}" [type]="timeSlotTypeEnum.CT"></app-booking-visit>
                <app-booking-visit *ngIf="displayPrevention" [form]="form" id="booking-visit-{{timeSlotTypeEnum.PREVENTION}}" [scapist]="scapist" [type]="timeSlotTypeEnum.PREVENTION"></app-booking-visit>
                <app-booking-visit *ngIf="displayFollowup" [form]="form" [scapist]="scapist" id="booking-visit-{{timeSlotTypeEnum.PREVENTION_FOLLOW_UP}}" [type]="timeSlotTypeEnum.PREVENTION_FOLLOW_UP"></app-booking-visit>
            </div>
        </div>
        <mat-dialog-actions class="action-bar">
            <button mat-flat-button color="primary" class="save-button">Spara</button>
            <button mat-stroked-button [mat-dialog-close]="true">Avbryt</button>
        </mat-dialog-actions>
    </form>
</div>
