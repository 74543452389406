import {Component, Input, OnInit} from '@angular/core';
import {SubStudy} from "@shared/models/sub-study.model";
import {SubStudyService} from "@shared/services/entity-services/sub-study.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sub-studies-list',
  templateUrl: './sub-studies-list.component.html',
  styleUrls: ['./sub-studies-list.component.scss']
})
export class SubStudiesListComponent implements OnInit {

  @Input() subStudies: SubStudy[];

  displayedColumns: string[] = [];


  constructor(private subStudyService: SubStudyService, private router: Router) {}

  ngOnInit(): void {
    this.displayedColumns = ['title', 'email', 'numberOfScapists', 'delete'];

  }

  deleteStudy(study: SubStudy, $event: MouseEvent) {
    $event.preventDefault();
    this.subStudyService.delete(study);
  }

  redirectToDetailsPage(row: any) {
    this.router.navigateByUrl('/sub-studies/' + row.id).then().catch(() => {
    });
  }
}
