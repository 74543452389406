export enum ScapistStatus {
  NEW = 'NEW',
  INVITED = 'INVITED',
  REMINDED = 'REMINDED',
  PASSIVELY_UNINTERESTED = 'PASSIVELY_UNINTERESTED',
  INTERESTED = 'INTERESTED',
  UNREACHABLE = 'UNREACHABLE',
  PARTLY_BOOKED = 'PARTLY_BOOKED',
  BOOKED = 'BOOKED',
  NO_SHOW_START = 'NO_SHOW_START',
  NO_SHOW_CONTINUED_START = 'NO_SHOW_CONTINUED_START',
  DONE_START = 'DONE_START',
  DONE_CONTINUED_START = 'DONE_CONTINUED_START',
  DONE_CT = 'DONE_CT',
  NO_SHOW_CT = 'NO_SHOW_CT',
  NO_SHOW_PREVENTION = 'NO_SHOW_PREVENTION',
  NO_SHOW_PREVENTION_FOLLOW_UP = 'NO_SHOW_PREVENTION_FOLLOW_UP',
  NO_PREVENTION_FOLLOW_UP_BOOKED = 'NO_PREVENTION_FOLLOW_UP_BOOKED',
  FINISHED = 'FINISHED',
  REMOVED_BEFORE_INCLUSION = 'REMOVED_BEFORE_INCLUSION',
  REMOVED_AFTER_INCLUSION = 'REMOVED_AFTER_INCLUSION',
  INCLUDED_MISSING_VISIT = 'INCLUDED_MISSING_VISIT',
  BOOK_PREVENTION = 'BOOK_PREVENTION',
  PREVENTION_BOOKED = 'PREVENTION_BOOKED',
  DONE_PREVENTION = 'DONE_PREVENTION',
  FOLLOW_UP_PREVENTION_BOOKED = 'FOLLOW_UP_PREVENTION_BOOKED',
  PARTLY_BOOKED_AFTER_INCLUSION = 'PARTLY_BOOKED_AFTER_INCLUSION',
}

export const ScapistStatusDisplay = {
  [ScapistStatus.NEW]: 'Ännu ej inbjuden',
  [ScapistStatus.INVITED]: 'Inbjuden',
  [ScapistStatus.REMINDED]: 'Påmind',
  [ScapistStatus.PASSIVELY_UNINTERESTED]: 'Passivt ointresserad',
  [ScapistStatus.INTERESTED]: 'Intresserad',
  [ScapistStatus.UNREACHABLE]: 'Okontaktbar',
  [ScapistStatus.PARTLY_BOOKED]: 'Delvis bokad',
  [ScapistStatus.BOOKED]: 'Bokad',
  [ScapistStatus.NO_SHOW_START]: 'Uteblivet startbesök',
  [ScapistStatus.NO_SHOW_CONTINUED_START]: 'Uteblivet fortsättning på startbesök',
  [ScapistStatus.DONE_START]: 'Ankommit start',
  [ScapistStatus.DONE_CONTINUED_START]: 'Ankommit fortsättning på startbesök',
  [ScapistStatus.DONE_CT]: 'Ankommit CT',
  [ScapistStatus.NO_SHOW_CT]: 'Utebliven CT',
  [ScapistStatus.NO_SHOW_PREVENTION]: 'Utebliven prevention',
  [ScapistStatus.NO_SHOW_PREVENTION_FOLLOW_UP]: 'Utebliven uppföljningssamtal',
  [ScapistStatus.FINISHED]: 'Klar',
  [ScapistStatus.REMOVED_BEFORE_INCLUSION]: 'Avbruten innan inklusion',
  [ScapistStatus.NO_PREVENTION_FOLLOW_UP_BOOKED]: 'Inget uppföljningssamtal bokat',
  [ScapistStatus.REMOVED_AFTER_INCLUSION]: 'Avbruten efter inklusion',
  [ScapistStatus.INCLUDED_MISSING_VISIT]: 'Missat besök, inkluderad scapist',
  [ScapistStatus.BOOK_PREVENTION]: 'Boka prevention',
  [ScapistStatus.PREVENTION_BOOKED]: 'Prevention bokad',
  [ScapistStatus.DONE_PREVENTION]: 'Ankommit prevention',
  [ScapistStatus.FOLLOW_UP_PREVENTION_BOOKED]: 'Uppföljning bokad',
  [ScapistStatus.PARTLY_BOOKED_AFTER_INCLUSION]: 'Delvis bokad efter inklusion',
}
