
<div *ngIf="letterResponse | async as loadedLetterResponse">
  <div *ngIf="loadedLetterResponse.eligible && loadedLetterResponse.scapistId === scapist.id">
    <div class="card">
      <div class="card-body">
        <h3>Adress för avslutningsbrev</h3>
        <div *ngIf="!loadedLetterResponse.addressLoaded">
          <button mat-raised-button color="primary" id="fetch-address" (click)="fetchAddress()">
            Hämta adress
          </button>
        </div>
        <div *ngIf="loadedLetterResponse.addressLoaded">
          <p>
            {{scapist.firstName}} {{scapist.lastName}}<br/>
            {{loadedLetterResponse.street}} {{loadedLetterResponse.streetContinuation}}<br/>
            {{loadedLetterResponse.zip}} {{loadedLetterResponse.city}}
          </p>

          <button mat-raised-button color="primary" id="copy-address" (click)="copyToClipboard(loadedLetterResponse)">
            <mat-icon [inline]="true" class="inline-icon">content_copy</mat-icon> Kopiera adress
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
