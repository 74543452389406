<div mat-dialog-title class="dialog-title">
    <h3>Lägg till Besök</h3>
    <button mat-icon-button aria-label="Stäng bokning" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
    <form [formGroup]="form"(ngSubmit)="onSubmit(form.getRawValue())">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>Välj titel</mat-label>
                            <mat-select id="title" formControlName="title">
                                <mat-option  *ngFor="let title of titles " value="{{title}}">{{title | visitType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <mat-form-field>
                    <mat-label>Beskrivning</mat-label>
                    <input matInput formControlName="description">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Datum</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="day">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div>
                                <mat-form-field appearance="fill">
                                    <mat-label>Välj timme</mat-label>
                                    <mat-select id="hour" formControlName="hour">
                                        <mat-option value="6">6</mat-option>
                                        <mat-option value="7">7</mat-option>
                                        <mat-option value="8">8</mat-option>
                                        <mat-option value="9">9</mat-option>
                                        <mat-option value="10">10</mat-option>
                                        <mat-option value="11">11</mat-option>
                                        <mat-option value="12">12</mat-option>
                                        <mat-option value="13">13</mat-option>
                                        <mat-option value="14">14</mat-option>
                                        <mat-option value="15">15</mat-option>
                                        <mat-option value="16">16</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div>
                                <mat-form-field appearance="fill">
                                    <mat-label>Välj minut</mat-label>
                                    <mat-select id="minute" formControlName="minute">
                                        <mat-option value="0">00</mat-option>
                                        <mat-option value="15">15</mat-option>
                                        <mat-option value="30">30</mat-option>
                                        <mat-option value="45">45</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <button color="primary" mat-raised-button [disabled]="!form.valid" type="submit">Skicka</button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
