import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {Scapist} from '@shared/models/scapist.model';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {FreeBookStatusEnum} from '@shared/constants/free-book-status.enum';

export class Booking {
  public timeSlotType:  TimeSlotTypeEnum;
  public status: BookingStatusEnum | FreeBookStatusEnum;
  public time: Date;
  public scapist: Scapist;
  public id: number;
}
