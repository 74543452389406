import { Component, OnInit } from '@angular/core';
import {share} from 'rxjs/operators';
import {IssuesService} from '@shared/services/entity-services/issues.service';
import {FeaturesService} from '@shared/services/features.service';
import { Observable } from 'rxjs';
import { Issue } from '@shared/models/issue.model';

@Component({
  selector: 'app-issue-list-container',
  templateUrl: './issue-list-container.component.html',
  styleUrls: ['./issue-list-container.component.scss']
})
export class IssueListContainerComponent implements OnInit {

  issues: Observable<Issue[]>;

  constructor(private issuesService: IssuesService, private featuresService: FeaturesService) { }

  ngOnInit(): void {
    this.issues = this.issuesService.issues.pipe(share());
  }

  isEnabled() {
    return this.featuresService.isEnabled('issues');
  }
}
