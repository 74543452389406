<table mat-table [dataSource]="data" multiTemplateDataRows>
  <tr mat-header-row *matHeaderRowDef="columnIds()"></tr>
  <tr mat-row
    *matRowDef="let row; columns: columnIds()"
    class="element-row"
    [class.expanded-row]="expandedElement === row"
    (click)="expandedElement = expandedElement === row ? null : row"></tr>
  <ng-container
    *ngFor="let column of columns; let i = index"
    matColumnDef="{{ column.id }}">
    <th mat-header-cell *matHeaderCellDef>{{ column.display }}</th>
    <td mat-cell *matCellDef="let element">
      <confirm-modal-button
        *ngIf="isButton(column)"
        [action]="column.formatter.action.bind(this, element)"
        [text]="column.formatter.text">
          <button
            dialogButton
            type="button"
            mat-stroked-button
            [disabled]="isDisabled(column.formatter, element)">{{column.formatter.btnText}}
        </button>
      </confirm-modal-button>
      {{ column.formatter && !isButton(column) ? column.formatter(element) : !isButton(column) ? element[column.id] : '' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="4">
      <div
        class="element-detail"
        [@expandDetails]="element == expandedElement ? 'expanded' : 'collapsed'">
        <ng-container *ngTemplateOutlet="detailRow; context: { row: element }"></ng-container>
      </div>
    </td>
  </ng-container>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row"></tr>
</table>
