import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '@shared/services/notification.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {AuthenticationService} from '@shared/services/authentication.service';
import {ResetPasswordBean} from '@shared/models/reset-password-bean.model';
import {ActivatedRoute} from '@angular/router';
import {ValidatePassword} from "@shared/validators/password.validator";
import {ValidateMatchPassword} from "@shared/validators/match-password.validator";

@Component({
  selector: 'app-update-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  private token: string;

  updatePasswordForm: FormGroup;

  constructor(private notificationService: NotificationService,
              private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
              private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8),
        ValidatePassword.patternValidator(ValidatePassword.UPPERCASE_REGEX, { requiresUppercase: true }),
        ValidatePassword.patternValidator(ValidatePassword.LOWERCASE_REGEX, { requiresLowercase: true }),
        ValidatePassword.patternValidator(ValidatePassword.DIGIT_REGEX, { requiresDigit: true }),
        ValidatePassword.patternValidator(ValidatePassword.SPECIAL_CHAR_REGEX, { requiresSpecialChars: true })
      ]),
      repeatPassword: new FormControl('', [Validators.required])
    }, {validators: ValidateMatchPassword});
  }

  updatePassword(formData) {
    if (formData.repeatPassword !== formData.newPassword) {
      this.notificationService.updateNotification('error', 'Passwords don\'t match!');
      this.updatePasswordForm.reset();
      return;
    }

    this.authenticationService.resetPassword(new ResetPasswordBean(
      this.token,
      formData.newPassword,
      formData.repeatPassword
    ));

  }

  isDisplayError(formProperty: string) {
    const value = this.updatePasswordForm.get(formProperty);
    return value.invalid && (value.dirty || value.touched);
  }

  get newPassword() {
    return this.updatePasswordForm.get('newPassword');
  }

  get repeatPassword() {
    return this.updatePasswordForm.get('repeatPassword');
  }

  get requiredValid() {
    return !this.newPassword.hasError("required");
  }

  get minLengthValid() {
    return !this.newPassword.hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.newPassword.hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.newPassword.hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.newPassword.hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.newPassword.hasError("requiresSpecialChars");
  }
}
