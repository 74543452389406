import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '@shared/services/statistics.service';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {


  openChart = 0;
  isNationalStats = false;
  sites = [
    {name: 'Göteborg', value: 1},
    {name: 'Malmö', value: 2},
    {name: 'Stockholm', value: 3},
    {name: 'Linköping', value: 4},
    {name: 'Uppsala', value: 5},
    {name: 'Umeå', value: 6},
    {name: 'Total', value: null},
  ];
  charts = ['Inbjudningar', 'Inklusion', 'CT-Undersökningar', 'CT-Granskningar', 'Prevention', 'Studietakt'];

  constructor(private statisticsService: StatisticsService, private authService: AuthenticationService) {}


  ngOnInit(): void {
    this.isNationalStats = this.authService.userHasRole(UserTypeEnum.ROLE_STATISTIK_NATIONELLT);
    this.statisticsService.setSite(null);
    this.loadCharts();

  }

  loadCharts(): void {
    this.statisticsService.getInvitations();
    this.statisticsService.getInclusionRate();
    this.statisticsService.getInclusionRatePerWeek();
    this.statisticsService.getCtExamRatePerWeek();
    this.statisticsService.getCtExamRate();
    this.statisticsService.getCtExamRateTime();
    this.statisticsService.getCtReviewRatePerWeek();
    this.statisticsService.getCtReviewRate();
    this.statisticsService.getCtReviewRateTime();
    this.statisticsService.getCtHeartReviewRateTime();
    this.statisticsService.getCtLungReviewRateTime();
    this.statisticsService.getCtBodyReviewRateTime();
    this.statisticsService.getStudyPace();
    this.statisticsService.getStudyPaceCumulative();
    this.statisticsService.getStudyPaceTimeDistributionSUP();
    this.statisticsService.getStudyPaceTimeDistributionNoSUP();
    this.statisticsService.getPreventionShare();
    this.statisticsService.getPreventionVisitShare();
    this.statisticsService.getPreventionFollowupShare();
    this.statisticsService.getPreventionVisitTime();
    this.statisticsService.getPreventionFollowupTime();
    this.statisticsService.getTimeSlotDistributionStart();
    this.statisticsService.getTimeSlotDistributionCt();
    this.statisticsService.getTimeSlotDistributionStartContinue();
    this.statisticsService.getTimeSlotDistributionPrevention();
    this.statisticsService.getTimeSlotDistributionFollowUp();
  }

  selectChart(index: number) {
    this.openChart = index;
  }

  className(index: number) {
    return this.openChart === index ? 'active' : '';
  }

  getTitle(): string {
    if(this.authService.userHasRole(UserTypeEnum.ROLE_STATISTIK_NATIONELLT)) {
      return 'Nationell statistik'
    }
    return 'Statistik';
  }

  setSite(siteNumber: number) {
    this.statisticsService.setSite(siteNumber);
    this.loadCharts();
  }
}
