export class ResetPasswordBean {
  token: string;
  newPassword: string;
  repeatPassword: string

  constructor(token: string, newPassword: string, repeatPassword: string) {
    this.token = token;
    this.newPassword = newPassword;
    this.repeatPassword = repeatPassword;
  }
}
