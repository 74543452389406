import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TimeSlotFormComponent} from '../time-slot-form/time-slot-form.component';
import {TimeSlotService} from '@shared/services/entity-services/time-slot.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {AuthenticationService} from '@shared/services/authentication.service';
import {Site} from '@shared/models/site.model';
import {MatOption} from '@angular/material/core';
import {TimeSlotUtils} from '@shared/utils/time-slot.utils';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-repeating-time-slot-form',
  templateUrl: './repeating-time-slot-form.component.html',
  styleUrls: ['./repeating-time-slot-form.component.scss']
})
export class RepeatingTimeSlotFormComponent implements OnInit {

  form: FormGroup;
  weekDays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];
  minToDates: Date[] = [new Date()];
  site: Site;
  isSubmitted = false;
  @ViewChildren('allWeekdays') private allWeekdays: QueryList<MatOption>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { from: Date, to: Date },
    public dialogRef: MatDialogRef<TimeSlotFormComponent>,
    private service: TimeSlotService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
      this.site = this.authenticationService.getUser().site || new Site();
      this.form = this.formBuilder.group({
        rows: this.formBuilder.array([])
      });
      this.addRow();
    }

    get formRows() {
      return this.form.get('rows') as FormArray;
    }

    addRow(rowIndex = -1) {
      const row = rowIndex < 0 ? this.formBuilder.group({
        weekday: new FormControl(null, [Validators.required]),
        from: new FormControl(this.data.from, [Validators.required]),
        to: new FormControl(this.data.to, [Validators.required]),
        number: new FormControl(null, [Validators.required, Validators.max(100), Validators.min(1)]),
        visit_type: new FormControl(null, [Validators.required]),
        time: new FormArray([
          new FormGroup({
          hour: new FormControl(null, [Validators.required]),
          minute: new FormControl(null, [Validators.required])
        })
      ])
      }) : cloneDeep(this.formRows.at(rowIndex))

      row.get('from').valueChanges.subscribe(date => {
        this.minToDates[this.formRows.length - 1] = date;
      })
      this.formRows.push(row);
    }

    addTime(rowIndex: number) {
      const time = new FormGroup({
        hour: new FormControl(null, [Validators.required]),
        minute: new FormControl(null, [Validators.required]),
      })
      const timeArray = this.formRows.at(rowIndex).get('time') as FormArray;
      timeArray.push(time);
    }

    removeTime(rowIndex: number, timeIndex: number) {
      const timeArray = this.formRows.at(rowIndex).get('time') as FormArray;
      timeArray.removeAt(timeIndex);
    }

    removeRow(index: number) {
      this.formRows.removeAt(index);
    }

    getTypeName(type: TimeSlotTypeEnum) {
      return TimeSlotUtils.getTitle(type);

    }

    getTitle(index: number) {
      if (this.formRows && index < this.formRows.length) {
        const rowGroup = this.formRows.at(index) as FormGroup;
        if (rowGroup.valid) {
          const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
          const { weekday, from, to, number, visit_type } = rowGroup.controls;
          const type = this.getTypeName(visit_type.value);
          const fromDate = from.value.toLocaleDateString('sv-SE', dateOptions);
          const toDate = to.value.toLocaleDateString('sv-SE', dateOptions);
          return `${number.value} ${type}-tider varje ${weekday.value.filter(v => v > -1).map(v => `${this.weekDays[v]}`)} mellan ${fromDate} och ${toDate}`;
        }
      }
    }

    formatSelection(row: number) {
      const rowGroup = this.formRows.at(row) as FormGroup;
      const weekday = rowGroup.controls.weekday;
      return weekday.value
        ?.filter(value => value !== -1)
        .map(value => this.weekDays[value])
        .join(',');
    }

    toggleAllSelection(row: number, single = false) {
      const rowGroup = this.formRows.at(row) as FormGroup;
      const weekday = rowGroup.controls.weekday;
      const allWeekdays = this.allWeekdays.toArray()[row];
      if (single) {
        if (allWeekdays.selected) {
          allWeekdays.deselect();
          return false;
      }
        if(weekday.value.length === this.weekDays.length)
          allWeekdays.select();
      }
      if (!single) {
        if (allWeekdays.selected) {
          weekday.patchValue([...this.weekDays.map((_, index) => index), -1]);
        }
        else {
          weekday.patchValue([]);
        }
      }

    }

    saveForm() {
      this.isSubmitted = true;
      if (!this.form.valid) {
        return;
      }
      const slots = [];
      const rows = this.form.value.rows;
      for (const row in rows) {
        const rowGroup = this.formRows.at(parseFloat(row)) as FormGroup;
        const startDate = rowGroup.controls.from.value;
        const endDate = rowGroup.controls.to.value;
        for (const i = new Date(startDate); i.getTime() <= endDate.getTime(); i.setDate(i.getDate() + 7)) {
          for (const weekdayIndex of rowGroup.controls.weekday.value.filter(value => value > -1)) {
            const times = rowGroup.controls.time as FormArray<FormGroup>;
            for (const time of times.controls) {
              const hour = time.controls.hour.value;
              const minute = time.controls.minute.value;
              const date = new Date(i);
              const weekday = weekdayIndex + 1;
              const currentDay = i.getDay();
              let daysToAdd = (weekday - currentDay + 7) % 7;
              daysToAdd = daysToAdd;
              date.setDate(i.getDate() + daysToAdd);
              if (date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()) {
                date.setHours(hour);
                date.setMinutes(minute);
                date.setSeconds(0);
                slots.push({
                  time: date,
                  day: rowGroup.controls.visit_type.value,
                  number: rowGroup.controls.number.value
                });
              }
            }
          }
        }
      }
      if (slots.length > 0) {
        this.service.createSchedule(slots);
        this.dialogRef.close();
      }
    }

    getAccordionClass(row) {
      if (row.invalid && this.isSubmitted) {
        return 'has-error';
      }
    }

}
