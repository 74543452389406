import { Pipe, PipeTransform } from '@angular/core';
import { ScapistStatus, ScapistStatusDisplay } from '@shared/constants/scapist-status.enum';
import { RedcapFlowDisplay, RedcapFlowEnum } from '@shared/constants/redcap-flow.enum';
import { RedcapFlowStatusDisplay, RedcapFlowStatusEnum } from '@shared/constants/redcap-flow-status.enum';
import { EventType, EventTypeDisplay } from '@shared/constants/event-type.enum';
import { TimeSlotTypeDisplay, TimeSlotTypeEnum } from '@shared/constants/time-slot-type.enum';

type EnumTypes = 'ScapistStatus' | 'RedcapFlowEnum' | 'RedcapFlowStatusEnum' | 'EventType' | 'TimeSlotTypeEnum' | 'Unknown';
type Enums = ScapistStatus | RedcapFlowEnum | RedcapFlowStatusEnum | EventType | TimeSlotTypeEnum;

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  enumDisplayMap = {
    ScapistStatus: ScapistStatusDisplay,
    RedcapFlowEnum: RedcapFlowDisplay,
    RedcapFlowStatusEnum: RedcapFlowStatusDisplay,
    EventType: EventTypeDisplay,
    TimeSlotTypeEnum: TimeSlotTypeDisplay
  }

  transform(value?: Enums, type?: EnumTypes): string {
    if (!value) {
      return '';
    }

    if (type) {
      const enumDisplay = this.enumDisplayMap[type];
      return this.enumDisplayMap[type][value as keyof typeof enumDisplay];
    }
  }

}
