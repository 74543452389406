<div class="container">
  <h1 class="content-title content-title-account">
    Konto
  </h1>

  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row">
        <div class="col-sm-6 offset-5 col-form-label">
          <p>Välkommen {{user.firstName}} {{user.lastName}}</p>
        </div>
      </div>
    </div>
    <div class="panel-body">
      <form [formGroup]="editUserForm" (ngSubmit)="onSubmit(editUserForm.value)">
        <div class="form-group row">
          <label for="firstName" class="col-sm-2 offset-4 col-form-label">Förnamn:</label>
          <div class="col-sm-3">
            <input type="text" id="firstName" formControlName="firstName">
          </div>
          <div class="errors">
            <div *ngIf="isDisplayError('firstName') && firstName.errors.required">
              <div class="error-label">Får inte vara tomt</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="lastName" class="col-sm-2 offset-4 col-form-label">Efternamn:</label>
          <div class="col-sm-3">
            <input type="text" id="lastName" formControlName="lastName">
          </div>
          <div class="errors">
            <div *ngIf="isDisplayError('lastName') && lastName.errors.required">
              <div class="error-label">Får inte vara tomt</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="email" class="col-sm-2 offset-4 col-form-label">Epost:</label>
          <div class="col-sm-3">
            <input type="email" disabled id="email" formControlName="email">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-4 offset-4">
            <button mat-raised-button color="primary" [disabled]="!editUserForm.valid || editUserForm.pristine" type="submit">Uppdatera</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4 offset-4 col-form-label">
      <p><a [routerLink]="['/updatePassword']">Ändra lösenord</a></p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 offset-4 col-form-label">
      <p><a href="#" (click)="logOut()">Logga ut</a></p>
    </div>
  </div>
</div>
