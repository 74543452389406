import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SubStudy} from '@shared/models/sub-study.model';
import {FeaturesService} from '@shared/services/features.service';
import {SubStudyService} from '@shared/services/entity-services/sub-study.service';
import {share} from 'rxjs/operators';
import {StudyScapist} from '@shared/models/data-models/study-scapist.model';
import {Sort} from '@angular/material/sort';
import {NotificationService} from '@shared/services/notification.service';
import {Router} from '@angular/router';
import {ListFunctionsService} from '@shared/services/list-functions.service';
import {bind} from "lodash";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sub-study-scapist-list',
  templateUrl: './sub-study-scapist-list.component.html',
  styleUrls: ['./sub-study-scapist-list.component.scss']
})
export class SubStudyScapistListComponent implements OnInit, OnDestroy {

  @Input() study: SubStudy;

  displayedColumns: string [] = [];

  scapists: Observable<StudyScapist[]>;
  scapistList: StudyScapist[] = [];
  featureToggle = false;
  sessionSort: Sort = { active: 'name', direction: 'asc' };

  constructor(private featuresService: FeaturesService, private subStudyService: SubStudyService,
              private notificationService: NotificationService, private router: Router,
              private listFunctionService: ListFunctionsService) {
                this.scapists = this.subStudyService.scapists.pipe(share());
              }

  ngOnInit(): void {

    if(this.featuresService.isEnabled('studyScapists')) {
      this.displayedColumns = ['name', 'personNumber', 'status', 'inclusion', 'ctDate', 'phone', 'manage']

      if (sessionStorage.getItem(`${this.study.id}_sort`)) {
        const sort = JSON.parse(sessionStorage.getItem(`${this.study.id}_sort`));
        this.sessionSort = sort;
      }
      if(this.study && this.study.id) {
        this.subStudyService.loadScapists(this.study.id);
      }
      this.featureToggle = true;
      this.scapists.subscribe(data => {
        this.scapistList = this.sortDataList(this.sessionSort, data);
      })
    }
  }

  ngOnDestroy() {
    this.subStudyService.discardScapists();
  }

  copyToClipboard(personNumber: string) {
    navigator.clipboard.writeText(personNumber)
      .then(() => {
        this.notificationService.sendSuccessNotification('Personnumret har kopierats till urklipp');
      })
      .catch((err) => {
        this.notificationService.sendErrorNotification('Misslyckades kopiera personnumret till urklipp');
      });
  }

  redirectToBookingPage(id: number) {
    this.router.navigateByUrl('/scapist/' + id).then();
  }

  sortData(sort: Sort) {
    sessionStorage.setItem(`${this.study.id}_sort`, JSON.stringify(sort));
    this.sessionSort = sort;
    this.scapistList = this.sortDataList(sort, this.scapistList);
  }

  private sortDataList(sort: Sort, scapists: StudyScapist[]) {
    const data = scapists.slice();
    if (!sort.active || sort.direction === '') {
      return data;
    }
    return data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      let sortValue = 0;
      switch (sort.active) {
        case 'name':
          sortValue = this.listFunctionService.compare(a.firstName, b.firstName, isDesc);
          break;
        case 'personNumber':
          sortValue = this.listFunctionService.compare(a.personNumber, b.personNumber, isDesc);
          break;
        case 'inclusion':
          sortValue = this.listFunctionService.compare(Number(a.inclusion), Number(b.inclusion), isDesc);
          break;
        case 'ctDate':
          sortValue = this.listFunctionService.compare(Number(a.ctDate), Number(b.ctDate), isDesc);
          break;
        case 'status':
          sortValue = this.listFunctionService.compare(a.status, b.status, isDesc);
          break;
        case 'phone':
          sortValue = this.listFunctionService.compare(a.phone, b.phone, isDesc);
          break;
        default:
          sortValue = 0;
      }
      if(sortValue === 0) {
        sortValue = this.listFunctionService.compare(a.personNumber, b.personNumber, isDesc);
      }
      return sortValue;
    });
  }

  manage(scapist: StudyScapist) {
    this.subStudyService.manage(scapist.id, this.study.id);
  }

  protected readonly bind = bind;
}
