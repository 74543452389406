import {Injectable} from '@angular/core';
import {CallListService} from '@shared/services/scapist-services/call-list.service';
import {Scapist} from '@shared/models/scapist.model';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@shared/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class InterestedScapistsService extends CallListService {
  constructor(http: HttpClient, notificationService: NotificationService) {
    super(http, notificationService);
  }

  protected getScapistUrl(): string {
    return 'interested-scapists';
  }

  public getScapists() {
    return super.getScapists();
  }
  public getPaginationInfo() {
    return super.getPaginationInfo();
  }
  public sendCallAttempt(scapist: Scapist) {
    return super.sendCallAttempt(scapist)
  }

  public loadPage(pageIndex: number, pageSize: number) {
    super.loadPage(pageIndex, pageSize);
  }

}
