import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

import {environment} from '@environments/environment';
import {User} from '@shared/models/user.model';
import {NotificationService} from '@shared/services/notification.service';


@Injectable({providedIn: 'root'})
export class UserService {
  private baseUrl: string;

  private usersURL = '/user/';
  private updateUserRoleURL = '/update-role';
  private userEnableURL = '/enable-user';
  private dataStore: { users: User[], loading: boolean, currentUser: User } = {users: [], loading: false, currentUser: null};
  private _users = new BehaviorSubject<User[]>([]);
  private _loading = new BehaviorSubject<boolean>(false);
  private _user = new BehaviorSubject<User>(null);
  public readonly loading = this._loading.asObservable();
  public readonly users = this._users.asObservable();
  public readonly currentUser = this._user.asObservable();

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  public loadData() {
    this._loading.next(true);
    this.http.get<User[]>(this.baseUrl + this.usersURL).subscribe(data => {
      const users = [];
      data.forEach(item => {
        users.push(Object.assign(new User(), item));
      });
      this.dataStore.users = users;
      this.dispatchUsers();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in användare');
    });
  }

  private dispatchUsers() {
    this._users.next(Object.assign({}, this.dataStore).users);
  }

  private dispatchUser(user: User) {
    this.dataStore.currentUser = user;
    this._user.next(Object.assign({}, this.dataStore).currentUser);
  }

  getUserById(id: number): void {
    this.http.get<User>(this.baseUrl + this.usersURL + `${id}`).subscribe((response) => {
      this._user.next(response);
    }, () => {
      this.notificationService.sendErrorNotification('Kunde inte hämta användare');
    });
  }

  updateUserRole(id: number, userRole: string) {
    this.http.post<User>(this.baseUrl + this.usersURL + `${id}` + this.updateUserRoleURL, userRole).subscribe((data) => {
      this.loadData();
      this.dispatchUser(data)
      this.notificationService.sendSuccessNotification('Användarrättigheter uppdaterade');
    }, (err) => {
      if (err.status === 406) {
        err.error = 'Du kan inte ta bort din egen roll för behörighetsansvarig';
        this.notificationService.sendErrorNotification(err);
      } else {
        this.notificationService.sendErrorNotification(err.error);
      }
    });
  }

  clearCurrentUser() {
    this._user.next(null);
  }

  resendCreationEmail(id: number) {
    this.http.post<User>(this.baseUrl + this.usersURL + `${id}/resend-creation-email`, {}).subscribe(() => {
      this.notificationService.sendSuccessNotification('Nytt mail skickat');
    }, (err) => {
      this.notificationService.sendErrorNotification(err.error);
    });
  }

  disableUser(userId: number) {
    this.http.delete<User>(this.baseUrl + this.usersURL + `${userId}`).subscribe((data) => {
      this.loadData();
      this.dispatchUser(data);
      this.notificationService.sendSuccessNotification('Användaren har blivit låst.');
    }, (err) => {
      this.notificationService.sendErrorNotification(err.error);
    });
  }

  enableUser(userId: number) {
    this.http.put<User>(this.baseUrl + this.usersURL + `${userId}` + this.userEnableURL, null).subscribe((data) => {
      this.loadData();
      this.dispatchUser(data);
      this.notificationService.sendSuccessNotification('Användaren har blivit upplåst.');
    }, (err) => {
      this.notificationService.sendErrorNotification(err.error);
    });
  }

  getAllUsersEmail():Observable<string[]>{
    return this.http.get<string[]>(this.baseUrl + this.usersURL + `get-all-user-emails`);
  }
}
