import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@shared/services/authentication.service';
import { Observable } from 'rxjs';
import {share} from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './request-new-password.component.html',
  styleUrls: ['./request-new-password.component.css']
})
export class RequestNewPasswordComponent {

  private resetBean = { email: '' };

  requestForm: FormGroup;
  passwordHasBeenReset = false;


  submitEnabled: Observable<boolean>;

  constructor(private authenticationService: AuthenticationService, private formBuilder: FormBuilder) {
    this.requestForm = this.formBuilder.group({
      email: new FormControl(this.resetBean.email, [Validators.email, Validators.required])
    });
  }

  ngOnInit() {
    this.submitEnabled = this.authenticationService.canSendRequest.pipe(share());
  }

  get email() {
    return this.requestForm.get('email');
  }

  sendResetRequest(formData) {
    this.requestForm.reset();
    this.authenticationService.requestNewPassword(formData.email);
    this.passwordHasBeenReset = true;
  }

}
