
export class LetterResponseModel {
  scapistId: number;
  street: string;
  zip: string;
  city: string;
  streetContinuation: string;
  eligible: boolean;
  addressLoaded: boolean;
}
