import {Component} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '@shared/utils/form.utils';
import {LoaderService} from '@shared/utils/loader.service';
import {share} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  inModal = false;
  isLoading: Observable<boolean>;

  constructor(private authenticationService: AuthenticationService, private formBuilder: FormBuilder, public loaderService: LoaderService) {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.isLoading = this.loaderService.isLoading.pipe(share());
  }

  onSubmit(loginData): void {
    this.authenticationService.authenticate(loginData.email, loginData.password, !this.inModal);
  }

  isDisplayError(formProperty: string) {
    return FormUtils.isDisplayError(this.loginForm, formProperty);
  }

  cancel($event: MouseEvent): void {
    $event.preventDefault();
    this.authenticationService.logOut();
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
