import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  public isApiVersionMatchingBackend = new BehaviorSubject(true);

  constructor() {}
}