import {Component, OnInit, TemplateRef} from '@angular/core';
import {TestScapistService} from '@shared/services/test-scapist.service';
import {debounceTime, filter, first} from 'rxjs/operators';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Gender} from '@shared/constants/gender.enum';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService} from '@shared/services/authentication.service';
import {Scapist} from '@shared/models/scapist.model';
import {ScapistSearchService} from '@shared/services/scapist-services/scapist-search.service';

@Component({
  selector: 'app-test-scapist',
  templateUrl: './test-scapist.component.html',
  styleUrls: ['./test-scapist.component.scss']
})
export class TestScapistComponent implements OnInit {
  scapists: Scapist[] = [];
  mockedScapist: Scapist;
  columnsToDisplay: string[] = ['name', 'email', 'phone', 'gender', 'personNumber', 'actions'];
  createScapistForm: FormGroup;
  dialogRef: MatDialogRef<unknown, any>;

  constructor(
    private testScapistService: TestScapistService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private scapistSearchService: ScapistSearchService) {
    this.createScapistForm = this.formBuilder.group({
      email: new FormControl('scapist@gmail.com', [Validators.email, Validators.required]),
      firstName: new FormControl('Ture', [Validators.required]),
      lastName: new FormControl('Scapist', [Validators.required]),
      gender: new FormControl(Gender.MALE, [Validators.required]),
      phone: new FormControl('0701234567', [Validators.required]),
      personNumber: new FormControl(null, [Validators.required])
    })

    this.scapistSearchService.scapists.subscribe(scapists => this.scapists = scapists)
    this.testScapistService.mockedScapist.subscribe(scapist => this.mockedScapist = scapist);
  }

  ngOnInit(): void {
    this.scapistSearchService.setSort('id', 'DESC');
    this.scapistSearchService.loadPage(0,500);
    this.testScapistService.getMockedScapist();
  }

  redirectToBookingPage(id: number) {
    if (id) {
      this.router.navigateByUrl('/scapist/' + id).then();
    }
  }

  addRow() {
    if (this.createScapistForm.get('personNumber').value) {
      this.createScapist();
    }
    this.testScapistService.loading.pipe(
      filter(loading => !loading),
      debounceTime(1000),
      first()
    ).subscribe(() => {
      this.createScapistForm.patchValue(this.mockedScapist);
      this.scapists = [this.mockedScapist, ...this.scapists];
    });
  }

  createScapist() {
    this.createScapistForm.markAllAsTouched();
    const siteId = this.authenticationService.getUser().site.id;
    if (this.createScapistForm.valid) {
      this.testScapistService.createScapist({ ...this.createScapistForm.getRawValue(), siteId })
        .then((data: Scapist) => {
          this.scapists = this.scapists.map(scapist => {
            if (scapist.personNumber === data.personNumber) {
              return data;
            }
            return scapist;
          });
          this.testScapistService.getMockedScapist();
          this.createScapistForm.reset();
        });
    }
  }

  deleteScapist(personNumber: string) {
    this.testScapistService.deleteScapist(personNumber).then(() => {
      this.scapistSearchService.loadPage(0,500);
    });
  }

  openDialog(template: TemplateRef<unknown>) {
    const conf = new MatDialogConfig();
    conf.minWidth = 500;
    this.dialogRef = this.dialog.open(template, conf);
  }
}
