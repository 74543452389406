
export class InvitationBasis {
  all: number;
  invitedScapists: number;
  notInvitedScapists: number;
  hasContinuedStartVisit: boolean;
  emptyStartVisitSlots: number;
  emptyContinuedStartVisitSlots: number;
  emptyCTVisitSlots: number;
}
