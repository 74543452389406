<div class="container">
  <form [formGroup]="userRoleForm" (ngSubmit)="onSubmit(userRoleForm.value, user.id)">

    <h5>Förnamn</h5>
    {{user.firstName}}
    <h5>Efternamn</h5>
    {{user.lastName}}
    <h5>Epost</h5>
    {{user.email}}
    <div>
      <h5>Site</h5>
      <div *ngIf="user.site">
        {{user.site.name}}
      </div>
      <div *ngIf="!user.site">
        Personen tillhör ingen site
      </div>
    </div>
    <div class="form-group">
      <h5>Användarroller</h5>
      <mat-form-field class="fet-select">
        <mat-label>Lägg till roll för användare</mat-label>
        <mat-select id="userRole" formControlName="userRole" multiple>
          <mat-option *ngFor="let role of roles" [value]="role[0]">{{role[1]}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button type="submit" id="update-role" mat-raised-button color="primary"
              [disabled]="isDisabled"
              [title]="isDisabled ? disabledRoleButtonInfo: enabledRoleButtonInfo">Uppdatera roll
      </button>
    </div>

    <div *ngIf="!user.enabled; else userActivated">
      <h3>Skicka om inbjudningslänk</h3>
      <p>
        Denna användare är ännu inte aktiverad. Klicka på knappen nedan för att skicka ut ett nytt mail till användaren.
      </p>
      <button type="button" mat-raised-button color="warn" (click)="resendCreationEmail(user.id)"> Skicka om inbjudningsmail</button>
    </div>
    <ng-template #userActivated>
      <div>
        <h3>
          Användaren aktiverad
        </h3>
        <p *ngIf="user.lastLoginAttempt">
          Användaren har aktiverat sitt konto och var senast inloggad {{user.lastLoginAttempt | date:'yyyy-MM-dd HH:mm'}}.
        </p>
        <p *ngIf="!user.lastLoginAttempt">
          Användaren har aktiverat sitt konto men ännu inte loggat in i systemet.
        </p>
      </div>
    </ng-template>
    <div *ngIf="isNotTheSameUser">
      <div *ngIf="this.user.accountNonLocked; else showEnableButton">
        <h5>Lås användare</h5>
        <p>Du kan låsa användaren så att hen ej längre kan komma åt S2.</p>
        <confirm-modal-button
          text="Är du säker på att du vill låsa {{user.firstName}} {{user.lastName}}?"
          [action]="disableUser">
          <button
            id="lock-user"
            dialogButton
            type="button"
            mat-raised-button
            color="warn">Lås användare
          </button>
        </confirm-modal-button>
      </div>
      <ng-template #showEnableButton>
        <h5>Lås upp användare</h5>
        <p>Du kan låsa upp användaren för åtkomst till S2.</p>
        <confirm-modal-button
          text="Är du säker på att du vill låsa upp {{user.firstName}} {{user.lastName}}?"
          [action]="enableUser">
          <button
            dialogButton
            type="button"
            mat-raised-button
            color="primary">Lås upp användare
          </button>
        </confirm-modal-button>
      </ng-template>
    </div>

  </form>
</div>
