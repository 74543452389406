import {Component, isDevMode, OnInit} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';

@Component({
  selector: 'app-admin-resources',
  templateUrl: './admin-resources.component.html',
  styleUrls: ['./admin-resources.component.scss']
})
export class AdminResourcesComponent implements OnInit {

  isBooker: boolean;
  isClinicStaff: boolean;
  isRecruiter: boolean;
  isTimeSlotAdmin: boolean;
  isUserAuthorisationAdmin: boolean;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.isBooker = this.authenticationService.userHasRole(UserTypeEnum.ROLE_BOKARE);
    this.isClinicStaff = this.authenticationService.userHasRole(UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL);
    this.isRecruiter = this.authenticationService.userHasRole(UserTypeEnum.ROLE_REKRYTERARE);
    this.isTimeSlotAdmin = this.authenticationService.userHasRole(UserTypeEnum.ROLE_TIDBOKNINGSANSVARIG);
    this.isUserAuthorisationAdmin = this.authenticationService.userHasRole(UserTypeEnum.ROLE_BEHORIGHETSANSVARIG);
  }

  isDevMode(): boolean {
    return isDevMode();
  }
}
