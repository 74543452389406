import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { StatisticsDataModel } from '@shared/models/data-models/statistics.model';
import { CategoryScale, Chart, ChartOptions, LineController, LineElement, LinearScale, PointElement } from 'chart.js';

@Component({
  selector: 'line-chart',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit, OnChanges {

  @Input() data: StatisticsDataModel[];
  @Input() labels: string[];

  lineChartOptions: ChartOptions<'line'>;
  lineChartLabels: number[] = [];
  public lineChartDatasets = [];

  constructor() {
  }

  ngOnInit() {
    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.data || changes.labels) {
      this.drawChart();
    }
  }

  drawChart(): void {
    if(!this.data) {
      this.data = [];
    }
    if(!this.labels) {
      this.labels = [];
    }
    this.lineChartLabels = [];
    this.lineChartDatasets = [];
    this.labels.forEach((label, index) => {
      this.lineChartDatasets.push({ label, data: this.data.map(dataSet => dataSet.values[index]) })
    });
    this.data.forEach(({ label }) => this.lineChartLabels.push(label));
    this.lineChartOptions = {
      plugins: {
        datalabels: { display: false },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: 'Vecka'
          }
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Antal scapister'
          }
        }
      }
    };
    Chart.register(LineController);
    Chart.register(PointElement);
    Chart.register(LineElement);
    Chart.register(LinearScale);
    Chart.register(CategoryScale);
  }

}
