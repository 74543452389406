import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@shared/services/notification.service';
import {environment} from '@environments/environment';
import {PageModel} from '@shared/models/data-models/page.model';
import {Scapist} from '@shared/models/scapist.model';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TestScapistService {

  private readonly baseUrl: string;

  private siteScapistURL = '/test-only';
  private dataStore: {
    scapists: Scapist[],
    mockedScapist: Scapist
    loading: boolean
  } = {
    scapists: [],
    mockedScapist: new Scapist(),
    loading: false
  };


  private _loading = new BehaviorSubject<boolean>(false);
  private _scapists = new BehaviorSubject<Scapist[]>([]);
  private _mockedScapist = new BehaviorSubject<Scapist>(new Scapist());
  public readonly loading = this._loading.asObservable();
  public readonly scapists = this._scapists.asObservable();
  public readonly mockedScapist = this._mockedScapist.asObservable();

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  public getScapists() {
    this.dispatchLoading(true);
    this.http.get<PageModel>(this.scapistUrl() + 'find-test-scapists').subscribe(
      this.getScapistsReponseHandler(),
      error => {
        this.dispatchLoading(false);
        this.notificationService.updateNotification('error', 'Lyckades inte läsa in scapister');
      });
  }


  private dispatchLoading(loading: boolean) {
    this.dataStore.loading = loading;
    this._loading.next(this.dataStore.loading);
  }

  private dispatchScapists(scapists: Scapist[]) {
    this.dataStore.scapists = scapists;
    this._scapists.next(Object.assign({}, this.dataStore).scapists);
  }

  private dispatchMockedScapist(scapist: Scapist) {
    this.dataStore.mockedScapist = scapist;
    this._mockedScapist.next(Object.assign({}, this.dataStore).mockedScapist);
    this.dispatchLoading(false);
  }

  private scapistUrl(): string {
    return `${this.baseUrl}${this.siteScapistURL}/`;
  }

  generateScapists() {
    this.http.get<PageModel>(this.scapistUrl() + 'generate-test-scapists').subscribe(
      () => {
        this.getScapists();
      }, error => {
        this.dispatchLoading(false);
        this.notificationService.updateNotification('error', 'Lyckades inte läsa in scapister');
      });
  }

  getMockedScapist() {
    this.dispatchLoading(true);
    this.http.get(this.scapistUrl() + 'get-mocked-scapist').subscribe(
      (data: Scapist) => {
        this.dispatchMockedScapist(data)
      }, () => this.dispatchLoading(false)
    )
  }

  createScapist(scapist: Scapist) {
    this.dispatchLoading(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.scapistUrl() + 'create-interested-test-scapist/', scapist).subscribe(
        (res) => {
          this.dispatchLoading(false);
          this.notificationService.updateNotification('success', 'Scapist skapad');
          resolve(res);
        }, (res) => {
          this.dispatchLoading(true);
          this.notificationService.updateNotification('error', 'Lyckades inte skapa scapist. Personnumret kan vara upptaget');
          reject(res);
        }
      )
    })
  }

  deleteScapist(personNumber: string) {
    return new Promise((resolve) => {
      this.http.delete(this.scapistUrl() + 'delete/scapist/' + personNumber, { responseType: 'text' }).subscribe(res => {
        this.notificationService.updateNotification('success', 'Scapist skapad');
        resolve(res);
      });
    });
  }

  getScapistsReponseHandler() {
    const self = this;
    return (data) => {
      const scapists = [];
      data.forEach(scapist => {
        scapists.push(Object.assign(new Scapist(), scapist));
      })
      self.dispatchScapists(scapists);
      self.dispatchLoading(false);
    }

  }

  forceDailyUpdateRedcap() {
    this.http.get<string>(this.scapistUrl() + 'force-daily-update-redcap').subscribe(
      (data) => {
        this.notificationService.updateNotification('success', data);
      }, error => {
        this.dispatchLoading(false);
        this.notificationService.updateNotification('error', 'Lyckades inte forcera en uppdatering av redcap');
      });
  }
}
