import {Pipe, PipeTransform} from '@angular/core';
import {TimeSlot} from '@shared/models/time-slot.model';
import {TimeSlotUtils} from '@shared/utils/time-slot.utils';

@Pipe({
  name: 'visitStatus'
})
export class VisitStatusFormatterPipe implements PipeTransform {

  transform(status: string, isFreebook = false): string {
    if(!status) {
      return status;
    }
    switch (status) {
        case 'ABSENT':
          if(isFreebook) {
            return 'Uteblivit (Ohanterat)'
          } else {
            return 'Uteblivit';
          }
        case 'ATTENDED': return 'Ankommit';
        case 'BOOKED': return 'Bokat';
        case 'MANAGED': return 'Uteblivit (Hanterat)';

    }
  }

}
