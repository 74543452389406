import {Component, OnInit} from '@angular/core';
import {share} from 'rxjs/operators';
import {Scapist} from '@shared/models/scapist.model';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {ListFunctionsService} from '@shared/services/list-functions.service';
import {FreeBookStatusEnum} from '@shared/constants/free-book-status.enum';
import {VisitTypeFormatterPipe} from '@shared/utils/visit-type-formatter.pipe';
import {NoShowScapistsService} from '@shared/services/scapist-services/no-show-scapists.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-no-show-call-list',
  templateUrl: './no-show-call-list.component.html',
  styleUrls: ['./no-show-call-list.component.scss']
})
export class NoShowCallListComponent implements OnInit {

  scapists: Observable<Scapist[]>;

  displayedColumns: string[] = [];
  noShowScapistList: Scapist[];
  pageSizeOptions = [10, 25, 50];
  toggleNoShow = false;

  constructor(private noShowService: NoShowScapistsService, public listFunctionService: ListFunctionsService, private visitType: VisitTypeFormatterPipe) {
  }

  ngOnInit(): void {
    this.scapists = this.noShowService.scapists.pipe(share());
    this.displayedColumns = ['name', 'personNumber', 'phone', 'status', 'attempts', 'latestAttempt', 'callAttempt'];
    this.noShowService.getScapists();
    this.scapists
      .subscribe(list => {
        this.noShowScapistList = list;
        this.pageSizeOptions = [...this.pageSizeOptions, this.pageInfo.total];
      })
    this.sortData({active: 'accepted', direction: 'asc'})
  }

  getAbsentFreeBook(scapist: Scapist): string {
    const hasAbsentFreeBook = scapist.freebooks.find(book => book.status === FreeBookStatusEnum.ABSENT);
    return hasAbsentFreeBook !== undefined ? ', ' + this.visitType.transform(hasAbsentFreeBook.title) :'';
  }

  toggleShowNoShow() {
    this.toggleNoShow = !this.toggleNoShow;
  }

  get pageInfo() {
    return this.noShowService.getPaginationInfo();
  }

  sortData(sort: Sort) {
    this.noShowService.setSorting(sort.active, sort.direction);
    this.noShowService.getScapists();
  }

  sendCallAttemptEvent(scapist: Scapist, direction: number) {
    // You cannot regret a call attempt scapist when no attempts been made.
    if (direction === -1 && scapist.callAttempts === 0) {
      return;
    }
    scapist.callAttempts += direction;
    if(direction !== -1) {
      scapist.latestCallAttempt = new Date();
    }
    this.noShowService.sendCallAttempt(scapist);
  }

  handlePageEvent(event: PageEvent) {
    this.noShowService.loadPage(event.pageIndex, event.pageSize)
  }
}
