<div class="container">
  <h1>Arbetslistor</h1>
  <div *ngIf="!isLoading()">
      Funktionalitet som finns för varje scapist i listorna är följande:
      <ul>
          <li>Klicka på namnet för att hamna på scapistens sida</li>
          <li>Klicka på personnumret för att kopiera det till urklipp</li>
          <li>eCRF knappen för dig vidare till scapistens eCRF</li>
      </ul>
  </div>
  <div *ngIf="isLoading()">
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
      <h4>Hämtar senaste arbetslistorna från eCRF</h4>
  </div>
  <button mat-flat-button (click)="accordion.openAll()">Expandera alla</button>
  <button mat-flat-button (click)="accordion.closeAll()">Minimera alla</button>
  <mat-accordion #accordion="matAccordion" [multi]="true">
  <div *ngIf="!isLoading() && isMottagningspersonal">
    <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_1"></app-flow-list>
    <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_9"></app-flow-list>
    <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_17"></app-flow-list>
    <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_20"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isStudielakare">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_2"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isRontgensjukskoterska">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_3"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isGranskareHjarta">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_4"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isGranskareLunga">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_5"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isGranskareKropp">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_6"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isPreventionssjukskoterska">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_7"></app-flow-list>
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_8"></app-flow-list>
      <app-flow-list *ngIf="false" [redcapFlow]="redcapFlowEnum.FLOW_12"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isStudielakare">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_10"></app-flow-list>
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_14"></app-flow-list>
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_15"></app-flow-list>
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_18"></app-flow-list>
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_19"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && isSpiro">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_11"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && (isStudielakare || isPreventionssjukskoterska)">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_13"></app-flow-list>
  </div>
  <div *ngIf="!isLoading() && (isStudielakare || isMottagningspersonal)">
      <app-flow-list [redcapFlow]="redcapFlowEnum.FLOW_16"></app-flow-list>
  </div>
  </mat-accordion>

  <div>
    <app-issue-list-container></app-issue-list-container>
  </div>

</div>
