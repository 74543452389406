import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {RedcapFlowEnum} from '@shared/constants/redcap-flow.enum';
import { WorkListService } from '@shared/services/work-list.service';
import {NotificationService} from '@shared/services/notification.service';

@Component({
  selector: 'app-work-list',
  templateUrl: './work-list.component.html',
  styleUrls: ['./work-list.component.scss']
})
export class WorkListComponent implements OnInit {
  redcapFlowEnum: typeof RedcapFlowEnum = RedcapFlowEnum;

  isAnvandare = false;
  isMottagningspersonal = false;
  isSpiro = false;
  isStudielakare = false;
  isRontgensjukskoterska = false;
  isPreventionssjukskoterska = false;
  isGranskareHjarta = false;
  isGranskareLunga = false;
  isGranskareKropp = false;
  isStatistikSite = false;
  isStatistikNationellt = false;
  isKvalitetsgranskareSite = false;
  isKvalitetsgranskareNationell = false;
  progress = 100;

  constructor(private authenticationService: AuthenticationService, private workListService: WorkListService, private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.isAnvandare = this.authenticationService.userHasRole(UserTypeEnum.ROLE_ANVANDARE)
    this.isMottagningspersonal = this.authenticationService.userHasRole(UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL)
    this.isSpiro = this.authenticationService.userHasRole(UserTypeEnum.ROLE_SPIRO)
    this.isStudielakare = this.authenticationService.userHasRole(UserTypeEnum.ROLE_STUDIELAKARE)
    this.isRontgensjukskoterska = this.authenticationService.userHasRole(UserTypeEnum.ROLE_RONTGENSJUKSKOTERSKA)
    this.isPreventionssjukskoterska = this.authenticationService.userHasRole(UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA)
    this.isGranskareHjarta = this.authenticationService.userHasRole(UserTypeEnum.ROLE_GRANSKARE_HJARTA)
    this.isGranskareLunga = this.authenticationService.userHasRole(UserTypeEnum.ROLE_GRANSKARE_LUNGA)
    this.isGranskareKropp = this.authenticationService.userHasRole(UserTypeEnum.ROLE_GRANSKARE_KROPP)
    this.isStatistikSite = this.authenticationService.userHasRole(UserTypeEnum.ROLE_STATISTIK_SITE)
    this.isStatistikNationellt = this.authenticationService.userHasRole(UserTypeEnum.ROLE_STATISTIK_NATIONELLT)
    this.isKvalitetsgranskareSite = this.authenticationService.userHasRole(UserTypeEnum.ROLE_KVALITETSGRANSKARE_SITE)
    this.isKvalitetsgranskareNationell = this.authenticationService.userHasRole(UserTypeEnum.ROLE_KVALITETSGRANSKARE_NATIONELLT)

  }


  isLoading() {
    return this.progress < 100;
  }

}
