import {Component, Input, OnInit} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {LetterService} from '@shared/services/scapist-services/letter.service';
import {share} from 'rxjs/operators';
import {LetterResponseModel} from '@shared/models/data-models/letter-response.model';
import {NotificationService} from '@shared/services/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-letter-address',
  templateUrl: './letter-address.component.html',
  styleUrls: ['./letter-address.component.scss']
})
export class LetterAddressComponent implements OnInit {

  @Input() scapist: Scapist;

  letterResponse: Observable<LetterResponseModel>;

  constructor(private letterService: LetterService, private notificationService: NotificationService) {
    this.letterResponse = this.letterService.letterResponse.pipe(share());
  }

  ngOnInit(): void {
    if(this.scapist){
      this.letterService.checkEligibility(this.scapist.id);
    }
  }

  fetchAddress() {
    this.letterService.fetchAddress(this.scapist.id);
  }

  copyToClipboard(loadedLetterResponse: LetterResponseModel) {
    const address = this.formatAddressForClipboard(loadedLetterResponse);
    navigator.clipboard.writeText(address)
      .then(() => {
        this.notificationService.sendSuccessNotification('Adressen har kopierats till urklipp');
      })
      .catch((err) => {
        this.notificationService.sendErrorNotification('Misslyckades kopiera Adressen till urklipp');
      });
  }

  private formatAddressForClipboard(data: LetterResponseModel): string {
    return `${this.scapist.firstName || ''} ${this.scapist.lastName || ''}\n${data.street || ''} ${data.streetContinuation || ''}\n${data.zip || ''} ${data.city || ''}`;
  }
}
