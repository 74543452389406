import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[dialogButton]'
})
export class DialogDirective {
  onClick: () => void;

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    event.stopPropagation();
    if (this.onClick) {
      this.onClick();
    }
  }
}