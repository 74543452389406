<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <p>Utgråade fält går inte att ändra på, endast för information. Epost, mobilnummer samt att scapisten är inbjuden krävs för att en scapist skall kunna markeras som intresserad. </p>
          <mat-form-field>
            <mat-label for="person-number">Personnummer</mat-label>
            <input matInput type="text" id="person-number" formControlName="personNumber" class="form-control">
          </mat-form-field>
          <mat-form-field>
            <mat-label for="status">Scapistens status</mat-label>
            <input matInput type="text" id="status" formControlName="status" class="form-control">
            <input type="hidden" id="scapist-status" [value]="scapist.status">
          </mat-form-field>
          <mat-form-field>
            <mat-label for="marked-as-interested">Accepterade inbjudan </mat-label>
            <input matInput type="text" id="marked-as-interested" formControlName="markedAsInterested" class="form-control">
          </mat-form-field>
          <mat-form-field class="form-group">
            <mat-label for="email">Epost</mat-label>
            <input matInput type="text" id="email" formControlName="email" class="form-control">
            <mat-error *ngIf="isDisplayError('email') &&  (form.controls.email.dirty || form.controls.email.touched)&&  email.errors.required">Du måste fylla i epost</mat-error>
            <mat-error *ngIf="isDisplayError('email') && email.errors.email">Formatet på eposten måste vara: epost&#64;epost.se</mat-error>
          </mat-form-field>
          <mat-form-field class="form-group">
            <mat-label for="phone">Mobilnummer</mat-label>
            <input matInput type="text" id="phone" formControlName="phone" class="form-control">
            <mat-error *ngIf="isDisplayError('phone') && (phone.dirty || phone.touched) && phone.errors.required">Du måste fylla i telefonnummer</mat-error>
            <mat-error *ngIf="isDisplayError('phone') && phone.errors.phoneNumber">Du måste fylla i ett giltilgt svenskt telefonnummer</mat-error>
          </mat-form-field>

          Påminnelse om bokat besök:
          <mat-checkbox formControlName="remindedViaSms" [checked]="scapist.remindedViaSms">SMS</mat-checkbox>
          <mat-checkbox formControlName="remindedViaEmail" [checked]="scapist.remindedViaEmail">E-post</mat-checkbox>

          <div class="form-group">
            <div class="button-container">
              <button
                type="submit"
                [disabled]="!form.valid"
                mat-raised-button color="primary">Spara
              </button>
              <div *ngIf="!(isScapistInterested) && !(isScapistRemoved) &&isBooker">
                <button
                  [disabled]="!canMarkScapistAsInterested"
                  type="button"
                  title="Mobilnummer och epost måste vara ifyllt för att kunna markera person som intresserad."
                  mat-raised-button color="primary"
                  (click)="markAsInterested()">Markera som intresserad
                </button>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
