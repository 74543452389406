<div class="container">
  <div *ngIf="qrCodeUri | async as loadedQrCodeUri">
    <app-qr-code [qrCodeURI]="loadedQrCodeUri"></app-qr-code>
  </div>
  <div class="content-title">
    Skriv in autentiseringskoden från din Authenticator app.
  </div>
  <div class="content">
    <input autofocus #authcode id="code" (keyup.enter)="checkAuthCode(authcode.value)">
    <button mat-stroked-button type="submit" (click)="checkAuthCode(authcode.value)">Skicka</button>
  </div>
</div>
