<form [formGroup]="studyForm" (ngSubmit)="onSubmit(studyForm.value)">

  <div class="row">
    <div class="col-md-4">
      <mat-form-field>
        <mat-label>Studieidentifierare</mat-label>
        <input matInput  class="form-control" formControlName="identifier" id="identifier">
        <mat-error *ngIf="isDisplayError('identifier') &&  (studyForm.controls.identifier.dirty || studyForm.controls.identifier.touched) &&  studyForm.controls.identifier.errors.required">Du måste fylla i studieidentifierare</mat-error>
        <mat-error *ngIf="isDisplayError('identifier') && studyForm.controls.identifier.errors.maxlength">Får max vara 10 tecken</mat-error>

      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field>
        <mat-label>Studietitel</mat-label>
        <input matInput  class="form-control" formControlName="title" id="title" max="50">
        <mat-error *ngIf="isDisplayError('title') &&  (studyForm.controls.title.dirty || studyForm.controls.title.touched) &&  studyForm.controls.title.errors.required">Du måste fylla i titel</mat-error>
        <mat-error *ngIf="isDisplayError('title') && studyForm.controls.title.errors.maxlength">Får max vara 30 tecken</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field>
        <mat-label>Ansvarig forskares email</mat-label>
        <input matInput  class="form-control" formControlName="email" id="email">
        <mat-error *ngIf="isDisplayError('email') &&  (studyForm.controls.email.dirty || studyForm.controls.email.touched) &&  studyForm.controls.email.errors.required">Du måste fylla i epost</mat-error>
        <mat-error *ngIf="isDisplayError('email') && studyForm.controls.email.errors.email">Formatet på eposten måste vara: epost&#64;epost.se</mat-error>
      </mat-form-field>

    </div>
  </div>






  <div class="row form-group">
   <h3>Scapister</h3>
    <div *ngIf="study.id">
      <p>
        Studien är inläst med {{study.numberOfScapists}} potentiella scapist(er). Om man laddar upp en ny fil så kommer
        kopplingen till de tidigare scapisterna plockas bort och ersättas med innehållet i den senast uppladdade filen.
      </p>
    </div>
    <p>
      Ladda upp en fil innehållande personnummer på de scapister som potentiellt kan vara med i studien.
    </p>

    <div class="custom-file">
      <mat-form-field>
        <button mat-icon-button matPrefix (click)="$event.preventDefault();f_input.click()">
          <mat-icon>attach_file</mat-icon>
        </button>
        <input type="text" readonly matInput formControlName="filename" />
        <input
          id="file"
          type="file"
          multiple
          hidden
          #f_input
          (change)="onFileSelect($event)"
        />
      </mat-form-field>

    </div>
  </div>

  <div class="form-group">
    <button mat-raised-button color="primary" [disabled]="!studyForm.valid" type="submit">Skicka</button>
  </div>
</form>
