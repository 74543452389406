import { Injectable } from '@angular/core';
import {Issue} from '@shared/models/issue.model';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@shared/services/notification.service';
import {environment} from '@environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  private readonly baseUrl: string;

  private issuesURL = '/issues/';

  private dataStore: {
    issues: Issue[],
    loading: boolean,
  } = {issues: [], loading: false};

  private _issues = new BehaviorSubject<Issue[]>([]);
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading = this._loading.asObservable();
  public readonly issues = this._issues.asObservable();

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
    this.loadData();
  }


  public loadData() {
    this._loading.next(true);
    this.http.get<Issue[]>(this.baseUrl + this.issuesURL).subscribe(data => {
      this.dataStore.issues = data;
      this.dispatchIssues();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Kunde inte ladda queries');
    });
  }

  private dispatchIssues() {
    this._issues.next(Object.assign({}, this.dataStore).issues);

  }
}
