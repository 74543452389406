import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '@shared/models/user.model';
import {environment} from '@environments/environment';
import {BehaviorSubject} from 'rxjs';
import {AuthResponse} from '@shared/models/auth-response.module';
import {Router} from '@angular/router';
import {NotificationService} from '@shared/services/notification.service';
import {ResetPasswordBean} from '@shared/models/reset-password-bean.model';
import {UpdatePasswordBean} from '@shared/models/update-password-bean.model';
import {ViewFormatter} from '@shared/utils/view-formatter';
import {UserForm} from '@shared/models/user-form.model';
import {Site} from '@shared/models/site.model';
import {UserService} from '@shared/services/user.service';
import {ReloadService} from '@shared/services/reload.service';
import {VersionService} from '@shared/utils/version.service';
import {S2ApiVersion} from '@environments/api-version';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly TEN_MINUTES = 1000 * 60 * 10;
  private expirationChecker;
  private authUrl: string;
  private editUserUrl: string;
  private enableUserUrl: string;
  private refreshUserUrl: string;
  private updatePasswordUrl: string;
  private resetPasswordUrl: string;
  private requestNewPasswordUrl: string;
  private updateUserUrl: string;

  private redirectPath: string;

  private dataStore: { user: User, sessionExpired: boolean, } = {user: new User(), sessionExpired: false};
  private _user = new BehaviorSubject<User>(new User());
  private userTryingToLogin: string;
  private _qrToken = new BehaviorSubject<string>('');
  private _sessionExpired = new BehaviorSubject<boolean>(false);

  private _canSendRequest = new BehaviorSubject<boolean>(true);

  public readonly user = this._user.asObservable();
  public readonly qrToken = this._qrToken.asObservable();
  public readonly sessionExpired = this._sessionExpired.asObservable();
  public readonly canSendRequest = this._canSendRequest.asObservable();

  constructor(private httpClient: HttpClient, private router: Router,
              private notificationService: NotificationService,
              private userService: UserService, private reloadService: ReloadService, private versionService: VersionService) {
    this.authUrl = environment.baseUrl + '/authenticate';
    this.editUserUrl = environment.baseUrl + '/user/';
    this.enableUserUrl = environment.baseUrl + '/user/enable';
    this.refreshUserUrl = environment.baseUrl + '/user/refresh';
    this.updateUserUrl = environment.baseUrl + '/user/update';
    this.updatePasswordUrl = environment.baseUrl + '/password/update';
    this.resetPasswordUrl = environment.baseUrl + '/password/reset';
    this.requestNewPasswordUrl = environment.baseUrl + '/password/requestNew';
    if (sessionStorage.getItem('token') && sessionStorage.getItem('expirationDate')) {
      if (this.getExpirationChecker()()) {
        this.refreshUser();
      }
    }
    // Checks each request if the api version is the same.
    this.checkIfUserHasSameApiVersion();
  }

  authenticate(email: string, password: string, redirect = true) {
    this.httpClient.post<AuthResponse>(this.authUrl, {
      username: email,
      password
    }, { observe: 'response' }).subscribe(
      (response) => {
        if(response.headers.get('s2-api-version') !== S2ApiVersion){
          this.notificationService.sendErrorNotification('Ett versionsfel har uppstått, försök ladda om sidan (Shift + F5). Annars vänligen kontakta er lokala koordinator');
          return;
        }
        this.getUserResponseHandler(redirect)(response.body);
      },
      (error) => {
        this.getApiErrorHandler()(error);
      }
    );
  }


  extendSession() {
    this.httpClient.post<AuthResponse>(this.authUrl + '/extend-session', {}).subscribe(
      this.getUserResponseHandler(false),
      this.getApiErrorHandler()
    );
  }

  verify(loginCode: string, redirect = true) {
    this.httpClient.post<AuthResponse>(this.authUrl + '/verify-mfa', {
      username: this.userTryingToLogin,
      code: loginCode
    }).subscribe(
      this.getUserResponseHandler(redirect),
      this.getApiErrorHandler()
    );
  }

  setRedirectTo(path: string) {
    this.redirectPath = path;
  }

  createUser(username: string): any {
    this.httpClient.post<any>(this.editUserUrl, {username})
      .subscribe(() => {
        this.userService.loadData();
        this.notificationService.sendSuccessNotification('E-post skickat till den angivna adressen');
      }, this.getApiErrorHandler());
  }

  isUserLoggedIn(): boolean {
    return this.getUser().isLoggedIn();
  }

  getUser(): User {
    return Object.assign(new User(), this.dataStore.user);
  }

  userHasRole(userType: string): boolean {
    return this.getUser().hasRole(userType);
  }

  userHasAnyRole(userTypes: string[]): boolean {
    return userTypes.map(userType => this.getUser().hasRole(userType)).some(value => value === true);
  }

  logOut() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('expirationDate');
    sessionStorage.removeItem('sessionVisitListFilter');
    // Remove flow list saved sortings
    for (let i = sessionStorage.length - 1; i >= 0; i--) {
      const key = sessionStorage.key(i);
      if (key.startsWith("FLOW_") && key.endsWith("_sort")) {
        sessionStorage.removeItem(key);
      }
    }
    this.setExpiredSession(false);
    this.updateUser(new User());
    this.reloadPage();
  }

  reloadPage() {
    this.reloadService.reloadApp()
  }

  /**
   * For users who have forgotten their password and entered a new one via token sent to their mail.
   * @param resetPasswordBean bean containing new password, repeat password and a valid token.
   */
  resetPassword(resetPasswordBean: ResetPasswordBean) {
    this.notificationService.closeNotification();
    this.httpClient.post<AuthResponse>(this.resetPasswordUrl, {
      token: resetPasswordBean.token,
      newPassword: resetPasswordBean.newPassword,
      repeatPassword: resetPasswordBean.repeatPassword
    }).subscribe(this.getUserResponseHandler(), this.getApiErrorHandler());
  }

  /**
   * For logged in users that want to change their password.
   * @param updatePasswordBean the bean containing current, new and repeated new password.
   */
  updatePassword(updatePasswordBean: UpdatePasswordBean) {
    this.notificationService.closeNotification();
    this.httpClient.post<AuthResponse>(this.updatePasswordUrl,
      updatePasswordBean
    ).subscribe(this.getUserResponseHandler(), this.getApiErrorHandler());
  }

  requestNewPassword(email: string) {
    this._canSendRequest.next(false);
    this.notificationService.closeNotification();
    this.httpClient.post(this.requestNewPasswordUrl, email).subscribe(() => {
      this.notificationService.sendSuccessNotification(`En återställnings länk har skickats till ${email}, följ instruktionerna i mailet. `);
      this._canSendRequest.next(true);
    }, err => {
      this.notificationService.sendErrorNotification(err);
      this._canSendRequest.next(true);
    });
  }

  private updateUser(user: User) {
    this.dataStore.user = Object.assign(new User(), user);
    this._user.next(Object.assign({}, this.dataStore).user);
  }

  public updateUserDetails(userFormData: UserForm) {
    this.httpClient.put<AuthResponse>(this.updateUserUrl, userFormData)
      .subscribe(
        this.getUserResponseHandler(false, true),
        this.getApiErrorHandler()
      );
  }

  private redirectToLandingPage() {
    this.redirectTo(this.redirectPath || '/start');
    this.setRedirectTo(undefined);
  }

  private redirectToLoginPage() {
    this.redirectTo('/login');
  }

  private redirectToAuthCode() {
    this.redirectTo('/auth-code');
  }

  private redirectTo(path: string) {
    this.router.navigateByUrl(path).then().catch(() => console.error('Could not redirect!'));
  }

  private refreshUser() {
    this.httpClient.get(this.refreshUserUrl).subscribe(
      this.getUserResponseHandler(),
      this.getApiErrorHandler()
    );
  }

  private getApiErrorHandler() {
    return error => {
      this.notificationService.sendErrorNotification(error);
    };
  }


  private getUserResponseHandler(redirect = true, notify = false) {
    return (data) => {
      if (!data.token) {
        if (data.qrCodeImageURI) {
          this._qrToken.next(data.qrCodeImageURI);
        }
        this.userTryingToLogin = data.user.username;
        this.redirectToAuthCode();
        return;
      }
      sessionStorage.setItem('token', 'Bearer ' + data.token);
      sessionStorage.setItem('expirationDate', data.expirationDate);
      this.setExpiredSession(false);
      this.updateUser(data.user);
      if (this.expirationChecker) {
        clearInterval(this.expirationChecker);
      }
      this.expirationChecker = setInterval(this.getExpirationChecker(), 5000);
      if (redirect) {
        this.redirectToLandingPage();
      } else {
        if (notify) {
          this.notificationService.sendSuccessNotification('User updated');
        } else {
          this.notificationService.closeNotification();
        }
      }
    };
  }

  private hasSessionExpired(): boolean {
    return this.dataStore.sessionExpired;
  }

  private setExpiredSession(status: boolean) {
    this.dataStore.sessionExpired = status;
    this._sessionExpired.next(status);
    if (status) {
      clearInterval(this.expirationChecker);
    }
  }

  private getExpirationChecker() {
    const self = this;
    return () => {
      const tokenExpirationDate = sessionStorage.getItem('expirationDate');
      if (tokenExpirationDate && tokenExpirationDate !== '' && !self.hasSessionExpired()) {
        const timeUntilExpiration = Number(tokenExpirationDate) - Date.now();
        if (timeUntilExpiration <= 0) {
          self.setExpiredSession(true);
          this.logOut();
          self.notificationService.updateNotification('error', 'Din session har gått ut');
          return false;
        } else if (timeUntilExpiration <= this.TEN_MINUTES) {
          self.notificationService.updateNotification('notice', 'Du blir utloggad om:  ' +
            ViewFormatter.millisecondsToReadableTimeLeft(timeUntilExpiration),
            'Förläng session', () => {
              self.extendSession();
            });
        }
        return true;
      }
    };
  }

  resetQrToken() {
    this._qrToken.next(null);
  }

  updateSite(data: Site) {
    this.dataStore.user.site = data;
  }

  enableUser(value: any, token: string) {
    value.token = token;
    this.httpClient.post(this.enableUserUrl, value).subscribe(() => {
      this.notificationService.sendSuccessNotification('Logga nu in för att koppla Authenticator');
      this.redirectToLoginPage();
    }, () => {
      this.notificationService.sendErrorNotification('Misslyckades med att aktivera kontot. Vänligen kontakta er administratör')
    });
  }

  private checkIfUserHasSameApiVersion() {
    this.versionService.isApiVersionMatchingBackend.subscribe(isApiVersionMatchingBackend => {
      if (isApiVersionMatchingBackend === false && this.isUserLoggedIn()) {
        this.logOut();
      }
    });
  }

}
