<div class="container">
  <div class="content-title">
    <div class="content-title-icon">&#xf007;</div>
    Skapa ett nytt lösenord
  </div>

  <div class="content">
    <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword(updatePasswordForm.value)">
      <div class="form-group">
        <label for="newPassword">Nytt lösenord: </label> <input type="password" id="newPassword"
                                                                formControlName="newPassword">
        <div *ngIf="isDisplayError('newPassword') && newPassword.errors.required">
          <div class="error-label">Får inte vara tomt</div>
        </div>
        <div *ngIf="requiredValid">
          <div [ngClass]="{ 'password-validation-success': minLengthValid, 'password-validation-error': !minLengthValid }">
            <div class="content-title-icon">{{minLengthValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste vara minst 8 tecken långt
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresDigitValid, 'password-validation-error': !requiresDigitValid }">
            <div class="content-title-icon">{{requiresDigitValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst en siffra
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresUppercaseValid, 'password-validation-error': !requiresUppercaseValid }">
            <div class="content-title-icon">{{requiresUppercaseValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst en stor bokstav
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresLowercaseValid, 'password-validation-error': !requiresLowercaseValid }">
            <div class="content-title-icon">{{requiresLowercaseValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst en liten bokstav
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresSpecialCharsValid, 'password-validation-error': !requiresSpecialCharsValid }">
            <div class="content-title-icon">{{requiresSpecialCharsValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst ett specialtecken
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="repeatPassword">Uppdatera lösenord: </label> <input type="password" id="repeatPassword"
                                                                        formControlName="repeatPassword">
        <div *ngIf="isDisplayError('repeatPassword') && repeatPassword.errors.required">
          <div class="error-label">Får inte vara tomt</div>
        </div>
        <div *ngIf="isDisplayError('repeatPassword') && repeatPassword.errors.passwordMismatch">
          <div class="error-label">{{ repeatPassword.getError('passwordMismatchMessage') }}</div>
        </div>
      </div>

      <button mat-stroked-button [disabled]="!updatePasswordForm.valid" type="submit">Uppdatera</button>
    </form>
  </div>
</div>
