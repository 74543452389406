import {Pipe, PipeTransform} from '@angular/core';
import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {TimeSlot} from '@shared/models/time-slot.model';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {DateUtils} from './date.utils';

export interface SummeredTimeSlot {
  type: TimeSlotTypeEnum;
  totalNumberOfTimeSlots: number;
  numberOfAvailableTimeSlots: number;
  time: string;
}

@Pipe({
  name: 'dayTypeSlots'
})
export class DayTypeSlotsPipe implements PipeTransform {

  transform(date: Date, hour: string, quarter: string, filteredTimeSlotsMap: Map<string, TimeSlot[]> = new Map(), totalTimeSlots: Map<string, TimeSlot[]>): SummeredTimeSlot[] {
    const filteredSlots = Array.from(filteredTimeSlotsMap.values()).flat();
    const totalSlots = Array.from(totalTimeSlots.values()).flat();
    const summary: { [key: string]: SummeredTimeSlot } = {};

    date.setHours(parseFloat(hour));
    date.setMinutes(parseFloat(quarter));

    filteredSlots
      .filter(slot => {
          const slotDate = new Date(slot.time);
          return DateUtils.isSameDate(date, slotDate) && DateUtils.isSameQuarter(date, slotDate);
        }
      )
      .forEach(slot => {
        const [timeKey, key] = this.getKey(slot);
        if (!summary[key]) {
          summary[key] = {
            type: slot.timeSlotType,
            totalNumberOfTimeSlots: 0,
            numberOfAvailableTimeSlots: 0,
            time: `${hour}:${timeKey}`
          };
        }
      });

    totalSlots
      .filter(slot => {
        const slotDate = new Date(slot.time);
        return DateUtils.isSameDate(date, slotDate) && DateUtils.isSameQuarter(date, slotDate);
      })
      .forEach(slot => {
        const [_, key] = this.getKey(slot);
        if (summary[key]) {
          if (slot.status === BookingStatusEnum.EMPTY) {
            summary[key].numberOfAvailableTimeSlots++;
          }
          summary[key].totalNumberOfTimeSlots++;
        }
      });

    return Object.values(summary).sort((a, b) => a.time.localeCompare(b.time));
  }

  getKey(slot: TimeSlot) {
    const timeKey = slot.time.getMinutes() < 10 ? `0${slot.time.getMinutes()}` : `${slot.time.getMinutes()}`;
    const key = `${slot.timeSlotType}_${timeKey}`;
    return [timeKey, key]
  }


}
