import {UserRole} from '@shared/models/user-role.model';
import {UserTypeDisplay, UserTypeDisplaySite7} from '@shared/constants/user-type.enum';
import {Site} from "@shared/models/site.model";

export class ViewFormatter {
  public static timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
  public static millisecondsToReadableTimeLeft(value: number) {
    const date = new Date(value);
    return `${date.getMinutes()} minutes and ${date.getSeconds()} seconds`;
  }

  public static  formatDate(value: Date) {
    const d = new Date(value);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  public static formatRoles(authorities: UserRole[], site: Site) {
    if(site.number !== 7) {
      return authorities.map(auth => UserTypeDisplay[auth.authority]).join(', ');
    }
    return authorities.map(auth => UserTypeDisplaySite7[auth.authority]).join(', ');
  }
}
