<div class="container">
  <div class="content-title">
    <div class="content-title-icon">&#xf044;</div>
    Återställ lösenord
  </div>
  <div class="content">
    <p><strong>Notera:</strong> Vid återställning av lösenord kommer du behöva skanna en ny QR-kod i din Microsoft Authenticator app.  </p>
    <form [formGroup]="requestForm" (ngSubmit)="sendResetRequest(requestForm.value)">
      <p><label for="email">Epost:</label> <input type="email" id="email" formControlName="email"></p>
      <button mat-stroked-button [disabled]="!(requestForm.valid && (submitEnabled | async))" type="submit">Återställ
      </button>
    </form>
    <div *ngIf="passwordHasBeenReset">
      <nav>
        <button mat-stroked-button routerLink="/login">Logga in</button>
      </nav>
    </div>
  </div>
</div>
