import {Injectable} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NotificationService} from '@shared/services/notification.service';
import {environment} from '@environments/environment';
import {RedcapFlowEnum} from '@shared/constants/redcap-flow.enum';
import {WorkListScapistModel} from '@shared/models/work-list-scapist.model';
import {Issue} from "@shared/models/issue.model";

@Injectable({
  providedIn: 'root'
})
export class WorkListService {

  private readonly baseUrl: string;

  private workListScapistURL = '/work-list/';
  private dataStore: {
    flow1Scapists: WorkListScapistModel[],
    flow2Scapists: WorkListScapistModel[],
    flow3Scapists: WorkListScapistModel[],
    flow4Scapists: WorkListScapistModel[],
    flow5Scapists: WorkListScapistModel[],
    flow6Scapists: WorkListScapistModel[],
    flow7Scapists: WorkListScapistModel[],
    flow8Scapists: WorkListScapistModel[],
    flow9Scapists: WorkListScapistModel[],
    flow10Scapists: WorkListScapistModel[],
    flow11Scapists: WorkListScapistModel[],
    flow12Scapists: WorkListScapistModel[],
    flow13Scapists: WorkListScapistModel[],
    flow14Scapists: WorkListScapistModel[],
    flow15Scapists: WorkListScapistModel[],
    flow16Scapists: WorkListScapistModel[],
    flow17Scapists: WorkListScapistModel[],
    flow18Scapists: WorkListScapistModel[],
    flow19Scapists: WorkListScapistModel[],
    flow20Scapists: WorkListScapistModel[],
    aux1Scapists: WorkListScapistModel[],
    statistics1Scapists: WorkListScapistModel[],
    statistics2Scapists: WorkListScapistModel[],
    loading: boolean,
    currentScapist,
  } = {
    flow1Scapists: [],
    flow2Scapists: [],
    flow3Scapists: [],
    flow4Scapists: [],
    flow5Scapists: [],
    flow6Scapists: [],
    flow7Scapists: [],
    flow8Scapists: [],
    flow9Scapists: [],
    flow10Scapists: [],
    flow11Scapists: [],
    flow12Scapists: [],
    flow13Scapists: [],
    flow14Scapists: [],
    flow15Scapists: [],
    flow16Scapists: [],
    flow17Scapists: [],
    flow18Scapists: [],
    flow19Scapists: [],
    flow20Scapists: [],
    aux1Scapists: [],
    statistics1Scapists: [],
    statistics2Scapists: [],
    loading: false,
    currentScapist: null,
  };

  private _loading = new BehaviorSubject<boolean>(false);
  private _flow1Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow2Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow3Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow4Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow5Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow7Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow6Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow8Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow9Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow10Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow11Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow12Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow13Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow14Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow15Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow16Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow17Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow18Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow19Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _flow20Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);

  private _aux1Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _statistics1Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  private _statistics2Scapists = new BehaviorSubject<WorkListScapistModel[]>([]);
  public readonly loading = this._loading.asObservable();

  get flow1Scapists() {
    return this._flow1Scapists.asObservable();
  }

  get flow2Scapists() {
    return this._flow2Scapists.asObservable();
  }

  get flow3Scapists(): Observable<WorkListScapistModel[]> {
    return this._flow3Scapists.asObservable();
  }

  get flow4Scapists() {
    return this._flow4Scapists.asObservable();
  }

  get flow5Scapists() {
    return this._flow5Scapists.asObservable();
  }

  get flow6Scapists() {
    return this._flow6Scapists.asObservable();
  }

  get flow7Scapists() {
    return this._flow7Scapists.asObservable();
  }

  get flow8Scapists() {
    return this._flow8Scapists.asObservable();
  }

  get flow9Scapists() {
    return this._flow9Scapists.asObservable();
  }

  get flow10Scapists() {
    return this._flow10Scapists.asObservable();
  }

  get flow11Scapists() {
    return this._flow11Scapists.asObservable();
  }

  get flow12Scapists() {
    return this._flow12Scapists.asObservable();
  }

  get flow13Scapists() {
    return this._flow13Scapists.asObservable();
  }

  get flow14Scapists() {
    return this._flow14Scapists.asObservable();
  }

  get flow15Scapists() {
    return this._flow15Scapists.asObservable();
  }

  get flow16Scapists() {
    return this._flow16Scapists.asObservable();
  }

  get flow17Scapists() {
    return this._flow17Scapists.asObservable();
  }

  get flow18Scapists() {
    return this._flow18Scapists.asObservable();
  }

  get flow19Scapists() {
    return this._flow19Scapists.asObservable();
  }
  get flow20Scapists() {
    return this._flow20Scapists.asObservable();
  }


  get aux1Scapists() {
    return this._aux1Scapists.asObservable();
  }

  get statistics1Scapists() {
    return this._statistics1Scapists.asObservable();
  }

  get statistics2Scapists() {
    return this._statistics2Scapists.asObservable();
  }


  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }


  public getScapistsByFlowScapists(redcapFlowEnum: RedcapFlowEnum) {
    this.dispatchLoading(true);
    const params = new HttpParams().set('flow', redcapFlowEnum.toString());
    this.http.get<WorkListScapistModel[]>(this.scapistUrl(null) + 'getAllScapistsByFlow', {params}).subscribe(
      this.getScapistsReponseHandler(redcapFlowEnum)
      , () => {
        this.dispatchLoading(false);
        this.notificationService.updateNotification('error', 'Lyckades inte läsa in scapister');
      });
  }

  private dispatchLoading(loading: boolean) {
    this.dataStore.loading = loading;
    this._loading.next(this.dataStore.loading);
  }

  private dispatchFlow1Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow1Scapists = scapists;
    this._flow1Scapists.next(Object.assign({}, this.dataStore).flow1Scapists);
  }

  private dispatchFlow2Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow2Scapists = scapists;
    this._flow2Scapists.next(Object.assign({}, this.dataStore).flow2Scapists);
  }

  private dispatchFlow4Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow4Scapists = scapists;
    this._flow4Scapists.next(Object.assign({}, this.dataStore).flow4Scapists);
  }

  private dispatchFlow3Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow3Scapists = scapists;
    this._flow3Scapists.next(Object.assign({}, this.dataStore).flow3Scapists);
  }

  private dispatchFlow5Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow5Scapists = scapists;
    this._flow5Scapists.next(Object.assign({}, this.dataStore).flow5Scapists);
  }

  private dispatchFlow6Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow6Scapists = scapists;
    this._flow6Scapists.next(Object.assign({}, this.dataStore).flow6Scapists);
  }

  private dispatchFlow7Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow7Scapists = scapists;
    this._flow7Scapists.next(Object.assign({}, this.dataStore).flow7Scapists);
  }

  private dispatchFlow8Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow8Scapists = scapists;
    this._flow8Scapists.next(Object.assign({}, this.dataStore).flow8Scapists);
  }

  private dispatchFlow9Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow9Scapists = scapists;
    this._flow9Scapists.next(Object.assign({}, this.dataStore).flow9Scapists);
  }

  private dispatchFlow10Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow10Scapists = scapists;
    this._flow10Scapists.next(Object.assign({}, this.dataStore).flow10Scapists);
  }

  private dispatchFlow11Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow11Scapists = scapists;
    this._flow11Scapists.next(Object.assign({}, this.dataStore).flow11Scapists);
  }

  private dispatchFlow12Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow12Scapists = scapists;
    this._flow12Scapists.next(Object.assign({}, this.dataStore).flow12Scapists);
  }

  private dispatchFlow13Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow13Scapists = scapists;
    this._flow13Scapists.next(Object.assign({}, this.dataStore).flow13Scapists);
  }

  private dispatchFlow14Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow14Scapists = scapists;
    this._flow14Scapists.next(Object.assign({}, this.dataStore).flow14Scapists);
  }

  private dispatchFlow15Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow15Scapists = scapists;
    this._flow15Scapists.next(Object.assign({}, this.dataStore).flow15Scapists);
  }

  private dispatchFlow16Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow16Scapists = scapists;
    this._flow16Scapists.next(Object.assign({}, this.dataStore).flow16Scapists);
  }

  private dispatchFlow17Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow17Scapists = scapists;
    this._flow17Scapists.next(Object.assign({}, this.dataStore).flow17Scapists);
  }

  private dispatchFlow18Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow18Scapists = scapists;
    this._flow18Scapists.next(Object.assign({}, this.dataStore).flow18Scapists);
  }

  private dispatchFlow19Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow19Scapists = scapists;
    this._flow19Scapists.next(Object.assign({}, this.dataStore).flow19Scapists);
  }

  private dispatchFlow20Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.flow20Scapists = scapists;
    this._flow20Scapists.next(Object.assign({}, this.dataStore).flow20Scapists);
  }

  private dispatchAux1Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.aux1Scapists = scapists;
    this._aux1Scapists.next(Object.assign({}, this.dataStore).aux1Scapists);
  }

  private dispatchStatistics1Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.statistics1Scapists = scapists;
    this._statistics1Scapists.next(Object.assign({}, this.dataStore).statistics1Scapists);
  }

  private dispatchStatistics2Scapists(scapists: WorkListScapistModel[]) {
    this.dataStore.statistics2Scapists = scapists;
    this._statistics2Scapists.next(Object.assign({}, this.dataStore).statistics2Scapists);
  }

  private scapistUrl(id: number): string {
    return `${this.baseUrl}${this.workListScapistURL}${id || ''}`;
  }

  getScapistsReponseHandler(flow: RedcapFlowEnum) {
    const self = this;
    return (data) => {
      const scapists = [];
      data.forEach(scapist => {
        scapists.push(Object.assign(new WorkListScapistModel(), scapist));
      })
      switch (flow) {
        case RedcapFlowEnum.FLOW_1:
          self.dispatchFlow1Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_2:
          self.dispatchFlow2Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_3:
          self.dispatchFlow3Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_4:
          self.dispatchFlow4Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_5:
          self.dispatchFlow5Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_6:
          self.dispatchFlow6Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_7:
          self.dispatchFlow7Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_8:
          self.dispatchFlow8Scapists(scapists);
          break;
        case RedcapFlowEnum.FLOW_9:
          self.dispatchFlow9Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_10:
          self.dispatchFlow10Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_11:
          self.dispatchFlow11Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_12:
          self.dispatchFlow12Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_13:
          self.dispatchFlow13Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_14:
          self.dispatchFlow14Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_15:
          self.dispatchFlow15Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_16:
          self.dispatchFlow16Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_17:
          self.dispatchFlow17Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_18:
          self.dispatchFlow18Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_19:
          self.dispatchFlow19Scapists(scapists)
          break;
        case RedcapFlowEnum.FLOW_20:
          self.dispatchFlow20Scapists(scapists)
          break;
        case RedcapFlowEnum.AUX_1:
          self.dispatchAux1Scapists(scapists)
          break;
        case RedcapFlowEnum.STATISTICS_1:
          self.dispatchStatistics1Scapists(scapists)
          break;
        case RedcapFlowEnum.STATISTICS_2:
          self.dispatchStatistics2Scapists(scapists)
          break;
      }
      self.dispatchLoading(false);
    }
  }

  redirectToRedcapByFlow(scapist: Scapist, flow: RedcapFlowEnum) {
    const params = new HttpParams().set('flow', flow?.toString());
    this.http.post(this.scapistUrl(null) + 'redirectToRedcapByFlow', scapist, {params}).subscribe((data: string) => {
      this.verifyTabOpen(window.open(data, 'same-tab'));
      this.getScapistsByFlowScapists(flow);
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  }
  redirectToRedcapIssue(issue: Issue) {
    const params = new HttpParams().set('id', issue.id);
    this.http.post(this.scapistUrl(null) + 'redirectToRedcapByIssue', null, {params}).subscribe((data: string) => {
      this.verifyTabOpen(window.open(data, 'same-tab'));
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  }

  redirectToRedcap(scapist: Scapist) {
    this.http.post(this.scapistUrl(null) + 'redirectToRedcap', scapist).subscribe((data: string) => {
      this.verifyTabOpen(window.open(data, 'same-tab'));
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  }

  getDayDiffMap(scapistList: WorkListScapistModel[]) {
    return this.getDayDiffMapGeneral(scapistList, 'id', 'transitionsToRelevantTimestamp')
  }

  getDayDiffMapGeneral(scapistList: any[], idField: string, dateField: string) {
    const dateMap: Map<number, number> = new Map<number, number>();
    scapistList.forEach(obj => {
      const today = new Date();
      const created = new Date(obj[dateField]);
      const timeDifference = Math.abs(today.getTime() - created.getTime());
      const difference = Math.floor(timeDifference / (1000 * 3600 * 24));
      dateMap.set(obj[idField], difference);
    });
    return dateMap;
  }

  private verifyTabOpen(newWin: WindowProxy) {
    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
      this.notificationService.sendErrorNotification('Kunde inte öppna ny flik. Vänligen verifiera att dina inställningar tillåter S2 att öppna popupfönster.');
    }
  }
}
