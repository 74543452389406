
<div class="progress-bar-container">
  <div class="bar">
    <div class="counter" [style.width.%]="ratio"><strong>{{currentValue || 0}}</strong></div>
  </div>
  <div class="goal"><strong>{{goalValue}}</strong></div>
</div>
<p class="rate">
  {{ratio | number: '1.00-0'}}%
</p>
