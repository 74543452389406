export enum EventType {
  INVITE = 'INVITE',
  ACCEPT = 'ACCEPT',
  UPDATE = 'UPDATE',
  ABSENT = 'ABSENT',
  ATTEMPTED_CALL = 'ATTEMPTED_CALL',
  REMOVED = 'REMOVED',
  LOGIN = 'LOGIN',
  REMINDER = 'REMINDER',
  BOOKING = 'BOOKING',
  CANCEL_VISIT = 'CANCEL_VISIT',
  ARRIVE = 'ARRIVE',
  REMOVE = 'REMOVE',
  TIME_OUT = 'TIME_OUT',
  UNDO = 'UNDO',
  RESET = 'RESET',
  PREVENTION = 'PREVENTION',
  NO_PREVENTION = 'NO_PREVENTION',
  REDCAP_REVIEW_LINK_CLICKED = 'REDCAP_REVIEW_LINK_CLICKED'
}

export const EventTypeDisplay = {
  [EventType.INVITE]: 'Inbjuden',
  [EventType.LOGIN]: 'Loggar in',
  [EventType.ACCEPT]: 'Acceptera',
  [EventType.REMINDER]: 'Påminnelse',
  [EventType.BOOKING]: 'Bokning',
  [EventType.CANCEL_VISIT]: 'Avboka besök',
  [EventType.UPDATE]: 'Uppdatera',
  [EventType.ARRIVE]: 'Ankomst',
  [EventType.ABSENT]: 'Uteblivit',
  [EventType.ATTEMPTED_CALL]: 'Uppringningsförsök',
  [EventType.REMOVE]: 'Borttagen',
  [EventType.TIME_OUT]: 'Time out',
  [EventType.UNDO]: 'Ångra',
  [EventType.RESET]: 'Återställ',
  [EventType.PREVENTION]: 'Prevention',
  [EventType.NO_PREVENTION]: 'Ingen prevention',
}
