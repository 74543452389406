<div mat-dialog-title class="dialog-title">
  <h3>Lägg till tider den {{date | date: "YYYY-MM-dd"}}</h3>
  <button mat-icon-button aria-label="Stäng bokning" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form.getRawValue())">
    <mat-form-field appearance="fill">
      <mat-label>Välj timme</mat-label>
      <mat-select id="hour" formControlName="hour">
        <mat-option value="6">6</mat-option>
        <mat-option value="7">7</mat-option>
        <mat-option value="8">8</mat-option>
        <mat-option value="9">9</mat-option>
        <mat-option value="10">10</mat-option>
        <mat-option value="11">11</mat-option>
        <mat-option value="12">12</mat-option>
        <mat-option value="13">13</mat-option>
        <mat-option value="14">14</mat-option>
        <mat-option value="15">15</mat-option>
        <mat-option value="16">16</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Välj minut</mat-label>
      <mat-select id="minute" formControlName="minute">
        <mat-option value="0">00</mat-option>
        <mat-option value="15">15</mat-option>
        <mat-option value="30">30</mat-option>
        <mat-option value="45">45</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label for="number">Hur många tider?</mat-label>
      <input type="number" matInput formControlName="number" id="number">
      <div *ngIf="isDisplayError('number') && (number.errors.min || number.errors.max || number.errors.pattern)">
        <div class="error-label">Måste vara ett heltal mellan 1 och 100</div>
      </div>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Välj besökstyp</mat-label>
      <mat-select id="day" formControlName="day">
        <mat-option value="START">{{TimeSlotTypeEnum.START | displayName: 'TimeSlotTypeEnum'}}</mat-option>
        <mat-option  *ngIf="site.hasContinuedStartVisit" value="CONTINUED_START">{{TimeSlotTypeEnum.CONTINUED_START | displayName: 'TimeSlotTypeEnum'}}</mat-option>
        <mat-option value="CT">{{TimeSlotTypeEnum.CT | displayName: 'TimeSlotTypeEnum'}}</mat-option>
        <mat-option value="PREVENTION">{{TimeSlotTypeEnum.PREVENTION | displayName: 'TimeSlotTypeEnum'}}</mat-option>
        <mat-option value="PREVENTION_FOLLOW_UP">{{TimeSlotTypeEnum.PREVENTION_FOLLOW_UP | displayName: 'TimeSlotTypeEnum'}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="form-group">
      <button color="primary" mat-raised-button [disabled]="!form.valid" type="submit">Skicka</button>
    </div>
  </form>
</mat-dialog-content>
