<div class="card" *ngIf="isPreventionssjukskoterska || isBorttagningsAnsvarig">
  <div class="card-body">
    <h3>Avbryt scapist</h3>
    <ul>
      <li *ngIf="isPreventionssjukskoterska">
        <strong>Rollen Preventionssjuksköterska:</strong> Möjlighet att ta bort en scapist som är inbokad på
        preventionsbesök.
      </li>
      <li *ngIf="isBorttagningsAnsvarig">
        <strong>Rollen Borttagningsansvarig:</strong> Möjlighet att ta bort scapist under hela studietiden.
      </li>
    </ul>
    <div *ngIf="isScapistDone">
      <p>Scapistens deltagande är färdigt och kan därför inte avbrytas.</p>
    </div>
    <div *ngIf="isScapistRemoved">
      <p>Scapistens deltagande är avbrutet. Om detta skedde av misstag kontakta scapis2&#64;scapis.org för att få hjälp.</p>
    </div>

    <div *ngIf="!isScapistRemoved && canAbort && !canAbortPrevention">
      <p>Avbryt Scapistens deltagande. Bokade framtida besök kommer att avbokas,
        ingen data i eCRF kommer att tas bort och Scapisten kommer finnas kvar på eventuella arbetslistor.</p>
      <confirm-modal-button [text]="dialogText()" [action]="removeScapist">
        <button
          id="abort-scapist-button"
          dialogButton
          type="button"
          mat-raised-button
          color="warn">Avbryt
        </button>
      </confirm-modal-button>
    </div>
    <div *ngIf="canAbortPrevention">
      <p>Avbryt scapistens deltagande i prevention. Bokade framtida besök kommer att avbokas,
        ingen data i eCRF kommer att tas bort och Scapisten kommer finnas kvar på eventuella arbetslistor.</p>
      <confirm-modal-button [text]="preventionDialogText()" [action]="abortPrevention">
        <button
          id="abort-scapist-button-prevention"
          dialogButton
          type="button"
          mat-raised-button
          color="warn">Avbryt prevention
        </button>
      </confirm-modal-button>
    </div>
  </div>
</div>
