import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

import {environment} from '@environments/environment';
import {NotificationService} from '@shared/services/notification.service';
import {SubStudy} from '@shared/models/sub-study.model';
import {StudyScapist} from '@shared/models/data-models/study-scapist.model';

@Injectable({providedIn: 'root'})
export class SubStudyService {
  private baseUrl: string;

  private subStudiesURL = '/sub-studies/';
  private dataStore: { subStudies: SubStudy[], scapists: StudyScapist[], loading: boolean } =
    {subStudies: [], scapists: [], loading: false};
  private _subStudies = new BehaviorSubject<SubStudy[]>([]);
  private _scapists = new BehaviorSubject<StudyScapist[]>([]);
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading = this._loading.asObservable();
  public readonly subStudies = this._subStudies.asObservable();
  public readonly scapists = this._scapists.asObservable();

  constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
    this.loadData();
  }


  public loadData() {
    this._loading.next(true);
    this.http.get<SubStudy[]>(this.baseUrl + this.subStudiesURL).subscribe(data => {
      const subStudies = [];
      for (const i in data) {
        subStudies.push(Object.assign(new SubStudy(), data[i]));
      }
      this.dataStore.subStudies = subStudies;
      this.dispatchSubStudys();
      this._loading.next(false);
    }, () => {
      this._loading.next(false);
      this.notificationService.updateNotification('error', 'Kunde inte ladda lokala substudier');
    });
  }

  private dispatchSubStudys() {
    this._subStudies.next(Object.assign({}, this.dataStore).subStudies);
  }

  create(data) {
    const formData = this.preparePostData(data);
    this._loading.next(true);
    this.http.post(this.baseUrl + this.subStudiesURL, formData).subscribe(
      (response: SubStudy) => {
        this.notificationService.sendSuccessNotification(`Lokal substudie inläst med ${response.numberOfScapists} scapister`)
        this.loadData();
      }, (error) => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte skapa studie');
      }
    )

  }

  delete(study: SubStudy) {
    this._loading.next(true);
    this.http.delete(this.baseUrl + this.subStudiesURL + study.id).subscribe(
      (response) => {
        this.notificationService.sendSuccessNotification(`Tog bort ${study.id} - ${study.title}`)
        this.loadData();
      }, (error) => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte ta bort studien');
      }
    )
  }

  getSubStudyById(id: number): SubStudy {
     return this.dataStore.subStudies.find(s => {
      return s.id === id
    });
  }

  update(id: number, data: SubStudy) {
    const formData = this.preparePostData(data);
    this._loading.next(true);
    this.http.post(this.baseUrl + this.subStudiesURL + id, formData).subscribe(
      (response: SubStudy) => {
        this.notificationService.sendSuccessNotification(`Lokal substudie uppdaterad med ${response.numberOfScapists} scapister`)
        this.loadData();
      }, (error) => {
        this._loading.next(false);
        this.notificationService.sendErrorNotification('Kunde inte uppdatera studien');
      }
    )
  }

  private preparePostData(data: SubStudy): FormData {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }

  loadScapists(id: number) {
    this.http.get(this.baseUrl + this.subStudiesURL + id + '/non-managed-scapists').subscribe((data: StudyScapist[]) => {
      this.dispatchScapists(data);
    },(error) => {
      this.notificationService.sendErrorNotification('Kunde inte hämta scapister');
    })
  }

  discardScapists() {
    this.dispatchScapists([]);
  }

  private dispatchScapists(scapists: StudyScapist[]) {
    this.dataStore.scapists = scapists;
    this._scapists.next(Object.assign({}, this.dataStore).scapists);
  }

  manage(scapistId: number, studyId: number) {
    console.log(scapistId, studyId);
    this.http.post(this.baseUrl + this.subStudiesURL + studyId + '/manage-scapist/' + scapistId, {}).subscribe((data: StudyScapist) => {
      this.loadScapists(studyId);
      this.notificationService.sendSuccessNotification('Scapisten markerad som hanterad.');
    }, () => {
      this.notificationService.sendErrorNotification('Kunde inte markera scapisten som hanterad');
    })
  }
}
