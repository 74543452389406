import {LoginComponent} from './login/login.component';
import {NgModule} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccountComponent} from './account/account.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {RequestNewPasswordComponent} from '@modules/auth/request-new-password/request-new-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {VerifyPasswordTokenComponent} from './verify-password-token/verify-password-token.component';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TermsComponent} from './terms/terms.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PortalCommonModule} from '@modules/common/portal-common.module';
import {QrCodeComponent} from '@modules/auth/qr-code/qr-code.component';
import {CodePageComponent} from '@modules/auth/code-page/code-page.component';
import {EnablePageComponent} from './enable-page/enable-page.component';
import {StartPageComponent} from './start-page/start-page.component';
import {MatIconModule} from '@angular/material/icon';

const declarations = [
  LoginComponent,
  QrCodeComponent,
  CodePageComponent,
  AccountComponent,
  RequestNewPasswordComponent,
  ResetPasswordComponent,
  VerifyPasswordTokenComponent,
  UpdatePasswordComponent,
  TermsComponent,
  EnablePageComponent,
  StartPageComponent
];

@NgModule({
  declarations,
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    PortalCommonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  exports: declarations,
  providers: [
    AuthenticationService
  ]
})
export class AuthModule {}
