import { Component, OnInit } from '@angular/core';
import {StatisticsService} from '@shared/services/statistics.service';
import {share} from 'rxjs/operators';
import {AuthenticationService} from '@shared/services/authentication.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';

@Component({
  selector: 'app-inclusion-stats-page',
  templateUrl: './inclusion-stats-page.component.html',
  styleUrls: ['./inclusion-stats-page.component.scss']
})
export class InclusionStatsPageComponent implements OnInit {


  inclusionRate: Observable<StatisticsModel>;
  inclusionRatePerWeek: Observable<StatisticsModel>;
  timeSlotDistributionStart: Observable<StatisticsModel>;
  timeSlotDistributionCt: Observable<StatisticsModel>;
  timeSlotDistributionStartContinue: Observable<StatisticsModel>;
  timeSlotDistributionPrevention: Observable<StatisticsModel>;
  timeSlotDistributionFollowUp: Observable<StatisticsModel>;
  inclusionGoal: number;
  currentInclusion = 0;
  form: FormGroup;

  constructor(private statisticsService: StatisticsService, private authenticationService: AuthenticationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const form = {
      from: new FormControl(null, Validators.required),
      to: new FormControl(null, Validators.required)
    }
    this.form = this.formBuilder.group(form);

    this.form.valueChanges.subscribe((values) => {
      this.setDateRange(values);
    })

    this.inclusionRate = this.statisticsService.inclusionRate.pipe(share());
    this.inclusionRatePerWeek = this.statisticsService.inclusionRatePerWeek.pipe(share());
    this.timeSlotDistributionStart = this.statisticsService.timeSlotDistributionStart.pipe(share());
    this.timeSlotDistributionCt = this.statisticsService.timeSlotDistributionCt.pipe(share());
    this.timeSlotDistributionStartContinue = this.statisticsService.timeSlotDistributionStartContinue.pipe(share());
    this.timeSlotDistributionPrevention = this.statisticsService.timeSlotDistributionPrevention.pipe(share());
    this.timeSlotDistributionFollowUp = this.statisticsService.timeSlotDistributionFollowUp.pipe(share());

    if(this.authenticationService.getUser().site) {
      this.inclusionGoal = this.authenticationService.getUser().site.recruitmentTarget;
    }
    this.statisticsService.inclusionRate.subscribe(value => {
      if (value && value.average) {
        this.inclusionGoal = value.average;
      }
      if(value && value.data && value.data && value.data.length > 0) {
        this.currentInclusion = value.data[value.data.length - 1].values[1];
      } else {
        this.currentInclusion = 0;
      }
    });
  }

  private setDateRange(values: any) {
    this.statisticsService.setDateRange(values.from, values.to);
    this.statisticsService.getTimeSlotDistributionStart();
    this.statisticsService.getTimeSlotDistributionStartContinue();
    this.statisticsService.getTimeSlotDistributionCt();
    this.statisticsService.getTimeSlotDistributionPrevention();
    this.statisticsService.getTimeSlotDistributionFollowUp();
  }

  clearFilter() {
    this.setDateRange({});
  }
}
