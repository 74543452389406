<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h1>Deltagare</h1>
      <form [formGroup]="form">
        <div class="form-group">
          <label for="searchTerm">Sök på namn, epost, mobilnummer eller personnummer. Börja skriva för att söka</label>
          <input type="text" id="searchTerm" formControlName="searchTerm" class="form-control">
          <p *ngIf="showMinLengthHint">Skriv minst tre tecken för att söka</p>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h2 [innerHTML]="getTitle(loading | async)"></h2>

      <div *ngIf="scapists | async as loadedScapists">

        <ng-container *ngIf="loadedScapists.length == 0; then noPersonsInCallList; else showTable"></ng-container>
        <ng-template #noPersonsInCallList>
          <p> Finns inga personer som matchar din sökning</p>
        </ng-template>
        <ng-template #showTable>
          <div *ngIf="loadedScapists.length > 0">
            <mat-table [dataSource]="loadedScapists" multiTemplateDataRows>

              <ng-container matColumnDef="name" >
                <th mat-header-cell *matHeaderCellDef id="name">Namn</th>
                <td mat-cell *matCellDef="let column"> {{column.firstName}}  {{column.lastName}} </td>
              </ng-container>

              <ng-container matColumnDef="personNumber"  >
                <th mat-header-cell *matHeaderCellDef id="personNumber">Personnummer</th>
                <td mat-cell *matCellDef="let column">{{column.personNumber | personNumber}}</td>
              </ng-container>

              <ng-container matColumnDef="email"  >
                <th mat-header-cell *matHeaderCellDef id="email">E-post</th>
                <td mat-cell *matCellDef="let column">{{column.email}} </td>
              </ng-container>

              <ng-container matColumnDef="status"  >
                <th mat-header-cell *matHeaderCellDef id="status">Scapist status</th>
                <td mat-cell *matCellDef="let column">{{column.status | displayName: 'ScapistStatus'}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                class="element-row"
                [class.expanded-row]="expandedElement === row"
                (click)="redirectToBookingPage(row.id)"></tr>

            </mat-table>

            <mat-paginator
              (page)="handlePageEvent($event)"
              [length]=pageInfo.total
              [pageIndex]= pageInfo.pageIndex
              [pageSize]= pageInfo.pageSize
              [pageSizeOptions]="pageSizeOptions"
              aria-label="Select page">
            </mat-paginator>
          </div>

        </ng-template>

      </div>
    </div>
  </div>
</div>
