import {Injectable} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {BehaviorSubject} from 'rxjs';
import {NotificationService} from '@shared/services/notification.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {PageModel} from '@shared/models/data-models/page.model';

@Injectable({
  providedIn: 'root'
})
export abstract class CallListService {

  protected readonly baseUrl: string;

  protected siteScapistURL = '/site-scapist/';
  protected dataStore: {
    scapists: Scapist[],
    loading: boolean,
    currentScapist,
    pageIndex: number,
    pageSize: number,
    total: number,
    sortBy: string,
    sortOrder: string
  } = {
    scapists: [],
    loading: false,
    currentScapist: null,
    pageIndex: 0,
    pageSize: 10,
    total: undefined,
    sortBy: 'accepted',
    sortOrder: 'DESC'
  };

  protected _loading = new BehaviorSubject<boolean>(false);
  protected _scapists = new BehaviorSubject<Scapist[]>([]);
  protected _page = new BehaviorSubject<number>(0);
  public readonly loading = this._loading.asObservable();
  public readonly scapists = this._scapists.asObservable();
  public readonly page = this._page.asObservable();



  protected constructor(private http: HttpClient, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }


  protected abstract getScapistUrl(): string;

  public getScapists() {
    this.dispatchLoading(true);
    this.http.get<PageModel>(this.scapistUrl(null) + this.getScapistUrl() + this.getParams()).subscribe(data => {
      const scapists = [];
      data.content.forEach(item => {
        scapists.push(Object.assign(new Scapist(), item));
      });
      this.dataStore.total = data.totalNumberOfElements;
      this.dataStore.pageSize = data.pageSize;
      this.dataStore.pageIndex = data.page;
      this.dispatchScapists(scapists);
      this.dispatchLoading(false);
    }, () => {
      this.dispatchLoading(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in scapister');
    });
  }

  public setSorting(sortBy: string, sortOrder: string) {
    this.dataStore.sortBy = sortBy;
    this.dataStore.sortOrder = sortOrder || 'DESC';
  }

  public getPaginationInfo() {
    return {
      pageIndex: this.dataStore.pageIndex,
      pageSize: this.dataStore.pageSize,
      total: this.dataStore.total,
      sortBy: this.dataStore.sortBy,
      sortOrder: this.dataStore.sortOrder
    };
  }

  loadPage(pageIndex: number, pageSize: number) {
    this.dataStore.pageIndex = pageIndex;
    this.dataStore.pageSize = pageSize;
    this.getScapists();
  }

  private dispatchLoading(loading: boolean) {
    this.dataStore.loading = loading;
    this._loading.next(this.dataStore.loading);
  }

  private dispatchScapists(scapists: Scapist[]) {
    this.dataStore.scapists = scapists;
    this._scapists.next(Object.assign({}, this.dataStore).scapists);
  }

  private getParams(): string {
    const params = [`page=${this.dataStore.pageIndex}`,`pageSize=${this.dataStore.pageSize}`, `sortBy=${this.dataStore.sortBy}`, `sortOrder=${this.dataStore.sortOrder.toUpperCase()}`];

    return `?${params.join('&')}`;
  }

  private scapistUrl(id: number): string {
    return `${this.baseUrl}${this.siteScapistURL}${id || ''}`;
  }

  public sendCallAttempt(scapist: Scapist) {
    this.http.post(this.scapistUrl(null) + 'call-attempt', scapist).subscribe(() => {
      this.getScapists();
    }, error => {
      this.notificationService.sendErrorNotification(error);
    });
  }
}
