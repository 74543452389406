import { Component, OnInit } from '@angular/core';
import {InvitationService} from '@shared/services/entity-services/invitation.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {share} from 'rxjs/operators';
import {InvitationBasis} from '@shared/models/invitation-basis.model.ts';
import {NotificationService} from '@shared/services/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-invitation-form',
  templateUrl: './invitation-form.component.html',
  styleUrls: ['./invitation-form.component.scss']
})
export class InvitationFormComponent implements OnInit {

  $info: Observable<InvitationBasis>
  info: InvitationBasis;
  form: FormGroup;
  MAX_INVITEES = 500;
  maxInvitees = this.MAX_INVITEES;
  invitees: number;
  estimatedInvited = 0;
  progress = 100;
  isLoading = false;
  progressInterval: ReturnType<typeof setInterval>;
  buffer = 10;

  constructor(private invitationsService: InvitationService, private formBuilder: FormBuilder, public notificationService: NotificationService) {
    this.$info = this.invitationsService.invitationBasis.pipe(share());
  }

  ngOnInit(): void {
    this.$info.subscribe(data => {
      if (this.invitees > 0) {
        if (data.invitedScapists === this.info.invitedScapists + this.invitees) {
          this.notificationService.sendSuccessNotification(`Skapade inbjudan för ${this.invitees} scapister`);
        }
        else {
          this.notificationService.sendErrorNotification('Någonting verkar ha gått fel. Ladda om sidan.');
        }
        this.invitees = 0;
      }
      this.info = data;
      this.maxInvitees = data.notInvitedScapists > this.MAX_INVITEES ? this.MAX_INVITEES : data.notInvitedScapists;
      this.form = this.setUpForm();
    });
  }

  setUpForm(): FormGroup {
    const config = {
      number: new FormControl(null, [
        Validators.min(1),
        Validators.max(this.maxInvitees),
        Validators.pattern(/^[0-9]+$/),
        Validators.required
      ])
    };
    return this.formBuilder.group(config);
  }

  errorMessage() {
    const formControl = this.form.get('number');
    if (!this.form.dirty) {
      return null;
    }
    return formControl.hasError('required') ?
     'Obligatoriskt fält' :
     formControl.hasError('max') ?
     `Max antal scapister: ${this.maxInvitees}` :
     formControl.hasError('min') ?
     'Minsta antal scapister: 1' :
     formControl.hasError('pattern') ?
     'Får endast vara siffror' : '';
  }

  onSubmit(value) {
    if(this.form.valid) {
      this.isLoading = true;
      this.progress = 0;
      this.estimatedInvited = 0;
      this.notificationService.updateNotification('info', 'Skapar inbjudan');
      this.invitationsService.create(value);
      this.invitees = value.number;
      this.form.reset();
      Object.keys(this.form.controls).forEach(key => {
        this.form.get(key).setErrors(null);
      });

      this.progressInterval = setInterval(() => this.estimateProgress(), 500);
    }
  }

  estimateProgress() {
    this.progress = (this.estimatedInvited / (this.invitees + this.buffer)) * 100;
    this.estimatedInvited++;
    if (this.progress === 100) {
      this.isLoading = false;
      clearInterval(this.progressInterval);
      this.invitationsService.loadData();
    }
    else {
      this.isLoading = true;
    }
  }

}
