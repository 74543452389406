import {Component, Input} from '@angular/core';
import {InvitationBasis} from '@shared/models/invitation-basis.model.ts';

@Component({
  selector: 'app-invitation-basis-info',
  templateUrl: './invitation-basis-info.component.html',
  styleUrls: ['./invitation-basis-info.component.scss']
})
export class InvitationBasisInfoComponent {

  @Input()
  info: InvitationBasis;

}
