import {FormGroup} from "@angular/forms";


export function ValidateMatchPassword(form: FormGroup) {

  const passwordControl = form.get('password') ? form.get('password') : form.get('newPassword');
  const confirmPasswordControl = form.get('repeatPassword');

  if (passwordControl.value !== confirmPasswordControl.value) {
    confirmPasswordControl.setErrors({
      passwordMismatch: true,
      passwordMismatchMessage: 'Lösenorden stämmer inte överens.'
    });
  } else {
    confirmPasswordControl.setErrors(null);
  }
}
