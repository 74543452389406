import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '@shared/services/notification.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UpdatePasswordBean} from '@shared/models/update-password-bean.model';
import {ValidatePassword} from "@shared/validators/password.validator";
import {FormUtils} from "@shared/utils/form.utils";
import {ValidateMatchPassword} from "@shared/validators/match-password.validator";

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent {

  private updateUrl: string;

  updatePasswordForm: FormGroup;

  constructor(private notificationService: NotificationService, private httpClient: HttpClient,
              private formBuilder: FormBuilder, private authenticationService: AuthenticationService) {
    this.updateUrl = environment.baseUrl + '/password/update';
    this.updatePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8),
        ValidatePassword.patternValidator(ValidatePassword.UPPERCASE_REGEX, {requiresUppercase: true}),
        ValidatePassword.patternValidator(ValidatePassword.LOWERCASE_REGEX, {requiresLowercase: true}),
        ValidatePassword.patternValidator(ValidatePassword.DIGIT_REGEX, {requiresDigit: true}),
        ValidatePassword.patternValidator(ValidatePassword.SPECIAL_CHAR_REGEX, {requiresSpecialChars: true})
      ]),
      repeatPassword: new FormControl('', [Validators.required])
    }, {validators: ValidateMatchPassword});
  }


  updatePassword(formData) {
    this.authenticationService.updatePassword(new UpdatePasswordBean(
      formData.currentPassword,
      formData.newPassword,
      formData.repeatPassword
    ));
  }

  isDisplayError(formProperty: string) {
    return FormUtils.isDisplayError(this.updatePasswordForm, formProperty);
  }


  get currentPassword() {
    return this.updatePasswordForm.get('currentPassword');
  }

  get newPassword() {
    return this.updatePasswordForm.get('newPassword');
  }

  get repeatPassword() {
    return this.updatePasswordForm.get('repeatPassword');
  }

  get requiredValid() {
    return !this.newPassword.hasError("required");
  }

  get minLengthValid() {
    return !this.newPassword.hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.newPassword.hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.newPassword.hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.newPassword.hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.newPassword.hasError("requiresSpecialChars");
  }
}
