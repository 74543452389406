import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// Standard routing and app compponent stuff
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app/app.component';
// Http module for API calls
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
// SCAPIS custom components
import {NavigationModule} from './navigation/navigation.module';
import {UtilsModule} from '@shared/utils/utils.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthModule} from './auth/auth.module';
import {HttpRequestInterceptor} from '@shared/utils/http-request-interceptor';
import * as Sentry from '@sentry/browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {PortalCommonModule} from '@modules/common/portal-common.module';
import {ScapistsModule} from '@modules/scapists/scapists.module';
import {InvitationsModule} from '@modules/invitations/invitations.module';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {UserListComponent} from './users/user-list/user-list.component';
import {UserRoleFormComponent} from './users/user-role-form/user-role-form.component';
import {MatSelectModule} from '@angular/material/select';
import {ViewUserComponent} from '@modules/users/view-user/view-user.component';
import {MatTableModule} from '@angular/material/table';
import {DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter} from '@angular/material/core';
import {WorkListComponent} from '@modules/users/work-list/work-list.component';
import {CreateUserModalComponent} from './users/create-user-modal/create-user-modal.component';
import {HeaderComponent} from './header/header.component';
import {MatIconModule} from '@angular/material/icon';
import {FlowListComponent} from '@modules/users/work-list/flow-list/flow-list.component';
import {StatisticsModule} from './statistics/statistics.module';
import {registerLocaleData} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import localeSv from '@angular/common/locales/sv';
import {LoaderInterceptor} from '@shared/utils/loader.interceptor';
import {LoaderService} from '@shared/utils/loader.service';
import {MatSortModule} from '@angular/material/sort';
import {GroupTimeSlotsPipe} from '@shared/utils/group-time-slots.pipe';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {VersionService} from '@shared/utils/version.service';
import {VersionInterceptor} from '@shared/utils/version-interceptor';
import {IssuesModule} from "@modules/issues/issues.module";
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { TimeSlotsModule } from './time-slots/time-slots.module';
import { SubStudiesModule } from './sub-studies/sub-studies.module';
import { SiteModule } from './site/site.module';

Sentry.init({
  dsn: 'https://4249fbd340524a0abb9fd543ec2f426e@sentry.io/1895009'
});

registerLocaleData(localeSv);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.log('error', error);
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}

export const DateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD']
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    UserListComponent,
    ViewUserComponent,
    UserRoleFormComponent,
    CreateUserModalComponent,
    UserRoleFormComponent,
    WorkListComponent,
    FlowListComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NavigationModule,
    ScapistsModule,
    InvitationsModule,
    UtilsModule,
    AppRoutingModule,
    HttpClientModule, // This should be in a service I think
    AuthModule,
    MatCheckboxModule,
    FormsModule,
    NgbModule,
    MatButtonModule,
    ReactiveFormsModule,
    PortalCommonModule,
    MatDialogModule,
    MatSelectModule,
    MatTableModule,
    HeaderComponent,
    MatIconModule,
    StatisticsModule,
    TimeSlotsModule,
    SubStudiesModule,
    SiteModule,
    MatSortModule,
    MatProgressBarModule,
    IssuesModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    [
      LoaderService,
      VersionService,
      GroupTimeSlotsPipe,
      MatDialog,
      { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      { provide: ErrorHandler, useClass: SentryErrorHandler },
      { provide: MAT_DATE_FORMATS, useValue: DateFormats },
      {
        provide: DateAdapter,
        useClass: class CustomDateAdapter extends NativeDateAdapter {
          getFirstDayOfWeek(): number {
              return 1;
          }
        }
      },
      { provide: LOCALE_ID, useValue: 'sv-SE' }
    ]
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
