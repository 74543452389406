<div class="container">
  <h3>Aktivera konto</h3>
  <p>Fyll i följande information för att aktivera ditt S2 konto.</p>
  <div class="content">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
      <div class="form-group">
        <label for="firstName">Förnamn:</label> <input class="form-control" type="text" id="firstName"
                                                       formControlName="firstName">
        <div *ngIf="isDisplayError('firstName') && firstName.errors.required">
          <div class="error-label">Får inte vara tomt</div>
        </div>
      </div>

      <div class="form-group">
        <label for="lastName">Efternamn:</label> <input class="form-control" type="text" id="lastName" formControlName="lastName">
        <div *ngIf="isDisplayError('lastName') && lastName.errors.required">
          <div class="error-label">Får inte vara tomt</div>
        </div>
      </div>

      <div class="form-group">
        <label for="password">Lösenord:</label> <input class="form-control" type="password" id="password" formControlName="password">
        <div *ngIf="isDisplayError('password') && password.errors.required">
          <div class="error-label">Får inte vara tomt</div>
        </div>

        <div *ngIf="requiredValid">
          <div [ngClass]="{ 'password-validation-success': minLengthValid, 'password-validation-error': !minLengthValid }">
            <div class="content-title-icon">{{minLengthValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste vara minst 8 tecken långt
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresDigitValid, 'password-validation-error': !requiresDigitValid }">
            <div class="content-title-icon">{{requiresDigitValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst en siffra
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresUppercaseValid, 'password-validation-error': !requiresUppercaseValid }">
            <div class="content-title-icon">{{requiresUppercaseValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst en stor bokstav
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresLowercaseValid, 'password-validation-error': !requiresLowercaseValid }">
            <div class="content-title-icon">{{requiresLowercaseValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst en liten bokstav
          </div>

          <div [ngClass]="{ 'password-validation-success': requiresSpecialCharsValid, 'password-validation-error': !requiresSpecialCharsValid }">
            <div class="content-title-icon">{{requiresSpecialCharsValid ? "&#xF046;" : "&#xF096;"}}</div>
            Lösenordet måste ha minst ett specialtecken
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="repeatPassword">Upprepa lösenord: </label> <input class="form-control" type="password" id="repeatPassword"
                                                                      formControlName="repeatPassword">
        <div *ngIf="isDisplayError('repeatPassword') && repeatPassword.errors.required">
          <div class="error-label">Får inte vara tomt</div>
        </div>
        <div *ngIf="isDisplayError('repeatPassword') && repeatPassword.errors.passwordMismatch">
          <div class="error-label">{{ repeatPassword.getError('passwordMismatchMessage') }}</div>
        </div>
      </div>


      <br/>
      <button mat-stroked-button [disabled]="!form.valid" type="submit">Skicka</button>
    </form>
  </div>
</div>
