<ng-container *ngIf="interestedScapistList.length === 0; then noPersonsInCallList; else showTable"></ng-container>
<ng-template #noPersonsInCallList>
  <div class="card">
    <div class="card-body">
      <h4>Finns inga scapister som är markerade för prevention</h4>
      <p>En lista över scapister som kommer bli kontaktade angående preventionsbesök </p>
    </div>
  </div>
</ng-template>
<ng-template #showTable>
  <div class="card">
    <div class="card-body">
      <h4>Scapister som är markerade för prevention</h4>
      <p>
       Lista över scapister som kommer bli kontaktade angående preventionsbesök.
      </p>
      <mat-table [dataSource]="interestedScapistList" matSort matSortActive="accepted" matSortDirection="asc"
                 (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName" sortActionDescription="Sortera efter namn"
              id="name">Namn
          </th>
          <td mat-cell *matCellDef="let column"> {{column.firstName}}  {{column.lastName}} </td>
        </ng-container>

        <ng-container matColumnDef="personNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="personNumber"
              sortActionDescription="Sortera efter personnummer" id="personNumber">Personnummer
          </th>
          <td mat-cell *matCellDef="let column">{{column.personNumber | personNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="phone"
              sortActionDescription="Sortera efter telefonnummer" id="phone">Telefonnummer
          </th>
          <td mat-cell *matCellDef="let column">{{column.phone}}</td>
        </ng-container>

        <ng-container matColumnDef="accepted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="accepted" sortActionDescription="Sortera efter datum"
              id="accepted">Anmälde intresse den
          </th>
          <td mat-cell *matCellDef="let column">{{column.accepted | date:'yyyy-MM-dd HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="attempts">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="callAttempts"
              sortActionDescription="Sortera efter antal försök" id="attempts"> Antal försök att nå scapist
          </th>callAttempts
          <td mat-cell *matCellDef="let column">
            <div *ngIf="column.callAttempts > 0">
              <strong>{{column.callAttempts}}</strong> {{column.callAttempts === 1 ? 'gång' : 'gånger'}}
            </div>
            <div *ngIf="column.callAttempts === 0">
              Inga försök
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="latestAttempt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="latestCallAttempt"
              sortActionDescription="Sortera efter senast gjort kontaktförsök" id="latestAttempt"> Senast gjort
            kontaktförsök
          </th>
          <td mat-cell *matCellDef="let column">
            <div *ngIf="column.latestCallAttempt">
              {{column.latestCallAttempt | date:'yyyy-MM-dd HH:mm'}}
            </div>
            <div *ngIf="!column.latestCallAttempt">
              Inga försök
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="callAttempt">
          <th mat-header-cell *matHeaderCellDef> Markera som uppringd utan svar</th>
          <td mat-cell *matCellDef="let row" class="button-cell" (click)="$event.stopPropagation()">
            <div class="button-container">
              <button mat-stroked-button class="text-success" type="submit"
                      (click)="$event.stopPropagation();sendCallAttemptEvent(row, 1)"> Uppringd
              </button>
              <button mat-stroked-button type="submit" color="warn"
                      (click)="$event.stopPropagation();sendCallAttemptEvent(row ,-1)"> Ångra
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            id="{{row.id}}"
            (click)="this.listFunctionService.redirectToBookingPage(row.id)"
        ></tr>
      </mat-table>
      <mat-paginator
        (page)="handlePageEvent($event)"
        [length]=pageInfo.total
        [pageIndex]=pageInfo.pageIndex
        [pageSize]=pageInfo.pageSize
        [pageSizeOptions]="pageSizeOptions"
        aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</ng-template>
