import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NotificationService} from '@shared/services/notification.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {VisitListDay} from '@shared/models/data-models/visit-list-day.model';
import {TimeSlot} from '@shared/models/time-slot.model';
import {BookingStatusEnum} from '@shared/constants/booking-status.enum';
import {VisitListResponse} from '@shared/models/data-models/visit-list-response.model';
import {Booking} from '@shared/models/data-models/booking.model';
import {TimeSlotTypeEnum} from '@shared/constants/time-slot-type.enum';
import {FreeBook} from '@shared/models/free-book.model';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class VisitListService {
  private readonly baseUrl: string;
  private siteScapistURL = '/time-slot/visit-list';
  private pastSlotsURL = '/time-slot/unmarked-past-visits';
  private dataStore: {
    timeSlots: VisitListDay[],
    pastTimeSlots: VisitListDay[],
    loading: boolean
  } = {
    timeSlots: [],
    pastTimeSlots: [],
    loading: false
  };

  private _loading = new BehaviorSubject<boolean>(false);
  private _timeSlots = new BehaviorSubject<VisitListDay[]>([]);
  private _pastTimeSlots = new BehaviorSubject<VisitListDay[]>([]);
  public readonly loading = this._loading.asObservable();
  public readonly timeSlots = this._timeSlots.asObservable();
  public readonly pastTimeSlots = this._pastTimeSlots.asObservable();

  constructor(private http: HttpClient, private datePipe: DatePipe, private notificationService: NotificationService) {
    this.baseUrl = environment.baseUrl;
  }

  loadData(from: Date, to: Date) {
    this.dispatchLoading(true);
    this.http.get<VisitListResponse>(this.visitListUrl() + this.getParams(from, to)).subscribe(data => {
      const days = this.sortVisitListResponse(data);

      this.dispatchTimeSlots(this.sortDays(days));
      this.dispatchLoading(false);
    }, () => {
      this.dispatchLoading(false);
      this.notificationService.updateNotification('error', 'Lyckades inte ladda in tider');
    });
  }

  loadPastTimeSlots() {
    this.dispatchLoading(true);
    this.http.get<VisitListResponse>(this.pastSlotListUrl()).subscribe(data => {
      const days = this.sortVisitListResponse(data);

      this.dispatchPastTimeSlots(this.sortDays(days));
      this.dispatchLoading(false);
    }, () => {
      this.dispatchLoading(false);
      this.notificationService.updateNotification('error', 'Lyckades inte ladda in tider');
    });
  }

  sortVisitListResponse(data: VisitListResponse): Map<Date, VisitListDay> {
    const days: Map<Date, VisitListDay> = new Map<Date, VisitListDay>();
    for (const visit of data.timeSlots) {

      const date = new Date(visit.time).toDateString()
      if(!days[date]) {
        days[date] = Object.assign(new VisitListDay(), {
          date: visit.time,
          timeSlots: []
        });
      }
      days[date].timeSlots.push(Object.assign(new TimeSlot(), visit));
    }
    for (const visit of data.freeBooks) {
      const date = new Date(visit.time).toDateString()
      if(!days[date]) {
        days[date] = Object.assign(new VisitListDay(), {
          date: visit.time,
          timeSlots: []
        });
      }
      days[date].timeSlots.push(Object.assign(new FreeBook(), visit));
    }
    return days;
  }

  attend(slot: TimeSlot, callback: () => void) {
    if(!slot.scapist || slot.status !== BookingStatusEnum.BOOKED) {
      return;
    }
    this.http.post(this.attendUrl(slot), {}).subscribe(callback,
      error => {
        this.notificationService.updateNotification('error', error.error || 'Misslyckades');
      });
  }

  reset(slot: Booking, callback: () => void) {
    if(!slot.scapist || slot.status === BookingStatusEnum.BOOKED) {
      return;
    }
    this.http.post(this.resetUrl(slot), {}).subscribe(callback,
      error => {
        this.notificationService.updateNotification('error', error.error || 'Misslyckades');
      });
  }

  absent(slot: Booking, callback: () => void) {
    if(!slot.scapist || slot.status !== BookingStatusEnum.BOOKED) {
      return;
    }
    this.http.post(this.absentUrl(slot), {}).subscribe(callback,
      error => {
        this.notificationService.updateNotification('error', error.error || 'Misslyckades');
      });
  }

  private sortDays(input: Map<Date, VisitListDay>): VisitListDay[] {
    return Object.values(input).sort((a: VisitListDay, b: VisitListDay) => {
      if (a.date > b.date) {
        return 1;
      }
      if (a.date < b.date) {
        return -1;
      }
      return 0;
    });
  }

  private dispatchLoading(loading: boolean) {
    this.dataStore.loading = loading;
    this._loading.next(this.dataStore.loading);
  }

  private dispatchTimeSlots(timeSlots: VisitListDay[]) {
    this.dataStore.timeSlots = timeSlots;
    this._timeSlots.next(Object.assign({}, this.dataStore).timeSlots);
  }

  private dispatchPastTimeSlots(timeSlots: VisitListDay[]) {
    this.dataStore.pastTimeSlots = timeSlots;
    this._pastTimeSlots.next(Object.assign({}, this.dataStore).pastTimeSlots);
  }

  private getParams(from, to): string {
    const params = [
      `from=${this.datePipe.transform(from, 'YYYY-MM-dd')}`,
      `to=${this.datePipe.transform(to, 'YYYY-MM-dd')}`
    ];

    return `?${params.join('&')}`;
  }

  private visitListUrl(): string {
    return `${this.baseUrl}${this.siteScapistURL}`;
  }

  private pastSlotListUrl() {
    return `${this.baseUrl}${this.pastSlotsURL}`;
  }

  private attendUrl(slot: Booking): string {
    const slug = this.getSlug(slot);
    return `${this.baseUrl}/${slug}/${slot.id}/attend/${slot.scapist.id}`;
  }
  private absentUrl(slot: Booking): string {
    const slug = this.getSlug(slot);
    return `${this.baseUrl}/${slug}/${slot.id}/absent/${slot.scapist.id}`;
  }

  private resetUrl(slot: Booking): string {
    const slug = this.getSlug(slot);
    return `${this.baseUrl}/${slug}/${slot.id}/reset/${slot.scapist.id}`;
  }

  private getSlug(slot: Booking): string {
    let slug = 'time-slot';

    if(slot.timeSlotType === TimeSlotTypeEnum.FREE_BOOK) {
      slug = 'free-book';
    }
    return slug;
  }


}
