import {Pipe, PipeTransform} from '@angular/core';
import {TimeSlot} from '@shared/models/time-slot.model';
import {TimeSlotUtils} from '@shared/utils/time-slot.utils';

@Pipe({
  name: 'visitType'
})
export class VisitTypeFormatterPipe implements PipeTransform {

  transform(status: string): string {
    if(!status) {
      return status;
    }
    switch (status) {
        case 'RETAKE': return 'Omprov';
        case 'LS': return 'Substudie';

    }
  }

}
