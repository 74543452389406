import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubStudiesPageComponent } from './sub-studies-page/sub-studies-page.component';
import { SubStudiesListComponent } from './sub-studies-list/sub-studies-list.component';
import { SubStudyPageComponent } from './sub-study-page/sub-study-page.component';
import { SubStudyFormComponent } from './sub-study-form/sub-study-form.component';
import {UtilsModule} from "@shared/utils/utils.module";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {RouterLinkWithHref} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import { SubStudyScapistListComponent } from './sub-study-scapist-list/sub-study-scapist-list.component';
import {MatSortModule} from "@angular/material/sort";
import {PortalCommonModule} from "@modules/common/portal-common.module";



@NgModule({
  declarations: [
    SubStudiesPageComponent,
    SubStudiesListComponent,
    SubStudyPageComponent,
    SubStudyFormComponent,
    SubStudyScapistListComponent
  ],
  exports: [
    SubStudiesListComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    MatTableModule,
    MatButtonModule,
    RouterLinkWithHref,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatSortModule,
    PortalCommonModule
  ]
})
export class SubStudiesModule { }
