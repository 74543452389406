<p *ngIf="!invitations || invitations.length == 0">Det finns ännu inga inbjudningar.</p>
<div *ngIf="invitations && invitations.length > 0">
  <h3>Tidigare Rekryteringar</h3>
  <app-expandable-table [columns]="columns" [data]="invitations" [detailRow]="detailRow">
  </app-expandable-table>
  <ng-template #detailRow let-row="row">
    <div class="row">
      <div class="col-md-9">
        <h4>Sammanfattning</h4>
      </div>
      <div class="col-md-3" *ngIf="row.total > row.interested">
        <h4><button mat-raised-button [disabled]="isNotTimeForReminder(row)" (click)="getReminderAddresses(row)">Förbered för påminnelse</button></h4>

      </div>
    </div>
      <table>
        <tr>
          <th>Antal</th>
          <td>{{ row.total }}</td>
        </tr>
        <tr>
          <th>Anmält intresse</th>
          <td>{{ row.interested }}</td>
        </tr>
        <tr>
          <th>Inbokade</th>
          <td>{{ row.booked }}</td>
        </tr>
        <tr>
          <th>Samtyckt</th>
          <td>{{ row.included }}</td>
        </tr>
        <tr>
          <th>Klara</th>
          <td>{{ row.finished }}</td>
        </tr>
      </table>
      <hr />
  </ng-template>
</div>
