

export class SubStudy {
  title: string;
  identifier: string;
  email: string;

  numberOfScapists: number;
  id: number;


}
