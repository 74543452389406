import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Issue} from '@shared/models/issue.model';
import {Scapist} from '@shared/models/scapist.model';
import {WorkListService} from '@shared/services/work-list.service';
import {Router} from '@angular/router';
import {NotificationService} from '@shared/services/notification.service';
import {Sort} from '@angular/material/sort';
import {ListFunctionsService} from '@shared/services/list-functions.service';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.scss']
})
export class IssueListComponent implements OnInit, OnChanges {
  dateMap: Map<number, number> = new Map<number, number>();

  @Input() issues: Issue[];
  columnsToDisplay: string[];
  sort: Sort;

  sortedIssues: Issue[];
  constructor(private workListService: WorkListService, private listFunctionService: ListFunctionsService, private router: Router, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.columnsToDisplay = ['name', 'personNumber', 'date', 'days', 'instrument', 'ecrf'];
    this.initiateListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.issues) {
      this.initiateListData();
    }
  }

  redirectToRedcap(issue: Issue) {
    this.workListService.redirectToRedcapIssue(issue);
  }

  redirectToBookingPage(id: number) {
    this.router.navigateByUrl('/scapist/' + id).then();
  }

  copyToClipboard(personNumber: string) {
    navigator.clipboard.writeText(personNumber)
      .then(() => {
        this.notificationService.sendSuccessNotification('Personnumret har kopierats till urklipp');
      })
      .catch((err) => {
        this.notificationService.sendErrorNotification('Misslyckades kopiera personnumret till urklipp');
      });
  }

  private initiateListData() {
    if (sessionStorage.getItem(`issues_sort`)) {
      const sort = JSON.parse(sessionStorage.getItem(`issues_sort`));
      this.sort = sort;
    }
    if(!this.sort) {
      this.sort = {active: 'days', direction: 'desc'}
    }
    this.sortData(this.sort);
    this.dateMap = this.workListService.getDayDiffMapGeneral(this.issues, 'id', 'created');
  }

  sortData(sort: Sort) {
    sessionStorage.setItem(`issues_sort`, JSON.stringify(sort))
    this.sort = sort;
    this.sortedIssues = this.listFunctionService.sortIssuesList(sort, this.issues, this.dateMap)
  }
}
