export enum UserTypeEnum {
  ROLE_ANVANDARE = 'ROLE_ANVANDARE',
  ROLE_BEHORIGHETSANSVARIG = 'ROLE_BEHORIGHETSANSVARIG',
  ROLE_TIDBOKNINGSANSVARIG = 'ROLE_TIDBOKNINGSANSVARIG',
  ROLE_BOKARE = 'ROLE_BOKARE',
  ROLE_REKRYTERARE = 'ROLE_REKRYTERARE',
  ROLE_MOTTAGNINGSPERSONAL = 'ROLE_MOTTAGNINGSPERSONAL',
  ROLE_SPIRO = 'ROLE_SPIRO',
  ROLE_BORTTAGNINGS_ANSVARIG = 'ROLE_BORTTAGNINGS_ANSVARIG',
  ROLE_STUDIELAKARE = 'ROLE_STUDIELAKARE',
  ROLE_RONTGENSJUKSKOTERSKA = 'ROLE_RONTGENSJUKSKOTERSKA',
  ROLE_PREVENTIONSSJUKSKOTERSKA = 'ROLE_PREVENTIONSSJUKSKOTERSKA',
  ROLE_GRANSKARE_HJARTA = 'ROLE_GRANSKARE_HJARTA',
  ROLE_GRANSKARE_LUNGA = 'ROLE_GRANSKARE_LUNGA',
  ROLE_GRANSKARE_KROPP = 'ROLE_GRANSKARE_KROPP',
  ROLE_STATISTIK_SITE = 'ROLE_STATISTIK_SITE',
  ROLE_STATISTIK_NATIONELLT = 'ROLE_STATISTIK_NATIONELLT',
  ROLE_KVALITETSGRANSKARE_SITE = 'ROLE_KVALITETSGRANSKARE_SITE',
  ROLE_KVALITETSGRANSKARE_NATIONELLT = 'ROLE_KVALITETSGRANSKARE_NATIONELLT',
  ROLE_LOKALA_SUBSTUDIER = 'ROLE_LOKALA_SUBSTUDIER',
  ROLE_LS_LAKARE = 'ROLE_LS_LAKARE',
  ROLE_LS_PERSONAL = 'ROLE_LS_PERSONAL',
  ROLE_KOMMUNIKATOR_NATIONELLT = 'ROLE_KOMMUNIKATOR_NATIONELLT'
}

export const UserTypeDisplay = {
  [UserTypeEnum.ROLE_BEHORIGHETSANSVARIG]: 'Behörighetsansvarig',
  [UserTypeEnum.ROLE_TIDBOKNINGSANSVARIG]: 'Tidboksansvarig',
  [UserTypeEnum.ROLE_BOKARE]: 'Bokare',
  [UserTypeEnum.ROLE_REKRYTERARE]: 'Rekryterare',
  [UserTypeEnum.ROLE_MOTTAGNINGSPERSONAL]: 'Mottagningspersonal',
  [UserTypeEnum.ROLE_BORTTAGNINGS_ANSVARIG]: 'Borttagningsansvarig',
  [UserTypeEnum.ROLE_SPIRO]: 'Spiro',
  [UserTypeEnum.ROLE_STUDIELAKARE]: 'Studieläkare',
  [UserTypeEnum.ROLE_RONTGENSJUKSKOTERSKA]: 'Röntgensjuksköterska',
  [UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA]: 'Preventionsjuksköterska',
  [UserTypeEnum.ROLE_GRANSKARE_HJARTA]: 'Granskare Hjärta',
  [UserTypeEnum.ROLE_GRANSKARE_LUNGA]: 'Granskare Lunga',
  [UserTypeEnum.ROLE_GRANSKARE_KROPP]: 'Granskare Kropp',
  [UserTypeEnum.ROLE_STATISTIK_SITE]: 'Statistik Site',
  [UserTypeEnum.ROLE_KVALITETSGRANSKARE_SITE]: 'Kvalitetsgranskare Site',
  [UserTypeEnum.ROLE_LS_LAKARE]: 'LS Läkare',
  [UserTypeEnum.ROLE_LS_PERSONAL]: 'LS Personal',
  [UserTypeEnum.ROLE_LOKALA_SUBSTUDIER]: 'Lokala substudier'
}

export const UserTypeDisplaySite7 = {
  [UserTypeEnum.ROLE_BEHORIGHETSANSVARIG]: 'Behörighetsansvarig',
  [UserTypeEnum.ROLE_STATISTIK_NATIONELLT]: 'Statistik Nationellt',
  [UserTypeEnum.ROLE_KVALITETSGRANSKARE_NATIONELLT]: 'Kvalitetsgranskare Nationellt',
  [UserTypeEnum.ROLE_KOMMUNIKATOR_NATIONELLT]: 'Kommunikatör Nationellt',
}

