import {Component, OnInit} from '@angular/core';
import {NotificationService} from '@shared/services/notification.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '@environments/environment';

/**
 * No template component, only responsible for redirecting the user to reset password page
 * if token valid. Link to this component route supplied in email to user when user wishes
 * to reset password.
 */
@Component({
  selector: 'app-verify-password-token',
  template: '<app-notification></app-notification>',
  styles: []
})
export class VerifyPasswordTokenComponent implements OnInit {

  private idParam: string;
  private tokenParam: string;
  private verifyTokenUrl: string;

  constructor(private notificationService: NotificationService,
              private httpClient: HttpClient,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute, private router: Router) {
    this.verifyTokenUrl = environment.baseUrl + '/password/verify';
    // Fetch the query params from url
    this.route.queryParamMap.subscribe(params => {
      this.idParam = params.get('id');
      this.tokenParam = params.get('token');
    });
  }

  ngOnInit() {
    // Redirect to not found page if params not present.
    if (!this.idParam && !this.tokenParam) {
      this.router.navigateByUrl('/notFound');
    }

    const params = new HttpParams()
      .set('id', this.idParam)
      .set('token', this.tokenParam);

    this.httpClient.get<string>(this.verifyTokenUrl, {
      params
    }).subscribe(() => {
      this.router.navigateByUrl(`/resetPassword?token=${this.tokenParam}`);
    }, err => {
      this.notificationService.sendErrorNotification(err);
    });
  }

}
