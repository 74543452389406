import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {share} from 'rxjs/operators';
import {RedcapFlowEnum} from '@shared/constants/redcap-flow.enum';
import {WorkListScapistModel} from '@shared/models/work-list-scapist.model';
import {WorkListService} from '@shared/services/work-list.service';
import {Scapist} from '@shared/models/scapist.model';
import {Router} from '@angular/router';
import {NotificationService} from '@shared/services/notification.service';
import {Sort} from '@angular/material/sort';
import {ListFunctionsService} from '@shared/services/list-functions.service';
import {RedcapFlowStatusEnum} from '@shared/constants/redcap-flow-status.enum';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.scss']
})
export class FlowListComponent implements OnInit {

  displayedColumns: string [] = [];
  scapists = null;

  @ViewChild('paginator') paginator: MatPaginator;
  @Input() redcapFlow: RedcapFlowEnum;
  scapistList: WorkListScapistModel[] = [];
  dataSource: MatTableDataSource<WorkListScapistModel>;
  dateMap: Map<number, number> = new Map<number, number>();
  sessionSort: Sort = { active: undefined, direction: undefined };

  constructor(public workListService: WorkListService, private router: Router, private notificationService: NotificationService, private listFunctionService:ListFunctionsService) {
  }

  ngOnInit(): void {
    this.displayedColumns = ['name', 'personNumber', 'date', 'days', 'status', 'latestReviewBy', 'latestReview', 'ecrf']
    this.initWorkList();
    if (sessionStorage.getItem(`${this.redcapFlow}_sort`)) {
      this.sessionSort = JSON.parse(sessionStorage.getItem(`${this.redcapFlow}_sort`));
    }
  }

  initWorkList() {
    this.workListService.getScapistsByFlowScapists(this.redcapFlow);
    switch (this.redcapFlow) {
      case RedcapFlowEnum.FLOW_1:
        this.scapists = this.workListService.flow1Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_2:
        this.scapists = this.workListService.flow2Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_3:
        this.scapists = this.workListService.flow3Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_4:
        this.scapists = this.workListService.flow4Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_5:
        this.scapists = this.workListService.flow5Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_6:
        this.scapists = this.workListService.flow6Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_7:
        this.scapists = this.workListService.flow7Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_8:
        this.scapists = this.workListService.flow8Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_9:
        this.scapists = this.workListService.flow9Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_10:
        this.scapists = this.workListService.flow10Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_11:
        this.scapists = this.workListService.flow11Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_12:
        this.scapists = this.workListService.flow12Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_13:
        this.scapists = this.workListService.flow13Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_14:
        this.scapists = this.workListService.flow14Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_15:
        this.scapists = this.workListService.flow15Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_16:
        this.scapists = this.workListService.flow16Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_17:
        this.scapists = this.workListService.flow17Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_18:
        this.scapists = this.workListService.flow18Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_19:
        this.scapists = this.workListService.flow19Scapists.pipe(share());
        break;
      case RedcapFlowEnum.FLOW_20:
        this.scapists = this.workListService.flow20Scapists.pipe(share());
        break;
      case RedcapFlowEnum.AUX_1:
        this.scapists = this.workListService.aux1Scapists.pipe(share());
        break;
      case RedcapFlowEnum.STATISTICS_1:
        this.scapists = this.workListService.statistics1Scapists.pipe(share());
        break;
      case RedcapFlowEnum.STATISTICS_2:
        this.scapists = this.workListService.statistics2Scapists.pipe(share());
        break;
    }
    if (this.scapists != null) {
      this.scapists.subscribe(data => {
        this.dateMap = this.workListService.getDayDiffMap(data);
        this.scapistList = this.listFunctionService.sortDataWorkList(this.sessionSort, data, this.dateMap);
        this.dataSource = new MatTableDataSource(this.scapistList);
        this.dataSource.paginator = this.paginator;
      });
    }

  }

  redirectToRedcap(scapist: Scapist) {
    this.workListService.redirectToRedcapByFlow(scapist, this.redcapFlow);
  }

  redirectToBookingPage(id: number) {
    this.router.navigateByUrl('/scapist/' + id).then();
  }

  copyToClipboard(personNumber: string) {
    navigator.clipboard.writeText(personNumber)
        .then(() => {
          this.notificationService.sendSuccessNotification('Personnumret har kopierats till urklipp');
        })
        .catch((err) => {
          this.notificationService.sendErrorNotification('Misslyckades kopiera personnumret till urklipp');
        });
  }

  sortData(sort: Sort) {
    sessionStorage.setItem(`${this.redcapFlow}_sort`, JSON.stringify(sort));
    this.sessionSort = sort;
    this.scapistList = this.listFunctionService.sortDataWorkList(sort, this.scapistList, this.dateMap);
    this.paginator.pageIndex = 0;
    this.dataSource.data = this.scapistList;
  }

  statusIcon(flowStatus: RedcapFlowStatusEnum) {
    switch (flowStatus) {
      case RedcapFlowStatusEnum.RELEVANT:
        return '🔘';
      case RedcapFlowStatusEnum.STARTED:
        return '🔴';
      case RedcapFlowStatusEnum.NOT_APPLICABLE:
        return '🔘';
      case RedcapFlowStatusEnum.NOT_VERIFIED:
        return '🟡';
      case RedcapFlowStatusEnum.DONE:
        return '🟢';

      default:
        return '';
    }
  }
}
