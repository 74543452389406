<h2>
  Inklusionstakt
</h2>
<div class="row">
  <div class="col-md-6 offset-md-3">
    <app-progress-bar [currentValue]="currentInclusion" [goalValue]="inclusionGoal"></app-progress-bar>
  </div>
  <div class="col-md-6">
    <line-chart
      *ngIf="inclusionRate | async as loadedInclusionRate"
      [labels]="loadedInclusionRate.serieNames"
      [data]="loadedInclusionRate.data"
    >
    </line-chart>
  </div>
  <div class="col-md-6">
    <bars-chart
      *ngIf="inclusionRatePerWeek | async as loadedInclusionRatePerWeek"
      [labels]="loadedInclusionRatePerWeek.serieNames"
      [data]="loadedInclusionRatePerWeek.data">
    </bars-chart>
  </div>
  <div class="col-md-12">
    <form [formGroup]="form">
      <div class="filter-border">
        <div class="row">
          <h3>Datumfilter för besöksöversikt</h3>
          <p>Ställ in det datumintervall du är intresserad av så visas endast besök inom intervallet i cirkeldiagramen nedan.</p>
          <mat-form-field class="date-range col-md-4 col-sm-6">
            <mat-date-range-input [rangePicker]="picker"(click)="picker.open()" class="date-input">
              <input matStartDate (click)="picker.open()" readonly formControlName="from" placeholder="Från" class="date-input">
              <input matEndDate (click)="picker.open()"readonly formControlName="to" placeholder="Till" class="date-input">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <button mat-raised-button (click)="clearFilter()">Rensa filter</button>

      </div>
    </form>
  </div>

  <div class="col-md-6">
    <h3>Besöksöversikt: Startbesök</h3>
    <pie-chart
      *ngIf="timeSlotDistributionStart | async as loadedTimeSlotDistributionStart"
      [labels]="loadedTimeSlotDistributionStart.serieNames"
      [data]="loadedTimeSlotDistributionStart.data"></pie-chart>
  </div>
  <div class="col-md-6">
    <h3>Besöksöversikt: CT-besök</h3>
    <pie-chart
      *ngIf="timeSlotDistributionCt | async as loadedTimeSlotDistributionCt"
      [labels]="loadedTimeSlotDistributionCt.serieNames"
      [data]="loadedTimeSlotDistributionCt.data"></pie-chart>
  </div>
  <div class="col-md-6">
    <h3>Besöksöversikt: Fortsättning på startbesök</h3>
    <pie-chart
      *ngIf="timeSlotDistributionStartContinue | async as loadedTimeSlotDistributionStartContinue"
      [labels]="loadedTimeSlotDistributionStartContinue.serieNames"
      [data]="loadedTimeSlotDistributionStartContinue.data"></pie-chart>
  </div>
  <div class="col-md-6">
    <h3>Besöksöversikt: Preventionsbesök</h3>
    <pie-chart
      *ngIf="timeSlotDistributionPrevention | async as loadedTimeSlotDistributionPrevention"
      [labels]="loadedTimeSlotDistributionPrevention.serieNames"
      [data]="loadedTimeSlotDistributionPrevention.data"></pie-chart>
  </div>
  <div class="col-md-6">
    <h3>Besöksöversikt: Uppföljningssamtal</h3>
    <pie-chart
      *ngIf="timeSlotDistributionFollowUp | async as loadedTimeSlotDistributionFollowUp"
      [labels]="loadedTimeSlotDistributionFollowUp.serieNames"
      [data]="loadedTimeSlotDistributionFollowUp.data"></pie-chart>
  </div>
</div>
