import { Component, OnInit } from '@angular/core';
import {share} from 'rxjs/operators';
import {StatisticsService} from '@shared/services/statistics.service';
import {AuthenticationService} from '@shared/services/authentication.service';
import { Observable } from 'rxjs';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';

@Component({
  selector: 'app-study-pace',
  templateUrl: './study-pace.component.html',
  styleUrls: ['./study-pace.component.scss']
})
export class StudyPaceComponent implements OnInit {

  studyPace: Observable<StatisticsModel>;
  studyPaceCumulative: Observable<StatisticsModel>;
  studyPaceTimeDistributionSUP: Observable<StatisticsModel>;
  studyPaceTimeDistributionNoSUP: Observable<StatisticsModel>;

  studyGoal: number;
  currentFinished = 0;

  constructor(private statisticsService: StatisticsService, private authService: AuthenticationService,) { }

  ngOnInit(): void {
    this.studyPace = this.statisticsService.studyPace.pipe(share());
    this.studyPaceCumulative = this.statisticsService.studyPaceCumulative.pipe(share());
    this.studyPaceTimeDistributionSUP = this.statisticsService.studyPaceTimeDistributionSUP.pipe(share());
    this.studyPaceTimeDistributionNoSUP = this.statisticsService.studyPaceTimeDistributionNoSUP.pipe(share());
    if(this.authService.getUser().site) {
      this.studyGoal = this.authService.getUser().site.recruitmentTarget;
    }

    this.statisticsService.studyPaceCumulative.subscribe(value => {
      if (value && value.average) {
        this.studyGoal = value.average;
      }
      if(value && value.data && value.data && value.data.length > 0) {
        this.currentFinished = value.data[value.data.length - 1].values[1];
      } else {
        this.currentFinished = 0;
      }
    })
  }

}
