import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Scapist} from '@shared/models/scapist.model';
import {ScapistService} from '@shared/services/scapist-services/scapist.service';
import {ScapistStatus} from '@shared/constants/scapist-status.enum';
import {ScapistStatusUtils} from '@shared/utils/scapist-status.utils';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {AuthenticationService} from '@shared/services/authentication.service';

@Component({
  selector: 'app-abort-scapist',
  templateUrl: './abort-scapist.component.html',
  styleUrls: ['./abort-scapist.component.scss']
})
export class AbortScapistComponent implements OnInit, OnChanges {

  @Input() scapist: Scapist;
  isScapistRemoved: boolean;
  isScapistDone: boolean;
  isBorttagningsAnsvarig: boolean;
  isPreventionssjukskoterska: boolean;
  canAbortPrevention: boolean;
  canAbort: boolean;

  constructor(private scapistService: ScapistService, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.getState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scapist) {
      this.getState();
    }
  }

  dialogText() {
    const {firstName, lastName} = this.scapist;
    const name = firstName && lastName ? `${firstName} ${lastName}` : '';
    return `Är du säker på att du vill avbryta deltagandet i SCAPIS för ${name}? Denna handling kan inte ångras!`;
  }

  removeScapist = () => {
    this.scapistService.setRemoveStatus(this.scapist);
  }

  preventionDialogText() {
    const {firstName, lastName} = this.scapist;
    const name = firstName && lastName ? `${firstName} ${lastName}` : '';
    return `Är du säker på att du vill avbryta personen ${name} prevention? Denna handling kan inte ångras!`;
  }

  abortPrevention = () => {
    this.scapistService.abortPrevention(this.scapist);
  }

  private getState() {
    this.isScapistRemoved = this.scapist.status === ScapistStatus.REMOVED_BEFORE_INCLUSION || this.scapist.status === ScapistStatus.REMOVED_AFTER_INCLUSION;
    this.canAbortPrevention = ScapistStatusUtils.isTargetStatusEligible(this.scapist.status, ScapistStatus.BOOK_PREVENTION) &&
      this.authService.userHasRole(UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA) &&
      this.scapist.status !== ScapistStatus.FINISHED;
    this.canAbort = this.authService.userHasRole(UserTypeEnum.ROLE_BORTTAGNINGS_ANSVARIG) && this.scapist.status !== ScapistStatus.FINISHED;
    this.isScapistDone = this.scapist.status === ScapistStatus.FINISHED;
    this.isBorttagningsAnsvarig = this.authService.userHasRole(UserTypeEnum.ROLE_BORTTAGNINGS_ANSVARIG);
    this.isPreventionssjukskoterska = this.authService.userHasRole(UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA);
  }
}
