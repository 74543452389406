import {Component, OnInit} from '@angular/core';
import {environment} from "@environments/environment";
import {EnvironmentMode} from "@shared/constants/environment-mode.enum";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-environment-indicator',
  templateUrl: './environment-indicator.component.html',
  styleUrls: ['./environment-indicator.component.scss']
})
export class EnvironmentIndicatorComponent implements OnInit {

  mode: EnvironmentMode;

  constructor(private titleService: Title) {
    this.mode = environment.environmentString;
  }

  ngOnInit(): void {
    if (!this.isProduction(this.mode)) {
      const currentTitle: string = this.titleService.getTitle();
      this.titleService.setTitle(`[${this.mode}] ${currentTitle}`);
    }
  }

  isProduction(mode: EnvironmentMode) {
    return mode === EnvironmentMode.PRODUCTION;
  }

}
