import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@shared/services/authentication.service';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {PreventionScapistsService} from '@shared/services/scapist-services/prevention-scapists.service';
import {NoShowScapistsService} from '@shared/services/scapist-services/no-show-scapists.service';
import {NoShowPreventionScapistsService} from '@shared/services/scapist-services/no-show-prevention-scapists.service';
import {InterestedScapistsService} from '@shared/services/scapist-services/interested-scapists.service';
import {UnreachableScapistsService} from '@shared/services/scapist-services/unreachable-scapists.service';

@Component({
  selector: 'app-call-list-page',
  templateUrl: './call-list-page.component.html',
  styleUrls: ['./call-list-page.component.scss']
})
export class CallListPageComponent implements OnInit {

  canViewCallList: boolean;
  canViewPreventionCallList: boolean;


  constructor(private authenticationService: AuthenticationService,
              private interestedScapistsService: InterestedScapistsService,
              private noShowPreventionScapistsService: NoShowPreventionScapistsService,
              private noShowScapistsService: NoShowScapistsService,
              private preventionScapistsService: PreventionScapistsService,
              private unreachableScapistsService: UnreachableScapistsService
  ) {
  }

  ngOnInit(): void {
    this.canViewCallList = this.authenticationService.userHasAnyRole([UserTypeEnum.ROLE_BOKARE]);
    this.canViewPreventionCallList = this.authenticationService.userHasAnyRole([UserTypeEnum.ROLE_PREVENTIONSSJUKSKOTERSKA]);

  }

  updateAllCallLists() {
    if (this.canViewCallList) {
      this.interestedScapistsService.getScapists();
      this.unreachableScapistsService.getScapists();
      this.noShowScapistsService.getScapists();
    }
    if (this.canViewPreventionCallList) {
      this.preventionScapistsService.getScapists();
      this.noShowPreventionScapistsService.getScapists();
    }
  }

}
