import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@shared/services/authentication.service';
import {ActivatedRoute} from '@angular/router';
import {ValidatePassword} from '@shared/validators/password.validator';
import {ValidateMatchPassword} from '@shared/validators/match-password.validator';
import {FormUtils} from '@shared/utils/form.utils';

@Component({
  selector: 'app-enable-page',
  templateUrl: './enable-page.component.html',
  styleUrls: ['./enable-page.component.scss']
})
export class EnablePageComponent implements OnInit {

  private token: string;

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
              private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8),
        ValidatePassword.patternValidator(ValidatePassword.UPPERCASE_REGEX, { requiresUppercase: true }),
        ValidatePassword.patternValidator(ValidatePassword.LOWERCASE_REGEX, { requiresLowercase: true }),
        ValidatePassword.patternValidator(ValidatePassword.DIGIT_REGEX, { requiresDigit: true }),
        ValidatePassword.patternValidator(ValidatePassword.SPECIAL_CHAR_REGEX, { requiresSpecialChars: true })
      ]),
      repeatPassword: new FormControl('', [Validators.required])
    }, {validators: ValidateMatchPassword});
  }

  isDisplayError(formProperty: string) {
    return FormUtils.isDisplayError(this.form, formProperty);
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get repeatPassword() {
    return this.form.get('repeatPassword');
  }

  get requiredValid() {
    return !this.password.hasError('required');
  }

  get minLengthValid() {
    return !this.password.hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.password.hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.password.hasError('requiresUppercase');
  }

  get requiresLowercaseValid() {
    return !this.password.hasError('requiresLowercase');
  }

  get requiresSpecialCharsValid() {
    return !this.password.hasError('requiresSpecialChars');
  }

  onSubmit(value: any) {
    this.authenticationService.enableUser(value, this.token);
  }
}
