<div mat-dialog-title class="dialog-title">
    <h3>Ta bort tider</h3>
    <button mat-icon-button aria-label="Stäng dialog" mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <mat-dialog-content>
      <p>Man kan inte ta bort tider som är har status <strong>{{timeslotFilter.BOOKED.display}}</strong>, <strong>{{timeslotFilter.ABSENT.display}}</strong> eller <strong>{{timeslotFilter.ATTENDED.display}}</strong>.</p>
    {{numberOfAvailableSlots}} av {{ slots.length }} {{ dayFilterEnum[slots[0].timeSlotType].display }}-tider går att ta bort.
    <mat-form-field>
      <mat-hint>Välj tider att ta bort</mat-hint>
      <input matInput hidden>
      <mat-selection-list formControlName="slots">
        <mat-list-option *ngFor="let slot of slots" [value]="slot.id" [disabled]="isDisabled(slot)">
          {{ slot.time.toLocaleString('sv-SE') }} (id: {{ slot.id }}) {{status(slot)}}
        </mat-list-option>
      </mat-selection-list>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="action-bar">
      <button mat-flat-button color="warn"><mat-icon>delete</mat-icon> Radera valda tider</button>
      <button mat-stroked-button [mat-dialog-close]="true">Avbryt</button>
  </mat-dialog-actions>
</form>