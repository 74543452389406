import {TimeSlot} from '@shared/models/time-slot.model';
import {EmptyTimeSlot} from '@shared/models/data-models/empty-timeslot-model.model';
import {TimeSlotTypeEnum} from "@shared/constants/time-slot-type.enum";
import {DayFilterEnum} from "@shared/constants/day-filter.enum";

export class TimeSlotUtils {

  public static groupByDate(timeSlots: TimeSlot[]): Map<string, TimeSlot[]> {
    const res = new Map<string, TimeSlot[]>();

    timeSlots.forEach(timeSlot => {
      timeSlot.time = new Date(timeSlot.time);
      if (!res.has(timeSlot.time.toDateString())) {
        res.set(timeSlot.time.toDateString(), []);
      }
      res.get(timeSlot.time.toDateString()).push(timeSlot);
    });
    return res;
  }

  public static groupEmptySlots(timeSlots: TimeSlot[]): EmptyTimeSlot {
    const res = new EmptyTimeSlot();
    res.startDaySlots = new Map<string, TimeSlot>();
    res.continuedStartDaySlots = new Map<string, TimeSlot>();
    res.ctDaySlots = new Map<string, TimeSlot>();
    res.preventionDaySlots = new Map<string,TimeSlot>();
    res.preventionFollowUpDaySlots = new Map<string, TimeSlot>();

    timeSlots.forEach(timeSlot => {
      if (timeSlot.scapist) {
        return;
      }
      timeSlot.time = new Date(timeSlot.time);
      const list = TimeSlotUtils.findCorrectDayList(res, timeSlot);
      list.set(timeSlot.time.toTimeString(), timeSlot);
    });

    res.timesStart = Array.from(res.startDaySlots.keys()).map(s => s.toString()).sort();
    res.timesContinuedStart = Array.from(res.continuedStartDaySlots.keys()).map(s => s.toString()).sort();
    res.timesCT = Array.from(res.ctDaySlots.keys()).map(s => s.toString()).sort();
    res.timesPrevention = Array.from(res.preventionDaySlots.keys()).map(s => s.toString()).sort();
    res.timesPreventionFollowUp = Array.from(res.preventionFollowUpDaySlots.keys()).map(s => s.toString()).sort();

    return res;
  }


  private static findCorrectDayList(res: EmptyTimeSlot, timeSlot: TimeSlot) {
    if (timeSlot.timeSlotType === 'START') {
      return res.startDaySlots;
    }
    if (timeSlot.timeSlotType === 'CONTINUED_START') {
      return res.continuedStartDaySlots;
    }
    if (timeSlot.timeSlotType === 'CT') {
      return res.ctDaySlots;
    }
    if (timeSlot.timeSlotType === 'PREVENTION') {
      return res.preventionDaySlots;
    }
    if (timeSlot.timeSlotType === 'PREVENTION_FOLLOW_UP') {
      return res.preventionFollowUpDaySlots;
    }

  }

  public static getTitle(type: TimeSlotTypeEnum) {
    switch (type) {
      case TimeSlotTypeEnum.START:
        return DayFilterEnum.START.display;
      case TimeSlotTypeEnum.CONTINUED_START:
        return DayFilterEnum.CONTINUED_START.display;
      case TimeSlotTypeEnum.CT:
        return DayFilterEnum.CT.display;
      case TimeSlotTypeEnum.PREVENTION:
        return DayFilterEnum.PREVENTION.display;
      case TimeSlotTypeEnum.PREVENTION_FOLLOW_UP:
        return DayFilterEnum.PREVENTION_FOLLOW_UP.display;
      case TimeSlotTypeEnum.FREE_BOOK:
        return DayFilterEnum.FREE_BOOK.display;
      default:
        break;
    }
  }

}
