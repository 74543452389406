import { DatePipe } from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {TableColumn} from '@modules/common/expandable-table/expandable-table.component';
import {UserTypeEnum} from '@shared/constants/user-type.enum';
import {InvitationStatus, InvitationStatusDisplay} from '@shared/invitation-status.enum';
import {Invitation} from '@shared/models/invitation.model';
import {InvitationService} from '@shared/services/entity-services/invitation.service';

@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.scss']
})
export class InvitationListComponent implements OnInit {
  @Input() invitations: Invitation[];

  columns: TableColumn[] = [
    { id: 'createdAt', display: 'Datum', formatter: (row: Invitation) => this.datePipe.transform(row.createdAt, 'yyyy-MM-dd HH:mm') },
    { id: 'total', display: 'Antal' },
    { id: 'user.email', display: 'Skapad av', formatter: (row: Invitation) => row.user.email },
    { id: 'status', display: 'Status', formatter: (row: Invitation) => row.total > row.interested ? InvitationStatusDisplay[row.status] : 'Färdig' },
    { id: 'addressList', display: 'Adresslista', formatter: {
        btnText: 'Ladda ner',
        text: 'Den här listan innehåller personuppgifter och måste hanteras på ett säkert sätt. Vill du fortsätta?',
        action: this.downloadAddressList.bind(this),
        disabled: this.isDisabled.bind(this)
      } }
  ];
  constructor(private invitationService: InvitationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
  }

  downloadAddressList(invitation: Invitation) {
    const isReminder = invitation.status === InvitationStatus.REMINDED || invitation.status === InvitationStatus.REMINDER_ADDRESSES_DOWNLOADED;
    this.invitationService.downloadAddressList(invitation, isReminder);
  }

  isDisabled(invitation: Invitation) {
    return !(
      invitation.addressListAt && this.isInFuture(invitation.addressListAt) &&
      invitation.total > invitation.interested
    );
  }

  private isInFuture(addressListAt: Date) {
    return addressListAt > new Date();
  }

  getReminderAddresses(invitation: Invitation) {
    return this.invitationService.sendReminders(invitation.id);
  }

  isNotTimeForReminder(invitation: Invitation): boolean {
    return invitation.status !== InvitationStatus.TIME_TO_REMIND;
  }
}
