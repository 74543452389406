<div class="container">
    <h1 class="content-title">
        Uppdatera lösenord
    </h1>

    <div class="content">
        <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword(updatePasswordForm.value)">
            <mat-form-field>
                <mat-label for="currentPassword">Nuvarande lösenord: </mat-label> <input matInput type="password" id="currentPassword"
                                                                                 formControlName="currentPassword">
                <div *ngIf="isDisplayError('currentPassword') && currentPassword.errors.required">
                    <div class="error-label">Får inte vara tomt</div>
                </div>
            </mat-form-field>

            <mat-form-field>
                <mat-label for="newPassword">Nytt lösenord: </mat-label> <input matInput type="password" id="newPassword"
                                                                        formControlName="newPassword">
                <div *ngIf="isDisplayError('newPassword') && newPassword.errors.required">
                    <div class="error-label">Får inte vara tomt</div>
                </div>
                <div *ngIf="requiredValid">
                    <div [ngClass]="{ 'password-validation-success': minLengthValid, 'password-validation-error': !minLengthValid }">
                        <div class="content-title-icon">{{minLengthValid ? "&#xF046;" : "&#xF096;"}}</div>
                        Lösenordet måste vara minst 8 tecken långt
                    </div>

                    <div [ngClass]="{ 'password-validation-success': requiresDigitValid, 'password-validation-error': !requiresDigitValid }">
                        <div class="content-title-icon">{{requiresDigitValid ? "&#xF046;" : "&#xF096;"}}</div>
                        Lösenordet måste ha minst en siffra
                    </div>

                    <div [ngClass]="{ 'password-validation-success': requiresUppercaseValid, 'password-validation-error': !requiresUppercaseValid }">
                        <div class="content-title-icon">{{requiresUppercaseValid ? "&#xF046;" : "&#xF096;"}}</div>
                        Lösenordet måste ha minst en stor bokstav
                    </div>

                    <div [ngClass]="{ 'password-validation-success': requiresLowercaseValid, 'password-validation-error': !requiresLowercaseValid }">
                        <div class="content-title-icon">{{requiresLowercaseValid ? "&#xF046;" : "&#xF096;"}}</div>
                        Lösenordet måste ha minst en liten bokstav
                    </div>

                    <div [ngClass]="{ 'password-validation-success': requiresSpecialCharsValid, 'password-validation-error': !requiresSpecialCharsValid }">
                        <div class="content-title-icon">{{requiresSpecialCharsValid ? "&#xF046;" : "&#xF096;"}}</div>
                        Lösenordet måste ha minst ett specialtecken
                    </div>
                </div>
            </mat-form-field>

            <mat-form-field>
                <mat-label for="repeatPassword">Upprepa lösenord: </mat-label> <input matInput type="password" id="repeatPassword"
                                                                                formControlName="repeatPassword">
                <div *ngIf="isDisplayError('repeatPassword') && repeatPassword.errors.required">
                    <div class="error-label">Får inte vara tomt</div>
                </div>
                <div *ngIf="isDisplayError('repeatPassword') && repeatPassword.errors.passwordMismatch">
                    <div class="error-label">{{ repeatPassword.getError('passwordMismatchMessage') }}</div>
                </div>
            </mat-form-field>

            <br/>
            <button mat-raised-button color="primary" [disabled]="!updatePasswordForm.valid" type="submit">Uppdatera</button>
        </form>
    </div>
</div>
