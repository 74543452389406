import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { environment } from '@environments/environment';
import { StatisticsModel } from '@shared/models/data-models/statistics.model';
import { BehaviorSubject } from 'rxjs';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export abstract class StatisticsService {

  protected readonly baseUrl: string;

  protected dataStore: {
    siteNumber: number,
    invitations: StatisticsModel;
    inclusionRate: StatisticsModel;
    inclusionRatePerWeek: StatisticsModel;
    ctExams: StatisticsModel;
    ctExamsPerWeek: StatisticsModel;
    ctExamsTime: StatisticsModel;

    ctReviews: StatisticsModel;
    ctReviewsPerWeek: StatisticsModel;
    ctReviewsTime: StatisticsModel;
    ctHeartReviewsTime: StatisticsModel;
    ctLungReviewsTime: StatisticsModel;
    ctBodyReviewsTime: StatisticsModel;

    studyPace: StatisticsModel;
    studyPaceCumulative: StatisticsModel;
    studyPaceTimeDistributionSUP: StatisticsModel;
    studyPaceTimeDistributionNoSUP: StatisticsModel;


    preventionShare: StatisticsModel;
    preventionVisitShare: StatisticsModel;
    preventionFollowupShare: StatisticsModel;
    preventionVisitTime: StatisticsModel;
    preventionFollowupTime: StatisticsModel;

    timeSlotDistributionStart: StatisticsModel;
    timeSlotDistributionCt: StatisticsModel;
    timeSlotDistributionStartContinue: StatisticsModel;
    timeSlotDistributionPrevention: StatisticsModel;
    timeSlotDistributionFollowUp: StatisticsModel;

    loading: boolean;
    from: Date;
    to: Date;
  }
  private _invitations = new BehaviorSubject<StatisticsModel>(null);
  public readonly invitations = this._invitations.asObservable();
  private _inclusionRate = new BehaviorSubject<StatisticsModel>(null);
  public readonly inclusionRate = this._inclusionRate.asObservable();
  private _inclusionRatePerWeek = new BehaviorSubject<StatisticsModel>(null);
  public readonly inclusionRatePerWeek = this._inclusionRatePerWeek.asObservable();

  private _ctExams = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctExams = this._ctExams.asObservable();
  private _ctExamsPerWeek = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctExamsPerWeek = this._ctExamsPerWeek.asObservable();
  private _ctExamsTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctExamsTime = this._ctExamsTime.asObservable();

  private _ctReviews = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctReviews = this._ctReviews.asObservable();
  private _ctReviewsPerWeek = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctReviewsPerWeek = this._ctReviewsPerWeek.asObservable();
  private _ctReviewsTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctReviewsTime = this._ctReviewsTime.asObservable();


  private _ctHeartReviewsTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctHeartReviewsTime = this._ctHeartReviewsTime.asObservable();
  private _ctLungReviewsTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctLungReviewsTime = this._ctLungReviewsTime.asObservable();
  private _ctBodyReviewsTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly ctBodyReviewsTime = this._ctBodyReviewsTime.asObservable();
  private _studyPace = new BehaviorSubject<StatisticsModel>(null);
  public readonly studyPace = this._studyPace.asObservable();
  private _studyPaceCumulative = new BehaviorSubject<StatisticsModel>(null);
  public readonly studyPaceCumulative = this._studyPaceCumulative.asObservable();
  private _studyPaceTimeDistributionSUP = new BehaviorSubject<StatisticsModel>(null);
  public readonly studyPaceTimeDistributionSUP = this._studyPaceTimeDistributionSUP.asObservable();
  private _studyPaceTimeDistributionNoSUP = new BehaviorSubject<StatisticsModel>(null);
  public readonly studyPaceTimeDistributionNoSUP = this._studyPaceTimeDistributionNoSUP.asObservable();

  private _preventionShare = new BehaviorSubject<StatisticsModel>(null);
  public readonly preventionShare = this._preventionShare.asObservable();
  private _preventionVisitShare = new BehaviorSubject<StatisticsModel>(null);
  public readonly preventionVisitShare = this._preventionVisitShare.asObservable();
  private _preventionFollowupShare = new BehaviorSubject<StatisticsModel>(null);
  public readonly preventionFollowupShare = this._preventionFollowupShare.asObservable();
  private _preventionVisitTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly preventionVisitTime = this._preventionVisitTime.asObservable();
  private _preventionFollowupTime = new BehaviorSubject<StatisticsModel>(null);
  public readonly preventionFollowupTime = this._preventionFollowupTime.asObservable();

  private _timeSlotDistributionStart = new BehaviorSubject<StatisticsModel>(null);
  public readonly timeSlotDistributionStart = this._timeSlotDistributionStart.asObservable();
  private _timeSlotDistributionCt = new BehaviorSubject<StatisticsModel>(null);
  public readonly timeSlotDistributionCt = this._timeSlotDistributionCt.asObservable();
  private _timeSlotDistributionStartContinue = new BehaviorSubject<StatisticsModel>(null);
  public readonly timeSlotDistributionStartContinue = this._timeSlotDistributionStartContinue.asObservable();
  private _timeSlotDistributionPrevention = new BehaviorSubject<StatisticsModel>(null);
  public readonly timeSlotDistributionPrevention = this._timeSlotDistributionPrevention.asObservable();
  private _timeSlotDistributionFollowUp = new BehaviorSubject<StatisticsModel>(null);
  public readonly timeSlotDistributionFollowUp = this._timeSlotDistributionFollowUp.asObservable();

  protected _loading = new BehaviorSubject<boolean>(false);

  protected constructor(private http: HttpClient, private notificationService: NotificationService, private datePipe: DatePipe) {
    this.baseUrl = environment.baseUrl;
    this.dataStore = {
      siteNumber: null,
      invitations: null,
      inclusionRate: null,
      inclusionRatePerWeek: null,
      ctExams: null,
      ctExamsPerWeek: null,
      ctExamsTime: null,
      ctReviews: null,
      ctReviewsPerWeek: null,
      ctReviewsTime: null,
      ctHeartReviewsTime: null,
      ctLungReviewsTime: null,
      ctBodyReviewsTime: null,
      studyPace: null,
      studyPaceCumulative: null,
      studyPaceTimeDistributionSUP: null,
      studyPaceTimeDistributionNoSUP: null,
      preventionShare: null,
      preventionVisitShare: null,
      preventionFollowupShare: null,
      preventionVisitTime: null,
      preventionFollowupTime: null,
      timeSlotDistributionStart: null,
      timeSlotDistributionCt: null,
      timeSlotDistributionStartContinue: null,
      timeSlotDistributionPrevention: null,
      timeSlotDistributionFollowUp: null,
      loading: false,
      from: null,
      to: null
    };
  }

  setSite(value: number) {
    this.dataStore.siteNumber = value;
  }

  getInvitations() {
      this.getData(`${this.baseUrl}/statistics/invitations`, 'invitations');
  }

  getInclusionRate() {
    this.getData(`${this.baseUrl}/statistics/inclusion-rate`, 'inclusionRate');
  }

  getCtReviewRateTime() {
    this.getData(`${this.baseUrl}/statistics/ct-review-time-distribution`, 'ctReviewsTime');
  }

  getCtHeartReviewRateTime() {
    this.getData(`${this.baseUrl}/statistics/ct-heart-review-time-distribution`, 'ctHeartReviewsTime');
  }

  getCtLungReviewRateTime() {
    this.getData(`${this.baseUrl}/statistics/ct-lung-review-time-distribution`, 'ctLungReviewsTime');
  }

  getCtBodyReviewRateTime() {
    this.getData(`${this.baseUrl}/statistics/ct-body-review-time-distribution`, 'ctBodyReviewsTime');
  }

  getCtReviewRatePerWeek() {
    this.getData(`${this.baseUrl}/statistics/ct-review-rate-week`, 'ctReviewsPerWeek');
  }

  getCtReviewRate() {
    this.getData(`${this.baseUrl}/statistics/ct-review-rate`, 'ctReviews');
  }

  getCtExamRateTime() {
    this.getData(`${this.baseUrl}/statistics/ct-time-distribution`, 'ctExamsTime');
  }

  getCtExamRatePerWeek() {
    this.getData(`${this.baseUrl}/statistics/ct-rate-week`, 'ctExamsPerWeek');
  }

  getCtExamRate() {
    this.getData(`${this.baseUrl}/statistics/ct-rate`, 'ctExams');
  }

  getInclusionRatePerWeek() {
      this.getData(`${this.baseUrl}/statistics/inclusion-rate-week`, 'inclusionRatePerWeek');
  }

  getStudyPace() {
      this.getData(`${this.baseUrl}/statistics/study-pace-week`, 'studyPace');
  }
  getStudyPaceCumulative() {
      this.getData(`${this.baseUrl}/statistics/study-pace`, 'studyPaceCumulative');
  }
  getStudyPaceTimeDistributionSUP() {
      this.getData(`${this.baseUrl}/statistics/study-pace-time-sup`, 'studyPaceTimeDistributionSUP');
  }
  getStudyPaceTimeDistributionNoSUP() {
      this.getData(`${this.baseUrl}/statistics/study-pace-time-no-sup`, 'studyPaceTimeDistributionNoSUP');
  }

  getPreventionShare() {
      this.getData(`${this.baseUrl}/statistics/prevention-share`, 'preventionShare');
  }
  getPreventionVisitShare() {
      this.getData(`${this.baseUrl}/statistics/prevention-visit-share`, 'preventionVisitShare');
  }
  getPreventionFollowupShare() {
      this.getData(`${this.baseUrl}/statistics/prevention-followup-share`, 'preventionFollowupShare');
  }
  getPreventionVisitTime() {
      this.getData(`${this.baseUrl}/statistics/prevention-visit-time`, 'preventionVisitTime');
  }
  getPreventionFollowupTime() {
    this.getData(`${this.baseUrl}/statistics/prevention-followup-time`, 'preventionFollowupTime');
  }
  getTimeSlotDistributionStart() {
    this.getData(`${this.baseUrl}/statistics/time-slot-status-distribution/START`, 'timeSlotDistributionStart');
  }
  getTimeSlotDistributionCt() {
    this.getData(`${this.baseUrl}/statistics/time-slot-status-distribution/CT`, 'timeSlotDistributionCt');
  }
  getTimeSlotDistributionStartContinue() {
    this.getData(`${this.baseUrl}/statistics/time-slot-status-distribution/CONTINUED_START`, 'timeSlotDistributionStartContinue');
  }
  getTimeSlotDistributionPrevention() {
    this.getData(`${this.baseUrl}/statistics/time-slot-status-distribution/PREVENTION`, 'timeSlotDistributionPrevention');
  }
  getTimeSlotDistributionFollowUp() {
    this.getData(`${this.baseUrl}/statistics/time-slot-status-distribution/PREVENTION_FOLLOW_UP`, 'timeSlotDistributionFollowUp');
  }

  private getData(url: string, name: string) {
    this.dispatchLoading(true);

    url = this.addParams(url);
    this.http.get<StatisticsModel>(url).subscribe(data => {
      this.dispatchData(data, name);
      this.dispatchLoading(false);
    }, () => {
      this.dispatchLoading(false);
      this.notificationService.updateNotification('error', 'Lyckades inte läsa in statistik');
    });
  }

  private addParams(url: string): string {
    const params = [];
    if (this.dataStore.siteNumber) {
      params.push(`siteNumber=${encodeURIComponent(this.dataStore.siteNumber)}`);
    }
    if(this.dataStore.from && this.dataStore.to) {
      params.push(`start=${this.datePipe.transform(this.dataStore.from, 'YYYY-MM-dd')}`);
      params.push(`end=${this.datePipe.transform(this.dataStore.to, 'YYYY-MM-dd')}`);
    }
    if(params.length === 0) {
      return url;
    }
    return url + `?${params.join('&')}`;
  }

  private dispatchLoading(loading: boolean) {
      this.dataStore.loading = loading;
      this._loading.next(this.dataStore.loading);
  }

  private dispatchData(data: StatisticsModel, name: string) {
    this.dataStore[name] = data;
    switch (name) {
      case 'invitations':
        this._invitations.next(Object.assign({}, this.dataStore).invitations);
        break;
      case 'inclusionRate':
        this._inclusionRate.next(Object.assign({}, this.dataStore).inclusionRate);
        break;
      case 'inclusionRatePerWeek':
        this._inclusionRatePerWeek.next(Object.assign({}, this.dataStore).inclusionRatePerWeek);
        break;
      case 'ctExams':
        this._ctExams.next(Object.assign({}, this.dataStore).ctExams);
        break;
      case 'ctExamsPerWeek':
        this._ctExamsPerWeek.next(Object.assign({}, this.dataStore).ctExamsPerWeek);
        break;
      case 'ctExamsTime':
        this._ctExamsTime.next(Object.assign({}, this.dataStore).ctExamsTime);
        break;
      case 'ctReviews':
        this._ctReviews.next(Object.assign({}, this.dataStore).ctReviews);
        break;
      case 'ctReviewsPerWeek':
        this._ctReviewsPerWeek.next(Object.assign({}, this.dataStore).ctReviewsPerWeek);
        break;
      case 'ctReviewsTime':
        this._ctReviewsTime.next(Object.assign({}, this.dataStore).ctReviewsTime);
        break;
      case 'ctHeartReviewsTime':
        this._ctHeartReviewsTime.next(Object.assign({}, this.dataStore).ctHeartReviewsTime);
        break;
      case 'ctLungReviewsTime':
        this._ctLungReviewsTime.next(Object.assign({}, this.dataStore).ctLungReviewsTime);
        break;
      case 'ctBodyReviewsTime':
        this._ctBodyReviewsTime.next(Object.assign({}, this.dataStore).ctBodyReviewsTime);
        break;
      case 'studyPace':
        this._studyPace.next(Object.assign({}, this.dataStore).studyPace);
        break;
      case 'studyPaceCumulative':
        this._studyPaceCumulative.next(Object.assign({}, this.dataStore).studyPaceCumulative);
        break;
      case 'studyPaceTimeDistributionSUP':
        this._studyPaceTimeDistributionSUP.next(Object.assign({}, this.dataStore).studyPaceTimeDistributionSUP);
        break;
      case 'studyPaceTimeDistributionNoSUP':
        this._studyPaceTimeDistributionNoSUP.next(Object.assign({}, this.dataStore).studyPaceTimeDistributionNoSUP);
        break;
      case 'preventionShare':
        this._preventionShare.next(Object.assign({}, this.dataStore).preventionShare);
        break;
      case 'preventionVisitShare':
        this._preventionVisitShare.next(Object.assign({}, this.dataStore).preventionVisitShare);
        break;
      case 'preventionFollowupShare':
        this._preventionFollowupShare.next(Object.assign({}, this.dataStore).preventionFollowupShare);
        break;
      case 'preventionVisitTime':
        this._preventionVisitTime.next(Object.assign({}, this.dataStore).preventionVisitTime);
        break;
      case 'preventionFollowupTime':
        this._preventionFollowupTime.next(Object.assign({}, this.dataStore).preventionFollowupTime);
        break;
      case 'timeSlotDistributionStart':
        this._timeSlotDistributionStart.next(Object.assign({}, this.dataStore).timeSlotDistributionStart);
        break;
      case 'timeSlotDistributionCt':
        this._timeSlotDistributionCt.next(Object.assign({}, this.dataStore).timeSlotDistributionCt);
        break;
      case 'timeSlotDistributionStartContinue':
        this._timeSlotDistributionStartContinue.next(Object.assign({}, this.dataStore).timeSlotDistributionStartContinue);
        break;
      case 'timeSlotDistributionPrevention':
        this._timeSlotDistributionPrevention.next(Object.assign({}, this.dataStore).timeSlotDistributionPrevention);
        break;
      case 'timeSlotDistributionFollowUp':
        this._timeSlotDistributionFollowUp.next(Object.assign({}, this.dataStore).timeSlotDistributionFollowUp);
        break;
    }
  }

  setDateRange(from, to) {
    this.dataStore.from = from;
    this.dataStore.to = to;
  }
}
