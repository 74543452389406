<div class="container">
	<div class="row">
		<div class="col">
			<div class="content-title">
				<h1>Välkommen</h1>
			</div>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-6 d-flex box">
			<div class=" w-100" [class.card]="isKommunikatorNationellt">
				<div class="card-body">
					<h4>Du är nu inloggad i S2</h4>
				</div>
			</div>
		</div>
		<div class="col-md-6 d-flex" *ngIf="isKommunikatorNationellt">
			<div class="card w-100">
				<div class="card-body">
					<h4>Ta ut en lista på allas epost</h4>
					<p>Som kommunikatör nationellt kan du ta ut en lista på alla användares epostadresser för att göra olika utskick. Klicka på knappen för att öppna din mailapplikation.</p>
					<button (click)="sendEmail()" mat-raised-button>
						<mat-icon>email</mat-icon> Skicka mail till alla användare.
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
