
<div class="container navigation-area">
  <h2>Resurser</h2>
  <div class="row">
    <div class="col-md-4">
      <div class="card" [routerLink]="['/call-list']" *ngIf="isBooker">
        <div class="card-body"><h3 class="card-title">Ringlista</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/visit-list']" *ngIf="isClinicStaff">
        <div class="card-body"><h3 class="card-title">Besöklista</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/find-scapist']">
        <div class="card-body"><h3 class="card-title"> Hitta Scapist</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/invite-scapists']" *ngIf="isRecruiter">
        <div class="card-body"><h3 class="card-title">Bjud in/Rekrytera</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/time-slots']" *ngIf="isTimeSlotAdmin">
        <div class="card-body"><h3 class="card-title">Hantera tidsböcker</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/sub-studies']">
        <div class="card-body"><h3 class="card-title">Substudier</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/users']" *ngIf="isUserAuthorisationAdmin">
        <div class="card-body"><h3 class="card-title">Användarrättigheter</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/site']" *ngIf="isTimeSlotAdmin">
        <div class="card-body"><h3 class="card-title">Site inställningar</h3></div></div>
    </div>
    <div class="col-md-4">
      <div class="card" [routerLink]="['/work-list']">
        <div class="card-body"><h3 class="card-title">Arbetslistor</h3></div></div>
    </div>
    <div class="col-md-4" *ngIf="isDevMode()">
      <div class="card" [routerLink]="['/generate-test-scapists']">
        <div class="card-body"><h3 class="card-title">Generera testscapister</h3></div></div>
    </div>
  </div>

</div>
